import cloneDeep from 'lodash-es/cloneDeep';
import isEqual from 'lodash-es/isEqual';

import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, NgZone, OnChanges,
    OnDestroy, OnInit, SimpleChanges, TrackByFunction, ViewChild, ViewEncapsulation
} from '@angular/core';
import { VirtualScrollerComponent } from '@iharbeck/ngx-virtual-scroller';
import {
    RadioButtonProps, RadioLook
} from '@profis-engineering/pe-ui-common/components/radio-button/radio-button.common';
import { Design, Properties } from '@profis-engineering/pe-ui-common/entities/design';
import {
    Feature, KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { environment } from '../../../environments/environmentPe';
import { ILoadsHNAWizardComponentInput } from '../../../shared/components/loads-hna-wizard';
import { DesignPe } from '../../../shared/entities/design-pe';
import {
    LoadCombination
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    AdvancedCalculationType, DesignStandard, DesignType, LoadCombinationsCalculationType, LoadType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { PropertyMetaData } from '../../../shared/properties/properties';
import {
    InternalLoadType, LoadsComponentHelper
} from '../../helpers/loads-component-helper';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { GuidService } from '../../services/guid.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UnitService } from '../../services/unit.service';
import { UserService } from '../../services/user.service';
import { includeSprites } from '../../sprites';

const DEFAULT_MAX_LOADS_HEIGHT = 334;

interface IDesignChangeDetectionModel {
    model: { [property: number]: any };
    properties: Properties;
}

@Component({
    templateUrl: './loads.component.html',
    styleUrls: ['../loads-row/loads-base.scss', './loads.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class LoadsComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
    private static readonly loadsRowHeight: number = 32; // sync with css variable @loads-row-height

    @Input()
    public isUtilizationVisible!: boolean;

    @Input()
    public resize3d!: (resetHighlightedComponents?: boolean) => void;

    @Input()
    public selectedLoadId!: string;

    @Input()
    public decisiveLoadCombinationIndex!: number;

    @Input()
    public decisiveLoadCombinationAnchorPlateIndex!: number;

    @Input()
    public decisiveLoadCombinationBaseMaterialIndex!: number;

    @Input()
    public decisiveLoadCombinationProfileIndex!: number;

    @Input()
    public decisiveLoadCombinationHandrailIndex!: number;

    @Input()
    public decisiveLoadWeldIndex!: number;

    @Input()
    public decisiveLoadStiffenersIndex!: number;

    @Input()
    public disabled!: boolean;

    @Input()
    public showPostAndRailDeflection!: boolean;

    @Input()
    public parentElement?: HTMLElement;

    public scrollLoadsFirstItemId?: string;

    @ViewChild('scrollLoads')
    public scrollLoadsElement!: VirtualScrollerComponent;

    @ViewChild('utilizationColumnRef')
    public utilizationColumnRef!: ElementRef<HTMLElement>;

    @ViewChild('handrailColumnRef')
    public handrailColumnRef!: ElementRef<HTMLElement>;

    public designStandardEnum = {
        ACI: DesignStandard.ACI
    };
    public propertyMetaData = PropertyMetaData;

    public rowsLoaded!: boolean;

    public collapsed = false;
    public submitted!: boolean;

    public loadsCalculationMode: LoadCombinationsCalculationType | null = null;
    public loadsCalculationModeRadio!: RadioButtonProps<LoadCombinationsCalculationType>;

    public helper: LoadsComponentHelper;

    private tableHeight!: number;
    private destroyed!: boolean;

    // Change detection
    private oldDesign!: IDesignChangeDetectionModel;
    private oldShowDynamic!: boolean;

    constructor(
        private readonly localization: LocalizationService,
        private readonly unit: UnitService,
        private readonly guid: GuidService,
        private readonly modal: ModalService,
        private readonly user: UserService,
        private readonly featuresVisibilityInfo: FeaturesVisibilityInfoService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly ngZone: NgZone,
        private readonly calculationService: CalculationServicePE,
        private readonly elementRef: ElementRef<HTMLElement>,
        translationFormatService: TranslationFormatService
    ) {

        this.helper = new LoadsComponentHelper(
            localization,
            unit,
            modal,
            translationFormatService,
            guid,
            user.design as unknown as DesignPe,
            environment.maxLoadCombinations
        );
    }

    public get design() {
        return this.user.design;
    }

    public get loads() {
        return this.design?.loadCombinations ?? [];
    }

    public get utilizationTotalText() {
        if (this.design.designType.id == DesignType.Handrail && this.helper.isUtilizationCompact) {
            return this.translate('Agito.Hilti.Profis3.Loads.Anchor');
        }

        return this.translate('Agito.Hilti.Profis3.Loads.Total');
    }

    public get utilizationFlexBasis() {
        if (this.collapsed) {
            return 0;
        }

        let utilizationFlexBasis = this.helper.resizerColumnWidth?.utilization ?? 0;

        if (this.design.designType.id == DesignType.Handrail) {
            utilizationFlexBasis += this.helper.resizerColumnWidth?.handrail ?? 0;
        }

        return utilizationFlexBasis;
    }

    public get isDeflectionLimitsVisible() {
        return this.design.region.id == KnownRegion.Hungary;
    }

    public get switchToFatigueCalculationModeTranslation() {
        const switchButtonTranslation = this.translate('Agito.Hilti.Profis3.Loads.FatigueCalculationMode.SwitchCalculation');
        const calculationMode = this.helper.isDynamicFatigueExpertMode ? this.translate('Agito.Hilti.Profis3.Loads.FatigueCalculationMode.Simple') : this.translate('Agito.Hilti.Profis3.Loads.FatigueCalculationMode.Expert');

        return switchButtonTranslation.replace('{fatigueCalculationMode}', calculationMode);
    }

    public get importLoadsTranslation() {
        return this.design.designType.id == DesignType.Handrail
            ? 'Agito.Hilti.Profis3.Loads.ImportLoads'
            : 'Agito.Hilti.Profis3.Loads.ImportLoadsCombinations';
    }

    public get openHNAWizardTranslation() {
        if (this.design.loadCombinationHNAWizard == null) {
            return null;
        }

        return this.design.loadCombinationHNAWizard.LoadCombinationHNAWizardEquations.length > 0
            ? this.translate('Agito.Hilti.Profis3.Loads.OpenHNAWizard.Edit')
            : this.translate('Agito.Hilti.Profis3.Loads.OpenHNAWizard.Create');
    }

    public get forcesHeader() {
        return `${this.translate('Agito.Hilti.Profis3.Loads.Forces')} [${this.unit.formatUnit(this.unit.getDefaultUnit(UnitGroup.Force))}]`;
    }

    public get momentsHeader() {
        return `${this.translate('Agito.Hilti.Profis3.Loads.Moments')} [${this.unit.formatUnit(this.unit.getDefaultUnit(UnitGroup.Moment))}]`;
    }

    public get loadsHeader() {
        return this.translate('Agito.Hilti.Profis3.Loads.Loads');
    }

    public get selectedLoad() {
        if (this.loads != null && this.selectedLoadId != null) {
            return this.loads.find((load) => load.Id == this.selectedLoadId) ?? null;
        }

        return null;
    }

    public get decisiveLoadId() {
        if (this.decisiveLoadCombinationIndex == null || this.loads[this.decisiveLoadCombinationIndex] == null) {
            return null;
        }

        return this.loads[this.decisiveLoadCombinationIndex].Id;
    }

    public get decisiveLoadBaseMaterialId() {
        if (this.decisiveLoadCombinationBaseMaterialIndex == null || this.loads[this.decisiveLoadCombinationBaseMaterialIndex] == null) {
            return null;
        }

        return (this.loads[this.decisiveLoadCombinationBaseMaterialIndex]).Id;
    }

    public get decisiveLoadAnchorPlateId() {
        if (this.decisiveLoadCombinationAnchorPlateIndex == null || this.loads[this.decisiveLoadCombinationAnchorPlateIndex] == null) {
            return null;
        }

        return this.loads[this.decisiveLoadCombinationAnchorPlateIndex].Id;
    }

    public get decisiveLoadProfileId() {
        if (this.decisiveLoadCombinationProfileIndex == null || this.loads[this.decisiveLoadCombinationProfileIndex] == null) {
            return null;
        }

        return this.loads[this.decisiveLoadCombinationProfileIndex].Id;
    }

    public get decisiveLoadHandrailId() {
        if (this.decisiveLoadCombinationHandrailIndex == null || this.loads[this.decisiveLoadCombinationHandrailIndex] == null) {
            return null;
        }
        return this.loads[this.decisiveLoadCombinationHandrailIndex].Id;
    }

    public get decisiveLoadWeldsId() {
        if (this.decisiveLoadWeldIndex == null || this.loads[this.decisiveLoadWeldIndex] == null) {
            return null;
        }
        return this.loads[this.decisiveLoadWeldIndex].Id;
    }

    public get decisiveLoadStiffenersId() {
        if (this.decisiveLoadStiffenersIndex == null || this.loads[this.decisiveLoadStiffenersIndex] == null) {
            return null;
        }

        return this.loads[this.decisiveLoadStiffenersIndex].Id;
    }

    public get decisiveLoadCombinations() {
        const sortedDecLoadCombinations: LoadCombination[] = [];

        if (this.selectedLoadId != null || this.loads == null || this.loads.length < 1) {
            return sortedDecLoadCombinations;
        }

        let decLoadCombinations: LoadCombination[] = [];
        this.addLoadCombinationToList(decLoadCombinations, this.decisiveLoadCombinationIndex);
        this.addLoadCombinationToList(decLoadCombinations, this.decisiveLoadCombinationAnchorPlateIndex);
        this.addLoadCombinationToList(decLoadCombinations, this.decisiveLoadCombinationBaseMaterialIndex);
        this.addLoadCombinationToList(decLoadCombinations, this.decisiveLoadCombinationProfileIndex);
        this.addLoadCombinationToList(decLoadCombinations, this.decisiveLoadWeldIndex);
        this.addLoadCombinationToList(decLoadCombinations, this.decisiveLoadStiffenersIndex);
        this.addLoadCombinationToList(decLoadCombinations, this.decisiveLoadCombinationHandrailIndex);

        this.loads.forEach(function (loadCombination) {
            let found = false;
            decLoadCombinations = decLoadCombinations.filter(function (decLoadCombination) {
                if (!found && decLoadCombination.Name == loadCombination.Name) {
                    sortedDecLoadCombinations.push(decLoadCombination);
                    found = true;
                    return false;
                }
                else {
                    return true;
                }
            });
        });

        return sortedDecLoadCombinations;
    }

    public get selectedLoadTypeName() {
        if (this.selectedLoad == null) {
            return null;
        }

        const index = Object.values(InternalLoadType).indexOf(InternalLoadType[this.selectedLoad.ActiveLoadType]);
        const enumKeys = Object.keys(InternalLoadType).map(key => InternalLoadType[key as any]).filter(value => typeof value === 'string') as string[];
        return this.translate(`Agito.Hilti.Profis3.Main.Loads.LoadType.${enumKeys[index]}`);
    }

    public get titleTranslation() {
        return this.design.designType.id == DesignType.Handrail
            ? 'Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.ControlLoadCases.Title'
            : 'Agito.Hilti.Profis3.Navigation.TabLoads.RegionLoads.ControlLoadCombinations.Title';
    }

    public get newLoadDisabled() {
        return this.submitted || this.helper.addingNewLoad || this.loads == null || this.loads.length >= this.helper.maxLoads
            || this.helper.isPropertyDisabled(PropertyMetaData.Loads_LoadCombinations.id) || this.loadCombinationFeatureDisabled ||
            this.design.isReadOnlyDesignMode || this.design.newAndImportLoadDisabled;
    }

    public get newLoadHidden() {
        return this.design.designType.id == DesignType.Handrail ||
            this.featuresVisibilityInfo.isHidden(Feature.Design_LoadCombination, this.design.region.id) ||
            (this.design.isDynamicFatigue && !this.helper.isDynamicFatigueExpertMode);
    }

    public get importLoadHidden() {
        return this.helper.isPropertyHidden(PropertyMetaData.Loads_ImportLoadCombinations.id) ||
            this.featuresVisibilityInfo.isHidden(Feature.Design_LoadCombination, this.design.region.id) ||
            (this.design.isDynamicFatigue && !this.helper.isDynamicFatigueExpertMode) ||
            this.design.newAndImportLoadDisabled;
    }

    public get importLoadDisabled() {
        return this.submitted || this.importLoadCombinationDisabled ||
            this.loadCombinationFeatureDisabled ||
            this.design.isReadOnlyDesignMode;
    }

    public get showDecisiveButton() {
        if (this.design.designType.id == DesignType.Handrail) {
            const hasSelectedLoadCombination = this.design.selectedLoadCombinationId !== null;
            const isDecisiveLoadDifferent = this.decisiveLoadId !== null && this.selectedLoadId !== this.decisiveLoadId;
            const isDecisiveLoadHandrailDifferent = this.decisiveLoadHandrailId !== null && this.selectedLoadId !== this.decisiveLoadHandrailId;

            return hasSelectedLoadCombination && (isDecisiveLoadDifferent || isDecisiveLoadHandrailDifferent);
        }

        const advancedCalculations = [AdvancedCalculationType.Realistic, AdvancedCalculationType.BPRigidityCheck, AdvancedCalculationType.FEM];
        if (advancedCalculations.includes(this.design.calculationType)) {
            return this.design.isLoadCombinationActive && this.design.decisiveLoadCombination != null && this.design.loadsCalculationMode == LoadCombinationsCalculationType.Single;
        }

        if (this.design.loadsCalculationMode == LoadCombinationsCalculationType.Single) {
            return false;
        }

        return this.design.isLoadCombinationActive && this.design.decisiveLoadCombination != null && this.design.decisiveLoadCombination != this.getActiveLoadCombination();
    }

    private getActiveLoadCombination() {
        return this.design.loadCombinations.find((load) => load.Id == this.selectedLoadId);
    }

    public get switchFatigueModeDisabled() {
        return this.submitted ||
            this.helper.isPropertyDisabled(PropertyMetaData.Loads_IsFatigueExpertMode.id) ||
            this.featuresVisibilityInfo.isHidden(Feature.Design_LoadCombination, this.design.region.id);
    }

    public get switchFatigueModeHidden() {
        return this.helper.isPropertyHidden(PropertyMetaData.Loads_IsFatigueExpertMode.id) ||
            this.featuresVisibilityInfo.isHidden(Feature.Design_LoadCombination, this.design.region.id);
    }

    public get openHNAWizardDisabled() {
        return this.submitted ||
            this.helper.isPropertyDisabled(PropertyMetaData.Loads_LoadCombinationHNAWizard.id) ||
            this.loadCombinationFeatureDisabled;
    }

    public get openHNAWizardHidden() {
        return this.helper.isPropertyHidden(PropertyMetaData.Loads_LoadCombinationHNAWizard.id) ||
            this.featuresVisibilityInfo.isHidden(Feature.Design_LoadCombination, this.design.region.id);
    }

    public get loadTooltip() {
        return this.importLoadCombinationDisabled || this.loadCombinationFeatureDisabled
            ? this.translate('Agito.Hilti.Profis3.Features.Design.ShowInputLoads.Tooltip') : null;
    }

    public get importLoadCombinationDisabled() {
        return this.helper.isPropertyDisabled(PropertyMetaData.Loads_ImportLoadCombinations.id);
    }

    public get loadCombinationFeatureDisabled() {
        return this.featuresVisibilityInfo.isDisabled(Feature.Design_LoadCombination, this.design?.region.id);
    }

    public get areLoadsWizardGenerated() {
        return this.loads.every((l) => l.IsWizardGenerated);
    }

    public get isSingleCalculation() {
        return this.loadsCalculationMode == LoadCombinationsCalculationType.Single;
    }

    private get loadCombinationUsedInReport() {
        return this.design.reportLoadCombination;
    }


    ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-trash',
            'sprite-anchor-shock',
            'sprite-anchor-seismic',
            'sprite-anchor-fire-resistant',
            'sprite-lines-expanded',
            'sprite-lines',
            'sprite-decisive-load-anchor',
            'sprite-decisive-load-handrail',
            'sprite-tab-profiles',
            'sprite-tab-base-plate',
            'sprite-tab-anchor-layout',
            'sprite-tab-base-material',
            'sprite-tab-profiles',
            'sprite-double-weld-location-small',
            'sprite-profile-i-all-small',
            'sprite-info-tooltip',
            'sprite-info'
        );

        this.onStateChanged = this.onStateChanged.bind(this);
        this.design.onStateChanged(this.onStateChanged);

        this.isDecisive = this.isDecisive.bind(this);

        this.helper.initialize();

        this.collapsed = this.loads == null || this.design.designType.id == DesignType.Handrail;

        // Load calculation mode
        this.loadsCalculationModeRadio = {
            look: RadioLook.Horizontal,
            items: [
                {
                    id: 'calculation-mode-all',
                    value: LoadCombinationsCalculationType.All,
                    text: this.translate('Agito.Hilti.Profis3.Loads.CalculationMode.All')
                },
                {
                    id: 'calculation-mode-single',
                    value: LoadCombinationsCalculationType.Single,
                    text: this.translate('Agito.Hilti.Profis3.Loads.CalculationMode.Single')
                }
            ],
            selectedValue: this.loadsCalculationMode ?? undefined
        };
        this.loadsCalculationMode = this.design.loadsCalculationMode;

        this.oldDesign = this.cloneDesignForChangeDetection(this.design);
        this.oldShowDynamic = this.helper.showDynamic;

        this.sizeHeader = this.sizeHeader.bind(this);

        // run outside angular zone because we run it in a loop
        this.ngZone.runOutsideAngular(() => requestAnimationFrame(this.sizeHeader));
        this.setMinimumHeight();
        this.onCollapsedChanged();
    }

    ngAfterViewInit() {
        this.scrollLoadsFirstItemId = this.scrollLoadsElement?.items[0]?.Id;
        this.setCompact();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedLoadId'] != null) {
            this.selectedLoadChanged(this.selectedLoad);
        }
        this.helper.setLoadTypeTooltips();
    }

    ngOnDestroy(): void {
        this.destroyed = true;
    }

    public setAllLoads(index: number) {
        switch (index) {
            case 1:
            case 2:
            case 3:
            case 4:
                this.loads.forEach(load => { load.ActiveLoadType = index; });

                // Run calculation
                this.calculationService.calculateAsync(this.design);
                break;
            default:
                throw new Error('Wrong load type id.');
        }
    }

    public updateToDecisiveButton() {
        if (this.loadsCalculationMode == LoadCombinationsCalculationType.Single) {
            this.loadsCalculationMode = LoadCombinationsCalculationType.All;
            this.loadsCalculationModeChanged();
        }
        else if(this.design.designType.id == DesignType.Handrail) {
            this.calculationService.calculateAsync(this.design, (design) => {
                design.selectedLoadCombinationId = '';
            });
        }
        else {
            // If there is no decisive load combination, then button will be hidden.
            this.selectLoad(this.design.decisiveLoadCombination?.Id ?? '');
        }
    }

    public switchFatigueMode() {
        // Update model and run calculation
        this.calculationService.calculateAsync(this.design,
            (design) => {
                design.isFatigueExpertMode = !design.isFatigueExpertMode;
            }
        );
    }

    public multipleCombinationsSelectedLoadTypeName(id: string) {
        if (this.loads != null) {
            const activeLoadType = this.loads.find((load) => load.Id == id)?.ActiveLoadType ?? LoadType.Unknown;
            const index = Object.values(InternalLoadType).indexOf(InternalLoadType[activeLoadType]);
            const enumKeys = Object.keys(InternalLoadType).map(key => InternalLoadType[key as any]).filter(value => typeof value === 'string') as string[];
            return this.translate(`Agito.Hilti.Profis3.Main.Loads.LoadType.${enumKeys[index]}`);
        }

        return null;
    }

    public translate(key: string) {
        return this.localization.getString(key);
    }

    public isDecisive(load: LoadCombination) {
        // if we have multiple LC and calculate active combination only we don't know which is decisive
        if (this.isSingleCalculation && this.selectedLoadId != null) {
            return false;
        }

        return this.decisiveLoadHandrailId == load.Id
            || this.decisiveLoadId == load.Id
            || this.decisiveLoadProfileId == load.Id
            || this.decisiveLoadBaseMaterialId == load.Id
            || this.decisiveLoadAnchorPlateId == load.Id
            || this.decisiveLoadWeldsId == load.Id
            || this.decisiveLoadStiffenersId == load.Id;
    }

    public openShowNewLoadWarning() {
        if (this.loads.some(l => l.IsWizardGenerated)) {
            this.modal.openConfirmChange({
                id: 'switch-to-custom-load-combinations-warning',
                title: this.translate('Agito.Hilti.Profis3.Warning'),
                message: this.translate('Agito.Hilti.Profis3.Main.SwitchToCustomLoadComabinations.Message'),
                confirmButtonText: this.translate('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.translate('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    modal.close();
                    this.showNewLoad();
                },
                onCancel: (modal) => {
                    modal.close();
                }
            });
        }
        else {
            this.showNewLoad();
        }
    }

    public importLoads() {
        if (this.loads.some(l => l.IsWizardGenerated)) {
            this.modal.openConfirmChange({
                id: 'switch-to-custom-load-combinations-warning',
                title: this.translate('Agito.Hilti.Profis3.Warning'),
                message: this.translate('Agito.Hilti.Profis3.Main.SwitchToCustomLoadComabinations.Message'),
                confirmButtonText: this.translate('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.translate('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    modal.close();
                    this.modal.openImportLoads();
                },
                onCancel: (modal) => {
                    modal.close();
                }
            });
        }
        else {
            this.modal.openImportLoads();
        }
    }

    public openHNAWizardWarning() {
        if (this.loads.some(l => !l.IsWizardGenerated)) {
            this.modal.openConfirmChange({
                id: 'switch-to-wizard-combinations-warning',
                title: this.translate('Agito.Hilti.Profis3.Warning'),
                message: this.translate('Agito.Hilti.Profis3.Main.SwitchToWizardLoadComabinations.Message'),
                confirmButtonText: this.translate('Agito.Hilti.Profis3.Yes'),
                cancelButtonText: this.translate('Agito.Hilti.Profis3.No'),
                onConfirm: (modal) => {
                    modal.close();
                    this.openHNAWizard();
                },
                onCancel: (modal) => {
                    modal.close();
                }
            });
        }
        else {
            this.openHNAWizard();
        }
    }

    public deleteLoads() {
        if (this.helper.haveSingleLoad) {
            return;
        }

        // one load combination is minimum
        const lastLoadCombinationId = this.selectedLoadId != null ? this.selectedLoadId :
            this.loadCombinationUsedInReport != null ? this.loadCombinationUsedInReport : this.loads[0].Id;

        this.design.loadCombinations = this.loads.filter((l) => l.IsWizardGenerated || l.Id == lastLoadCombinationId);

        // Run calculation
        this.calculationService.calculateAsync(this.design);
    }

    public formatForce(value: number) {
        if (value == null) {
            return null;
        }

        const defaultUnit = this.unit.getDefaultUnit(UnitGroup.Force);
        const internalUnit = this.unit.getInternalUnit(UnitGroup.Force);
        const precision = this.unit.getPrecision(defaultUnit);

        const defaultValue = this.unit.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unit.formatNumber(defaultValue, precision);
    }

    public formatMoment(value: number) {
        if (value == null) {
            return null;
        }

        const defaultUnit = this.unit.getDefaultUnit(UnitGroup.Moment);
        const internalUnit = this.unit.getInternalUnit(UnitGroup.Moment);
        const precision = this.unit.getPrecision(defaultUnit);

        const defaultValue = this.unit.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unit.formatNumber(defaultValue, precision);
    }

    public openDeflectionLimits() {
        this.modal.openDeflectionLimits();
    }

    public openFatigueLoadsInfo() {
        this.modal.openFatigueLoadsInfo();
    }

    @HostListener('window:resize', ['$event'])
    onWindowResize() {
        // reset column widths on window resize
        this.helper.resizerColumnWidth = null;
        this.scrollLoadsElement?.refresh();
        this.setCompact();
    }

    public get loadsContainer() {
        return this.elementRef.nativeElement.shadowRoot?.querySelector('.loads-container') as HTMLElement;
    }

    public get resizerWrapper() {
        return this.elementRef.nativeElement.shadowRoot?.querySelector<HTMLElement>('.resizer-wrapper') as HTMLElement;
    }

    public onResize() {
        this.tableHeight = this.loadsContainer.offsetHeight;
        this.scrollLoadsElement.refresh();
        this.resize3d(true);
    }

    public onCollapsedChanged() {
        if (!this.collapsed) {
            // trigger rows load
            this.rowsLoaded = true;
        }
        this.adjustGridHeight();
        this.setMinimumHeight();
        if (this.resize3d != null) {
            this.resize3d();
        }
        this.setCompact();
    }

    public selectLoad(loadId: string) {
        if (this.selectedLoadId != loadId) {
            this.selectedLoadId = loadId;

            this.selectedLoadChanged(this.selectedLoad);
        }
    }

    public changeLoad() {
        // Run calculation
        this.calculationService.calculateAsync(this.design);
    }

    public loadsCalculationModeRadioSelectedValueChange(selectedValue: LoadCombinationsCalculationType | null) {
        if (this.loadsCalculationMode != selectedValue) {
            this.loadsCalculationMode = selectedValue ?? null;

            this.submitted = true;

            this.calculationService.calculateAsync(this.design,
                (design) => {
                    design.loadsCalculationMode = this.loadsCalculationMode;
                }
            )
                .finally(() => {
                    this.submitted = false;
                });

            this.loadsCalculationModeChanged();
        }
    }

    public addNewLoad(newLoad: LoadCombination) {
        const loads = this.loads || [];

        const load = cloneDeep(newLoad);
        load.Id = this.guid.new();

        loads.push(load);
        this.design.loadCombinations = loads;

        this.helper.addingNewLoad = false;

        if (this.loads.length == 1) {
            this.selectLoad(this.loads[0].Id);
        }

        this.loadsContentScrollBottom();

        // Run calculation
        this.calculationService.calculateAsync(this.design);

        this.loadsContentScrollBottom();
    }

    public deleteLoad(load: LoadCombination) {
        if (this.helper.haveSingleLoad) {
            return;
        }

        this.design.loadCombinations = this.loads.filter((l) => l !== load || l.Id == this.selectedLoadId);

        // Run calculation
        this.calculationService.calculateAsync(this.design);
    }

    public columnsResized() {
        this.setCompact();
    }

    public virtualScrollChange(event: Event) {
        let eventItemId: string | undefined = undefined;
        if (event != null) {
            const event2 = event as any;
            if (Array.isArray(event2) && event2.length > 0) {
                eventItemId = event2[0]?.Id;
            }
        }

        if (event == null || eventItemId != this.scrollLoadsFirstItemId) {
            // If input element had focus we need to blur it on virtual scroll change
            // (viewport end/start index changed), but not if no actual scrolling happened
            const activeElement = document.activeElement as HTMLElement;
            if (activeElement != null && activeElement.tagName.toLowerCase() === 'input') {
                const loadRowsElement = this.elementRef.nativeElement.shadowRoot?.querySelector<HTMLElement>('.loads-rows') as HTMLElement;
                if (loadRowsElement != null && loadRowsElement.contains(activeElement)) {
                    activeElement.blur();
                }
            }
        }

        this.scrollLoadsFirstItemId = eventItemId;
    }

    public decisiveLoadsTrackById: TrackByFunction<LoadCombination> = (_: number, decisiveLoad: LoadCombination) => decisiveLoad.Id;

    public identifyLoad: TrackByFunction<LoadCombination> = (index: number) => index;

    private onShowDynamicChanged() {
        this.setMinimumHeight();
        this.resize3d();
    }

    private loadsCalculationModeChanged() {
        if (this.loadsCalculationMode != this.design.loadsCalculationMode) {
            if (this.loadsCalculationMode == LoadCombinationsCalculationType.All) {
                if (this.design.isCBFEMCalculation) {
                    // Triger idea
                    this.calculationService.calculateAdvancedBaseplateAll(this.design)
                        .then((result: any) => {
                            if (result != 'confirmedRealisticCalculation') {
                                this.selectedLoadId = this.design.selectedLoadCombinationId;
                            }
                        });
                }
                else {
                    // Update model
                    this.selectedLoadId = this.design.selectedLoadCombinationId; // Update model
                }

            }
            else if (this.loadsCalculationMode == LoadCombinationsCalculationType.Single) {
                if (this.selectedLoadId != this.design.selectedLoadCombinationId) {
                    if (this.design.isCBFEMCalculation) {
                        // Triger idea
                        this.calculationService.calculateAdvancedBaseplate(this.design, this.design.selectedLoadCombinationId)
                            .then((result: any) => {
                                if (result != 'confirmedRealisticCalculation') {
                                    this.selectedLoadId = this.design.selectedLoadCombinationId;
                                }
                            });
                    }
                    else {
                        // Update model
                        this.selectedLoadId = this.design.selectedLoadCombinationId;
                    }
                }
            }
            else {
                throw new Error('Unknown calculation mode');
            }
        }
    }

    private selectedLoadChanged(load: LoadCombination | null) {
        if (this.selectedLoadId != this.design.selectedLoadCombinationId && load != null) {
            if (this.design.isCBFEMCalculation) {
                /* Set selected load to previous value.
                 * Selected load id will be set when realistic calculation is confirmed */
                if (this.design.calculationType == AdvancedCalculationType.Realistic || this.design.calculationType == AdvancedCalculationType.BPRigidityCheck) {
                    // The value is changed inside the same cycle so change detection
                    // needs to be run again before the new change
                    this.changeDetector.detectChanges();

                    this.selectedLoadId = this.design.selectedLoadCombinationId;
                }

                // Trigger idea
                this.calculationService.calculateAdvancedBaseplate(this.design, load.Id)
                    .then((result: any) => {
                        if (result == 'confirmedRealisticCalculation') {
                            this.selectedLoadId = load.Id;
                        }
                    });
            }
            else {
                // Update model and run calculation
                this.calculationService.calculateAsync(this.design,
                    (design) => {
                        design.selectedLoadCombinationId = load.Id;
                    }
                );
            }
        }
    }

    private onStateChanged() {
        // FIX MODULARIZATION: remove NgZone wrapper when design will be removed from pe-ui
        const onStateChanged = () => {
            let designChanged = false;
            const newDesign = this.design;

            const selectedLoadCombinationId = this.design.selectedLoadCombinationId;
            if (this.selectedLoadId != selectedLoadCombinationId) {
                this.selectedLoadId = selectedLoadCombinationId;
                designChanged = true;
            }

            const loadsCalculationMode = this.design.loadsCalculationMode;
            if (this.loadsCalculationMode != loadsCalculationMode) {
                this.loadsCalculationMode = loadsCalculationMode;
                designChanged = true;
            }

            const propId = PropertyMetaData.AnchorPlate_CalculationType.id;
            const oldLoadCombinationsCalculationType = this.oldDesign.properties.get(propId)?.hidden;
            const newLoadCombinationsCalculationType = newDesign.properties.get(propId)?.hidden;
            if (!isEqual(oldLoadCombinationsCalculationType, newLoadCombinationsCalculationType) && newLoadCombinationsCalculationType != null) {
                if (this.design.loadsCalculationMode === undefined) {
                    this.loadsCalculationMode = null;
                }
                else {
                    this.loadsCalculationMode = this.design.loadsCalculationMode;
                }
            }

            if (designChanged) {
                this.oldDesign = this.cloneDesignForChangeDetection(this.design);
            }

            const showDynamic = this.helper.showDynamic;
            if (this.oldShowDynamic != showDynamic) {
                this.oldShowDynamic = showDynamic;
                this.onShowDynamicChanged();
            }

            this.changeDetector.detectChanges();
        };
        return NgZone.isInAngularZone() ? onStateChanged() : this.ngZone.run(onStateChanged);
    }

    private loadsContentScrollBottom() {
        this.scrollLoadsElement.scrollToIndex(this.loads.length);
    }

    private sizeHeader() {
        if (!this.destroyed) {
            const header = this.elementRef.nativeElement.shadowRoot?.querySelector('.resizer-wrapper .loads-header .header-sizer') as HTMLElement;
            const subHeader = this.elementRef.nativeElement.shadowRoot?.querySelector('.resizer-wrapper .loads-sub-header .header-sizer') as HTMLElement;
            const rows = this.elementRef.nativeElement.shadowRoot?.querySelectorAll('.resizer-wrapper .loads-row-component') as NodeListOf<Element>;

            if (header != null) {
                if (rows.length > 0) {
                    const rowWidth = (rows[0] as HTMLElement).offsetWidth;

                    if (header.style.width != rowWidth + 'px') {
                        header.style.width = rowWidth + 'px';
                    }
                }
                else if (header.style.width != '') {
                    header.style.width = '';
                }
            }

            if (subHeader != null) {
                if (rows.length > 0) {
                    const rowWidth = (rows[0] as HTMLElement).offsetWidth;

                    if (subHeader.style.width != rowWidth + 'px') {
                        subHeader.style.width = rowWidth + 'px';
                    }
                }
                else if (subHeader.style.width != '') {
                    subHeader.style.width = '';
                }
            }

            requestAnimationFrame(this.sizeHeader);
        }
    }

    private adjustGridHeight() {
        if (this.collapsed) {
            this.removeGridHeight();
        }
        else {
            this.setGridHeight();
        }
    }

    private setGridHeight(addSpace = 0) {
        let fullHeight = 0;

        if (this.tableHeight == null) { // if grid was not manually expanded, calculate new height
            let rowsHeight = this.loads.length;

            if (this.helper.addingNewLoad) { // Always account for additional row and space when adding new load
                rowsHeight += 1;
                addSpace += 17;
            }

            rowsHeight = rowsHeight * this.calculateRowHeight();

            rowsHeight++;   // First row has 2 borders, only 1 respected in calculateRowHeight()!

            const headersHeight = 4 + 32 + 36 + 48; // 4 resizer  32 header  36 subheader  48 footer

            fullHeight = Math.min(rowsHeight + headersHeight + addSpace, DEFAULT_MAX_LOADS_HEIGHT);
        }
        else {
            fullHeight = this.tableHeight;
        }
        this.loadsContainer.style.height = fullHeight + 'px';
    }

    private removeGridHeight() {
        this.loadsContainer.style.height = '';
    }

    private calculateRowHeight(load?: LoadCombination) {
        const rowFactor = this.helper.showDynamic && this.helper.isLoadNormalOrUnfactored(load)
            ? 2
            : 1;
        let height = rowFactor * (LoadsComponent.loadsRowHeight + 1); // +1 for bottom border that each row has

        // total height is actually loads.length * height + 1; so we add + 1 to the first load
        if (load != null && this.loads.length > 0 && this.loads[0] == load) {
            height++;
        }

        return height;
    }

    private setMinimumHeight() {
        const rowHeight = this.calculateRowHeight();
        const minimumHeight = !this.collapsed ? 122 + rowHeight : 0;
        this.loadsContainer.style.minHeight = minimumHeight + 'px';
    }

    private showNewLoad() {
        this.helper.initializeNewLoad();

        this.helper.addingNewLoad = true;

        this.setGridHeight();
        this.loadsContentScrollBottom();
        this.setMinimumHeight();
        this.resize3d();
    }

    private openHNAWizard() {
        const props: ILoadsHNAWizardComponentInput = {
            onSave: () => {
                this.setGridHeight(0); // do not add empty space, loads are already in list so needed height will be added automatically
            }
        };
        this.modal.openLoadsHNAWizard(props);
    }

    private cloneDesignForChangeDetection(design: Design): IDesignChangeDetectionModel {
        return {
            model: cloneDeep(design.model),
            properties: cloneDeep(design.properties)
        };
    }

    private setCompact() {
        NgZone.assertInAngularZone();

        if (!this.helper.setCompactCollapsed(this.collapsed)) {
            setTimeout(() => {
                this.helper.calculateCompact(this.utilizationColumnRef?.nativeElement, this.handrailColumnRef?.nativeElement);
            });
        }
    }

    private addLoadCombinationToList(loadCombinations: LoadCombination[], index?: number) {
        if (index == null) {
            return;
        }

        if (this.loads.length < index + 1) {
            return;
        }

        if (loadCombinations.filter(loadComb => loadComb.Id == this.loads[index]?.Id).length == 0) {
            loadCombinations.push(this.loads[index]);
        }
    }
}
