import { Injectable } from '@angular/core';
import { CommonRegion, ICommonRegionConstructor } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { Unit } from '@profis-engineering/pe-ui-common/entities/code-lists/unit';
import { SpecialRegion, SpecialRegionName } from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import {
    UserSettingsServiceInjected
} from '@profis-engineering/pe-ui-common/services/user-settings.common';

import { CodeList } from '../../shared/entities/code-lists/code-list';
import { DesignMethodGroup } from '../../shared/entities/code-lists/design-method-group';
import { DesignStandard } from '../../shared/entities/code-lists/design-standard';
import { DesignType } from '../../shared/entities/code-lists/design-type';
import {
    EdgeDistanceDisplayType
} from '../../shared/entities/code-lists/edge-distance-display-type';
import { IRegionConstructor, Region } from '../../shared/entities/code-lists/region';
import { QuickStartSettings, UserSettingsPe } from '../../shared/entities/user-settings';
import { ProjectCodeList } from '../../shared/enums/project-code-list';
import {
    DefaultsOfUserSettings
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import { AdvancedBaseplateCalculationDataEntity } from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    DesignMethodGroup as DesignMethodGroupEnum, DesignStandard as DesignStandardEnum,
    DesignType as DesignTypeEnum, SteelGuideline
} from '../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import { getAllowedDesignStandardsByDesignType } from '../../shared/helpers/design-standard-helper';
import { defaultEdgeDistance } from '../../shared/helpers/edge-distance-helper';
import { findSteelCalculationMethod } from '../../shared/helpers/steel-guideline-helper';
import { PropertyMetaData } from '../../shared/properties/properties';
import { CodeListService } from './code-list.service';
import { CommonCodeListService } from './common-code-list.service';

@Injectable({
    providedIn: 'root'
})
export class UserSettingsService extends UserSettingsServiceInjected<UserSettingsPe> {
    constructor(
        private codeListService: CodeListService,
        private commonCodeListService: CommonCodeListService
    ) {
        super();
    }

    public getQuickStartSettings(designTypeId: DesignTypeEnum): QuickStartSettings {
        switch (designTypeId) {
            case DesignTypeEnum.Concrete:
                return this.quickStartConcreteSettings;
            case DesignTypeEnum.Masonry:
                return this.quickStartMasonrySettings;
            case DesignTypeEnum.Handrail:
                return this.quickStartHandrailSettings;
            case DesignTypeEnum.MetalDeck:
                return this.quickStartMetalDeckSettings;
            default:
                throw new Error(`Unknown Design type. ${designTypeId}`);
        }
    }

    /**
     * Settings for concrete design. If specific setting for concrete is not set, global setting is used. If neither is found, default one from region is used.
     */
    public get quickStartConcreteSettings(): QuickStartSettings {
        const generalRegion = this.getRegionById(this.settings.quickStart.concrete.generalRegionId.value)
            ?? this.getDefaultRegion();
        const region = this.getSelectedOrGlobalAllowedRegion(DesignTypeEnum.Concrete, generalRegion.id);

        let designStandard = this.getValueWithFallbacks<DesignStandard>(
            this.getDesignStandardById(this.settings.quickStart.concrete.calculationDesignStandardId.value),
            this.getDesignStandardById(region?.defaultConcreteDesignStandardId));
        let designMethodGroup = this.getValueWithFallbacks<DesignMethodGroup>(
            this.getDesignMethodGroupById(this.settings.quickStart.concrete.calculationDesignMethodGroupId.value),
            this.getDesignMethodGroupById(this.getDefaultDesignMethodGroupId(DesignTypeEnum.Concrete, designStandard, region)));

        const allowedDesignStandards = this.getDesignStandardsByDesignType(this.getDesignTypeById(DesignTypeEnum.Concrete), region?.id).filter((it) => it.disabled !== true);
        if (allowedDesignStandards.length == 0) {
            designStandard = undefined;
            designMethodGroup = undefined;
        }
        else if (designStandard != null) {
            const newDesignStandard = (allowedDesignStandards.some(it => it.id == designStandard?.id) ? designStandard : allowedDesignStandards[0]);
            if (newDesignStandard.id != designStandard.id) {
                designStandard = newDesignStandard;
                designMethodGroup = this.getDesignMethodGroupById(this.getDefaultDesignMethodGroupId(DesignTypeEnum.Concrete, designStandard, region));
            }
        }

        return {
            general_region: generalRegion.commonRegion,
            units_area: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitArea, this.settings.quickStart.concrete.unitsAreaId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitArea, region?.defaultUnitArea)),
            units_force: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitForce, this.settings.quickStart.concrete.unitsForceId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitForce, region?.defaultUnitForce)),
            units_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitLength, this.settings.quickStart.concrete.unitsLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitLength, region?.defaultUnitLength)),
            units_moment: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitMoment, this.settings.quickStart.concrete.unitsMomentId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitMoment, region?.defaultUnitMoment)),
            units_stress: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitStress, this.settings.quickStart.concrete.unitsStressId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitStress, region?.defaultUnitStress)),
            units_temperature: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitTemperature, this.settings.quickStart.concrete.unitsTemperatureId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitTemperature, region?.defaultUnitTemperature)),
            units_force_per_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitForcePerLength, this.settings.quickStart.concrete.unitsForcePerLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitForcePerLength, region?.defaultUnitForcePerLength)),
            units_moment_per_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitMomentPerLength, this.settings.quickStart.concrete.unitsMomentPerLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitMomentPerLength, region?.defaultUnitMomentPerLength)),
            units_length_large: this.getUnitFromCodeList(CommonCodeList.UnitLength, region?.defaultUnitLengthLarge),
            units_stress_small: this.getUnitFromCodeList(CommonCodeList.UnitStress, region?.defaultUnitStressSmall),
            units_density: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitDensity, this.settings.quickStart.concrete.unitsDensityId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitDensity, region?.defaultUnitDensity)),
            anchor_plate_factor: this.getValueWithFallbacks<number>(
                (this.isDefaultEnAndSofaBasedDesignMethod(
                    this.settings.quickStart.concrete.calculationDesignStandardId.value,
                    this.settings.quickStart.concrete.calculationDesignMethodGroupId.value,
                    this.settings.quickStart.concrete.generalRegionId.value)
                    ? this.settings.application.defaults.anchorPlateFactor.value : this.settings.quickStart.concrete.anchorPlateFactor.value),
                this.settings.application.defaults.anchorPlateFactor.value),
            variable_loads: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.variableLoads.value,
                this.settings.application.defaults.variableLoads.value),
            permenent_loads: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.permenentLoads.value,
                this.settings.application.defaults.permenentLoads.value),
            calculation_designStandard: designStandard
                ?? null as unknown as DesignStandard,
            calculation_designMethodGroup: designMethodGroup,
            calculation_etagEnOnly: this.settings.quickStart.concrete.calculationEtagEnOnly.value
                ?? false,
            calculation_etaData: false,
            calculation_ostInputsData: false,
            edgeDistanceDisplayType: this.getValueWithFallbacks<EdgeDistanceDisplayType>(
                this.getEdgeDistanceDisplayTypeById(this.settings.quickStart.concrete.edgeDistanceDisplayTypeId.value),
                this.defaultEdgeDistanceCodelistItem(DesignTypeEnum.Concrete)),
            minimum_anchor_to_profile_distance: this.getValueWithFallbacks<number>(
                (this.isDefaultEnAndSofaBasedDesignMethod(
                    this.settings.quickStart.concrete.calculationDesignStandardId.value,
                    this.settings.quickStart.concrete.calculationDesignMethodGroupId.value,
                    this.settings.quickStart.concrete.generalRegionId.value)
                    ? this.settings.application.defaults.minimumAnchorToProfileDistance.value : this.settings.quickStart.concrete.minimumAnchorToProfileDistance.value),
                this.settings.application.defaults.minimumAnchorToProfileDistance.value),
            concreteSafetyFactorGammaC: this.getValueWithFallbacks<number>(
                (this.isDefaultEnAndSofaBasedDesignMethod(
                    this.settings.quickStart.concrete.calculationDesignStandardId.value,
                    this.settings.quickStart.concrete.calculationDesignMethodGroupId.value,
                    this.settings.quickStart.concrete.generalRegionId.value)
                    ? this.settings.application.defaults.concreteSafetyFactorGammaC.value : this.settings.quickStart.concrete.concreteSafetyFactorGammaC.value),
                this.settings.application.defaults.concreteSafetyFactorGammaC.value),
            concreteSafetyFactorGammaCFreeValue: PropertyMetaData.Option_ConcreteSafetyFactorGammaCFreeValue.defaultValue,
            steelSafetyFactorGammaM0: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.steelSafetyFactorGammaM0.value,
                this.settings.application.defaults.steelSafetyFactorGammaM0.value),
            steelSafetyFactorGammaM1: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.steelSafetyFactorGammaM1.value,
                this.settings.application.defaults.steelSafetyFactorGammaM1.value),
            steelSafetyFactorGammaM2: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.steelSafetyFactorGammaM2.value,
                this.settings.application.defaults.steelSafetyFactorGammaM2.value),
            steelSafetyFactorGammaM5: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.steelSafetyFactorGammaM5.value,
                this.settings.application.defaults.steelSafetyFactorGammaM5.value),
            materialSafetyFactor: this.getValueWithFallbacks<number>(this.settings.quickStart.concrete.materialSafetyFactor.value),
            handrailDisplacementLimit: this.getValueWithFallbacks<number>(this.settings.quickStart.handrail.displacementLimit.value),
            concreteCapacityFactor: this.getValueWithFallbacks<number>(this.settings.quickStart.concrete.concreteCapacityFactor.value),
            steelCapacityFactor: this.getValueWithFallbacks<number>(this.settings.quickStart.concrete.steelCapacityFactor.value),
            weldsCapacityFactor: this.getValueWithFallbacks<number>(this.settings.quickStart.concrete.weldsCapacityFactor.value),
            inSteelSafetyFactorGammaM0: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.inSteelSafetyFactorGammaM0.value,
                this.settings.application.defaults.inSteelSafetyFactorGammaM0.value),
            inSteelSafetyFactorGammaM1: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.inSteelSafetyFactorGammaM1.value,
                this.settings.application.defaults.inSteelSafetyFactorGammaM1.value),
            inSteelSafetyFactorGammaMw: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.inSteelSafetyFactorGammaMw.value,
                this.settings.application.defaults.inSteelSafetyFactorGammaMw.value),
            inSteelSafetyFactorGammaMb: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.inSteelSafetyFactorGammaMb.value,
                this.settings.application.defaults.inSteelSafetyFactorGammaMb.value),
            steelGuideLine: this.getValueWithFallbacks<SteelGuideline>(
                this.settings.quickStart.concrete.steelGuideline.value,
                this.getDefaultSteelGuideline(designStandard?.id, generalRegion)),
            minimumConcreteCover: this.getValueWithFallbacks<number>(
                (this.isDefaultEnAndSofaBasedDesignMethod(
                    this.settings.quickStart.concrete.calculationDesignStandardId.value,
                    this.settings.quickStart.concrete.calculationDesignMethodGroupId.value,
                    this.settings.quickStart.concrete.generalRegionId.value)
                    ? this.settings.application.defaults.minimumConcreteCover.value : this.settings.quickStart.concrete.minimumConcreteCover.value),
                this.settings.application.defaults.minimumConcreteCover.value),
            measureAnchorPlate: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.measureAnchorPlate?.value,
                region?.defaultMeasureAnchorPlate),
            measureBaseMaterialEdgeFrom: this.getValueWithFallbacks<number>(
                this.settings.quickStart.concrete.measureBaseMaterialEdgeFrom?.value,
                region?.defaultMeasureBaseMaterialEdgeFrom),
        };
    }

    /**
     * Settings for masonry design. If specific setting for masonry is not set, global setting is used. If neither is found, default one from region is used.
     */
    public get quickStartMasonrySettings(): QuickStartSettings {
        const generalRegion = this.getRegionById(this.settings.quickStart.masonry.generalRegionId.value)
            ?? this.getDefaultRegion();
        const region = this.getSelectedOrGlobalAllowedRegion(DesignTypeEnum.Masonry, generalRegion.id);

        let designStandard = this.getValueWithFallbacks<DesignStandard>(
            this.getDesignStandardById(this.settings.quickStart.masonry.calculationDesignStandardId.value),
            this.getDesignStandardById(region?.defaultMasonryDesignStandardId));
        let designMethodGroup = this.getValueWithFallbacks<DesignMethodGroup>(
            this.getDesignMethodGroupById(this.settings.quickStart.masonry.calculationDesignMethodGroupId.value),
            this.getDesignMethodGroupById(this.getDefaultDesignMethodGroupId(DesignTypeEnum.Masonry, designStandard, region)));

        const allowedDesignStandards = this.getDesignStandardsByDesignType(this.getDesignTypeById(DesignTypeEnum.Masonry), region?.id).filter((it) => it.disabled !== true);
        if (allowedDesignStandards.length == 0) {
            designStandard = undefined;
            designMethodGroup = undefined;
        }
        else if (designStandard != null) {
            const newDesignStandard = (allowedDesignStandards.some(it => it.id == designStandard?.id) ? designStandard : allowedDesignStandards[0]);
            if (newDesignStandard.id != designStandard.id) {
                designStandard = newDesignStandard;
                designMethodGroup = this.getDesignMethodGroupById(this.getDefaultDesignMethodGroupId(DesignTypeEnum.Masonry, designStandard, region));
            }
        }

        let etaData = (this.settings.quickStart.masonry.calculationEtaData.value == null) ? true : this.settings.quickStart.masonry.calculationEtaData.value;
        const hiltiTechnicalData = (this.settings.quickStart.masonry.calculationHiltiTechnicalData.value == null) ? !region?.noHiltiTechnicalDataBrick : this.settings.quickStart.masonry.calculationHiltiTechnicalData.value;
        const ostInputsData = (this.settings.quickStart.masonry.calculationOstInputsData.value == null) ? true : this.settings.quickStart.masonry.calculationOstInputsData.value;

        // at least one must data flag must be true
        if (!etaData && !hiltiTechnicalData) {
            etaData = true;
        }

        return {
            general_region: generalRegion.commonRegion,
            units_area: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitArea, this.settings.quickStart.masonry.unitsAreaId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitArea, region?.defaultUnitArea)),
            units_force: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitForce, this.settings.quickStart.masonry.unitsForceId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitForce, region?.defaultUnitForce)),
            units_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitLength, this.settings.quickStart.masonry.unitsLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitLength, region?.defaultUnitLength)),
            units_moment: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitMoment, this.settings.quickStart.masonry.unitsMomentId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitMoment, region?.defaultUnitMoment)),
            units_stress: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitStress, this.settings.quickStart.masonry.unitsStressId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitStress, region?.defaultUnitStress)),
            units_temperature: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitTemperature, this.settings.quickStart.masonry.unitsTemperatureId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitTemperature, region?.defaultUnitTemperature)),
            units_force_per_length: this.getUnitFromCodeList(CommonCodeList.UnitForcePerLength, region?.defaultUnitForcePerLength),
            units_moment_per_length: this.getUnitFromCodeList(CommonCodeList.UnitMomentPerLength, region?.defaultUnitMomentPerLength),
            units_length_large: this.getUnitFromCodeList(CommonCodeList.UnitLength, region?.defaultUnitLengthLarge),
            units_stress_small: this.getUnitFromCodeList(CommonCodeList.UnitStress, region?.defaultUnitStressSmall),
            units_density: this.getUnitFromCodeList(CommonCodeList.UnitDensity, region?.defaultUnitDensity),
            anchor_plate_factor: this.getValueWithFallbacks<number>(
                this.settings.quickStart.masonry.anchorPlateFactor.value,
                this.settings.application.defaults.anchorPlateFactor.value),
            variable_loads: this.getValueWithFallbacks<number>(
                this.settings.quickStart.masonry.variableLoads.value,
                this.settings.application.defaults.variableLoads.value),
            permenent_loads: this.getValueWithFallbacks<number>(
                this.settings.quickStart.masonry.permenentLoads.value,
                this.settings.application.defaults.permenentLoads.value),
            calculation_designStandard: designStandard
                ?? null as unknown as DesignStandard,
            calculation_designMethodGroup: designMethodGroup,
            calculation_etagEnOnly: false,
            calculation_etaData: etaData,
            calculation_ostInputsData: ostInputsData,
            edgeDistanceDisplayType: this.getValueWithFallbacks<EdgeDistanceDisplayType>(
                this.getEdgeDistanceDisplayTypeById(this.settings.quickStart.masonry.edgeDistanceDisplayTypeId.value),
                this.defaultEdgeDistanceCodelistItem(DesignTypeEnum.Masonry)),
            minimum_anchor_to_profile_distance: this.settings.quickStart.masonry.minimumAnchorToProfileDistance.value
                ?? this.settings.application.defaults.minimumAnchorToProfileDistance.value
                ?? undefined,
            concreteSafetyFactorGammaC: undefined,
            concreteSafetyFactorGammaCFreeValue: undefined,
            steelSafetyFactorGammaM0: this.getValueWithFallbacks<number>(
                this.settings.quickStart.masonry.steelSafetyFactorGammaM0.value,
                this.settings.application.defaults.steelSafetyFactorGammaM0.value),
            steelSafetyFactorGammaM1: this.getValueWithFallbacks<number>(
                this.settings.quickStart.masonry.steelSafetyFactorGammaM1.value,
                this.settings.application.defaults.steelSafetyFactorGammaM1.value),
            steelSafetyFactorGammaM2: this.getValueWithFallbacks<number>(
                this.settings.quickStart.masonry.steelSafetyFactorGammaM2.value,
                this.settings.application.defaults.steelSafetyFactorGammaM2.value),
            steelSafetyFactorGammaM5: this.getValueWithFallbacks<number>(
                this.settings.quickStart.masonry.steelSafetyFactorGammaM5.value,
                this.settings.application.defaults.steelSafetyFactorGammaM5.value),
            handrailDisplacementLimit: this.getValueWithFallbacks<number>(this.settings.quickStart.handrail.displacementLimit.value),
            handrailDisplacementLimitBridges: this.getValueWithFallbacks<number>(this.settings.quickStart.handrail.displacementLimitBridges.value),
            steelGuideLine: this.getDefaultSteelGuideline(designStandard?.id, generalRegion),
            minimumConcreteCover: undefined
        };
    }

    /**
     * Settings for handrail design. If specific setting for handrail is not set, global setting is used. If neither is found, default one from region is used.
     */
    public get quickStartHandrailSettings(): QuickStartSettings {
        const generalRegion = this.getRegionById(this.settings.quickStart.handrail.generalRegionId.value)
            ?? this.getDefaultRegion();
        const region = this.getSelectedOrGlobalAllowedRegion(DesignTypeEnum.Handrail, generalRegion.id);

        let designStandard = this.getValueWithFallbacks<DesignStandard>(
            this.getDesignStandardById(this.settings.quickStart.handrail.calculationDesignStandardId.value),
            this.getDesignStandardById(region?.defaultHandrailDesignStandardId));
        let designMethodGroup = this.getValueWithFallbacks<DesignMethodGroup>(
            this.getDesignMethodGroupById(this.settings.quickStart.handrail.calculationDesignMethodGroupId.value),
            this.getDesignMethodGroupById(this.getDefaultDesignMethodGroupId(DesignTypeEnum.Handrail, designStandard, region)));

        const allowedDesignStandards = this.getDesignStandardsByDesignType(this.getDesignTypeById(DesignTypeEnum.Handrail), region?.id).filter((it) => it.disabled !== true);
        if (allowedDesignStandards.length == 0) {
            designStandard = undefined;
            designMethodGroup = undefined;
        }
        else if (designStandard != null) {
            const newDesignStandard = (allowedDesignStandards.some(it => it.id == designStandard?.id) ? designStandard : allowedDesignStandards[0]);
            if (newDesignStandard.id != designStandard.id) {
                designStandard = newDesignStandard;
                designMethodGroup = this.getDesignMethodGroupById(this.getDefaultDesignMethodGroupId(DesignTypeEnum.Handrail, designStandard, region));
            }
        }

        return {
            general_region: generalRegion.commonRegion,
            units_area: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitArea, this.settings.quickStart.handrail.unitsAreaId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitArea, region?.defaultUnitArea)),
            units_force: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitForce, this.settings.quickStart.handrail.unitsForceId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitForce, region?.defaultUnitForce)),
            units_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitLength, this.settings.quickStart.handrail.unitsLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitLength, region?.defaultUnitLength)),
            units_moment: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitMoment, this.settings.quickStart.handrail.unitsMomentId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitMoment, region?.defaultUnitMoment)),
            units_stress: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitStress, this.settings.quickStart.handrail.unitsStressId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitStress, region?.defaultUnitStress)),
            units_temperature: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitTemperature, this.settings.quickStart.handrail.unitsTemperatureId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitTemperature, region?.defaultUnitTemperature)),
            units_force_per_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitForcePerLength, this.settings.quickStart.handrail.unitsForcePerLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitForcePerLength, region?.defaultUnitForcePerLength)),
            units_moment_per_length: this.getUnitFromCodeList(CommonCodeList.UnitMomentPerLength, region?.defaultUnitMomentPerLength),
            units_length_large: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitLength, this.settings.quickStart.handrail.unitsLengthLargeId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitLength, region?.defaultUnitLengthLarge)),
            units_density: this.getUnitFromCodeList(CommonCodeList.UnitDensity, region?.defaultUnitDensity),
            units_stress_small: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitStress, this.settings.quickStart.handrail.unitsStressSmallId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitStress, region?.defaultUnitStressSmall)),
            anchor_plate_factor: this.getValueWithFallbacks<number>(
                this.settings.quickStart.handrail.anchorPlateFactor.value,
                this.settings.application.defaults.anchorPlateFactor.value),
            variable_loads: this.getValueWithFallbacks<number>(
                this.settings.quickStart.handrail.variableLoads.value,
                this.settings.application.defaults.variableLoads.value),
            permenent_loads: this.getValueWithFallbacks<number>(
                this.settings.quickStart.handrail.permenentLoads.value,
                this.settings.application.defaults.permenentLoads.value),
            calculation_designStandard: designStandard
                ?? null as unknown as DesignStandard,
            calculation_designMethodGroup: designMethodGroup,
            calculation_etagEnOnly: this.settings.quickStart.handrail.calculationEtagEnOnly.value
                ?? false,
            calculation_etaData: false,
            calculation_ostInputsData: false,
            edgeDistanceDisplayType: this.getValueWithFallbacks<EdgeDistanceDisplayType>(
                this.getEdgeDistanceDisplayTypeById(this.settings.quickStart.handrail.edgeDistanceDisplayTypeId.value),
                this.defaultEdgeDistanceCodelistItem(DesignTypeEnum.Handrail)),
            minimum_anchor_to_profile_distance: this.getValueWithFallbacks<number>(
                this.settings.quickStart.handrail.minimumAnchorToProfileDistance.value,
                this.settings.application.defaults.minimumAnchorToProfileDistance.value),
            concreteSafetyFactorGammaC: undefined,
            concreteSafetyFactorGammaCFreeValue: undefined,
            steelSafetyFactorGammaM0: this.getValueWithFallbacks<number>(
                this.settings.quickStart.handrail.steelSafetyFactorGammaM0.value,
                this.settings.application.defaults.steelSafetyFactorGammaM0.value),
            steelSafetyFactorGammaM1: this.getValueWithFallbacks<number>(
                this.settings.quickStart.handrail.steelSafetyFactorGammaM1.value,
                this.settings.application.defaults.steelSafetyFactorGammaM1.value),
            steelSafetyFactorGammaM2: this.getValueWithFallbacks<number>(
                this.settings.quickStart.handrail.steelSafetyFactorGammaM2.value,
                this.settings.application.defaults.steelSafetyFactorGammaM2.value),
            steelSafetyFactorGammaM5: this.getValueWithFallbacks<number>(
                this.settings.quickStart.handrail.steelSafetyFactorGammaM5.value,
                this.settings.application.defaults.steelSafetyFactorGammaM5.value),
            handrailDisplacementLimit: this.getValueWithFallbacks<number>(this.settings.quickStart.handrail.displacementLimit.value),
            steelGuideLine: this.getDefaultSteelGuideline(designStandard?.id, generalRegion),
            minimumConcreteCover: undefined
        };
    }

    /**
     * Settings for metal deck design. If specific setting for metal deck is not set, global setting is used. If neither is found, default one from region is used.
     */
    public get quickStartMetalDeckSettings(): QuickStartSettings {
        const generalRegion = this.getRegionById(this.settings.quickStart.metalDeck.generalRegionId.value)
            ?? this.getDefaultRegion();
        const region = this.getSelectedOrGlobalAllowedRegion(DesignTypeEnum.MetalDeck, generalRegion.id);

        let designStandard = this.getValueWithFallbacks<DesignStandard>(
            this.getDesignStandardById(this.settings.quickStart.metalDeck.calculationDesignStandardId.value),
            this.getDesignStandardById(region?.defaultMetalDeckDesignStandardId));
        let designMethodGroup = this.getValueWithFallbacks<DesignMethodGroup>(
            this.getDesignMethodGroupById(this.settings.quickStart.metalDeck.calculationDesignMethodGroupId.value),
            this.getDesignMethodGroupById(this.getDefaultDesignMethodGroupId(DesignTypeEnum.MetalDeck, designStandard, region)));

        const allowedDesignStandards = this.getDesignStandardsByDesignType(this.getDesignTypeById(DesignTypeEnum.MetalDeck), region?.id).filter((it) => it.disabled !== true);
        if (allowedDesignStandards.length == 0) {
            designStandard = undefined;
            designMethodGroup = undefined;
        }
        else if (designStandard != null) {
            const newDesignStandard = (allowedDesignStandards.some(it => it.id == designStandard?.id) ? designStandard : allowedDesignStandards[0]);
            if (newDesignStandard.id != designStandard.id) {
                designStandard = newDesignStandard;
                designMethodGroup = this.getDesignMethodGroupById(this.getDefaultDesignMethodGroupId(DesignTypeEnum.MetalDeck, designStandard, region));
            }
        }

        return {
            general_region: generalRegion.commonRegion,
            units_area: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitArea, this.settings.quickStart.metalDeck.unitsAreaId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitArea, region?.defaultUnitArea)),
            units_force: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitForce, this.settings.quickStart.metalDeck.unitsForceId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitForce, region?.defaultUnitForce)),
            units_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitLength, this.settings.quickStart.metalDeck.unitsLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitLength, region?.defaultUnitLength)),
            units_moment: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitMoment, this.settings.quickStart.metalDeck.unitsMomentId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitMoment, region?.defaultUnitMoment)),
            units_stress: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitStress, this.settings.quickStart.metalDeck.unitsStressId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitStress, region?.defaultUnitStress)),
            units_temperature: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitTemperature, this.settings.quickStart.metalDeck.unitsTemperatureId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitTemperature, region?.defaultUnitTemperature)),
            units_force_per_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitForcePerLength, this.settings.quickStart.metalDeck.unitsForcePerLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitForcePerLength, region?.defaultUnitForcePerLength)),
            units_moment_per_length: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitMomentPerLength, this.settings.quickStart.metalDeck.unitsMomentPerLengthId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitMomentPerLength, region?.defaultUnitMomentPerLength)),
            units_length_large: this.getValueWithFallbacks<Unit>(this.getUnitFromCodeList(CommonCodeList.UnitLength, region?.defaultUnitLengthLarge)),
            units_stress_small: this.getValueWithFallbacks<Unit>(this.getUnitFromCodeList(CommonCodeList.UnitStress, region?.defaultUnitStressSmall)),
            units_density: this.getValueWithFallbacks<Unit>(
                this.getUnitFromCodeList(CommonCodeList.UnitDensity, this.settings.quickStart.metalDeck.unitsDensityId.value),
                this.getUnitFromCodeList(CommonCodeList.UnitDensity, region?.defaultUnitDensity)),
            anchor_plate_factor: this.getValueWithFallbacks<number>(
                this.settings.quickStart.metalDeck.anchorPlateFactor.value,
                this.settings.application.defaults.anchorPlateFactor.value),
            variable_loads: this.getValueWithFallbacks<number>(this.settings.application.defaults.variableLoads.value),
            permenent_loads: this.getValueWithFallbacks<number>(this.settings.application.defaults.permenentLoads.value),
            calculation_designStandard: designStandard
                ?? null as unknown as DesignStandard,
            calculation_designMethodGroup: designMethodGroup,
            calculation_etagEnOnly: false,
            calculation_etaData: false,
            calculation_ostInputsData: false,
            edgeDistanceDisplayType: this.getValueWithFallbacks<EdgeDistanceDisplayType>(
                this.getEdgeDistanceDisplayTypeById(this.settings.quickStart.metalDeck.edgeDistanceDisplayTypeId.value),
                this.defaultEdgeDistanceCodelistItem(DesignTypeEnum.MetalDeck)),
            minimum_anchor_to_profile_distance: this.getValueWithFallbacks<number>(
                this.settings.quickStart.metalDeck.minimumAnchorToProfileDistance.value,
                this.settings.application.defaults.minimumAnchorToProfileDistance.value),
            concreteSafetyFactorGammaC: undefined,
            concreteSafetyFactorGammaCFreeValue: undefined,
            steelSafetyFactorGammaM0: this.getValueWithFallbacks<number>(
                this.settings.quickStart.metalDeck.steelSafetyFactorGammaM0.value,
                this.settings.application.defaults.steelSafetyFactorGammaM0.value),
            steelSafetyFactorGammaM1: this.getValueWithFallbacks<number>(
                this.settings.quickStart.metalDeck.steelSafetyFactorGammaM1.value,
                this.settings.application.defaults.steelSafetyFactorGammaM1.value),
            steelSafetyFactorGammaM2: this.getValueWithFallbacks<number>(
                this.settings.quickStart.metalDeck.steelSafetyFactorGammaM2.value,
                this.settings.application.defaults.steelSafetyFactorGammaM2.value),
            steelSafetyFactorGammaM5: this.getValueWithFallbacks<number>(
                this.settings.quickStart.metalDeck.steelSafetyFactorGammaM5.value,
                this.settings.application.defaults.steelSafetyFactorGammaM5.value),
            handrailDisplacementLimit: this.getValueWithFallbacks<number>(this.settings.quickStart.handrail.displacementLimit.value),
            materialSafetyFactor: this.getValueWithFallbacks<number>(this.settings.quickStart.metalDeck.materialSafetyFactor.value),
            steelGuideLine: this.getValueWithFallbacks<SteelGuideline>(
                this.settings.quickStart.metalDeck.steelGuideline.value,
                this.getDefaultSteelGuideline(designStandard?.id, generalRegion)),
            minimumConcreteCover: undefined
        };
    }

    public readServerSideSettingsEntity(): DefaultsOfUserSettings {
        if (this.settingsLoaded == false) {
            throw new Error('Calling readServerSideSettingsEntity on user settings service before the service has been initiated!');
        }

        return {
            MinimumConcreteCover: this.settings.application.defaults.minimumConcreteCover.value,
            MinimumAnchorToProfileDistance: this.settings.application.defaults.minimumAnchorToProfileDistance.value,
            AnchorPlateThicknessDefault: this.settings.application.defaults.anchorPlateThinckness.value,
            AnchorPlateFactorDefault: this.settings.application.defaults.anchorPlateFactor.value,
            PermanentLoadsDefault: this.settings.application.defaults.permenentLoads.value,
            VariableLoadsDefault: this.settings.application.defaults.variableLoads.value,
            CompanyNameDefault: this.settings.user.companyDetails.companyName.value,
            AddressDefault: this.settings.user.companyDetails.address.value,
            EmailDefault: this.settings.user.companyDetails.email.value,
            AuthorDefault: this.settings.user.generalName.value,
            PhoneNumberDefault: this.settings.user.companyDetails.phoneNumber.value,
            FaxNumberDefault: this.settings.user.companyDetails.fax.value
        } as DefaultsOfUserSettings;
    }

    public initUserSettingsValidation(): void {
        this.validateSettings();
        this.userSettingsSaving.subscribe(() => this.validateSettings());
    }

    public validateSettings() {
        this.validateDesignStandard();
    }

    public getAdvancedBaseplateCalculation(regionId: number): AdvancedBaseplateCalculationDataEntity {
        const advCalc = this.settings.application.advancedCalculation;

        return {
            DivergentIterationsCount: this.getValueWithFallbacks(advCalc.divergentIterationsCount.value),
            DivisionOfArcsOfRHS: this.getValueWithFallbacks(advCalc.divisionOfArcsOfRHS.value),
            DivisionOfSurfaceOfCHS: this.getValueWithFallbacks(advCalc.divisionOfSurfaceOfCHS.value),
            EffectiveArea: this.getValueWithFallbacks(advCalc.effectiveArea.value),
            EffectiveAreaAISC: this.getValueWithFallbacks(advCalc.effectiveAreaAISC.value),
            JointCoefficientBj: this.getValueWithFallbacks(advCalc.jointCoefficientBj.value),
            LimitPlasticStrain: this.getValueWithFallbacks(advCalc.limitPlasticStrain.value),
            LoadDistributionAngle: this.getValueWithFallbacks(advCalc.loadDistributionAngle.value),
            AlphaCC: this.getValueWithFallbacks(advCalc.alphaCC.value),
            MaximumSizeOfElement: this.getValueWithFallbacks(advCalc.maximumSizeOfElement.value),
            MinimumSizeOfElement: this.getValueWithFallbacks(advCalc.minimumSizeOfElement.value),
            NumberOfAnalysisIterations: this.getValueWithFallbacks(advCalc.numberOfAnalysisIterations.value),
            NumberOfElementsOfEdge: this.getValueWithFallbacks(advCalc.numberOfElementsOfEdge.value),
            StopAtLimitStrain: undefined,
            UseULSStresses: this.getValueWithFallbacks(advCalc.useULSStresses.value),
            ConcreteInCompressionMethod: advCalc.concreteInCompressionMethod.value ?? this.getDefaultConcreteInCompressionMethod(regionId)
        };
    }

    public getRegionById(regionId: number | null) {
        const regionCodeList = this.codeListService.projectCodeLists[ProjectCodeList.Region] as Region[];
        return regionCodeList.find(region => region.id == regionId);
    }

    private getDefaultRegion(): Region {
        return new Region({
            id: SpecialRegion.Default,
            windZoneImage: '',
            defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard: null as unknown as number,
            defaultDesignMethodGroupForEurocodeDesignStandard: null as unknown as number,
            defaultDesignMethodGroupForISDesignStandard: null as unknown as number,
            defaultDesignMethodGroupForMSDesignStandard: null as unknown as number,
            defaultDesignMethodGroupForNZDesignStandard: null as unknown as number,
            ACIDesignMethodGroup: 6,
            anchorPlateFactor: 1,
            safetyFactorForPermamentLoads: 1,
            safetyFactorForVariableLoads: 1,
            thickness: 4,
            isWeldConfigAllowed: false,
            isStressCheckAllowed: false,
            isDisplacementLimitAllowed: false,
            defaultHandrailDisplacement: null as unknown as number,
            defaultAlphaCC: undefined,
            allowAdvancedBaseplate: false,
            isBridgesDisplacementLimitAllowed: false,
            defaultConcreteInCompressionMethod: undefined,
            defaultEurocodeDesignStandardSteelGuideLine: null as unknown as number,
            allowAdvancedBaseplateMetalDeck: false,
            includeParallelDesignInReport: false,
            defaultSteelGuideline: null as unknown as number,
            isSmartAnchorAvailable: false,
            allowAdvancedBaseplateHandrail: false,
            isDG1ExtensionAllowed: false,
            defaultDesignMethodGroupForRussianDesignStandard: 27,
            defaultMethodGroupForAustralianDesignStandard: 18,
            defaultMethodGroupForHongKongDesignStandard: 22,
            defaultDesignMethodGroupForCsaDesignStandard: 33,
            defaultMeasureAnchorPlate: 1,
            defaultMeasureBaseMaterialEdgeFrom: 1,
            commonRegion: new CommonRegion({
                id: SpecialRegion.Default,
                profisAnchorGuid: undefined,
                countryCode: SpecialRegionName.Default,
                image: undefined,
                onlineTechnicalLibraryUrl: undefined as unknown as string,
                hiltiBimCadLibraryUrl: undefined,
                profis3KBUrl: undefined as unknown as string,
                profis3KBUrlAci: undefined as unknown as string,
                hiltiOnlineUrl: undefined as unknown as string,
                contactUrl: undefined,
                supportPhone: undefined as unknown as string,
                supportHours: undefined as unknown as string,
                worldAreaId: 1,
                onlineTenderTextsUrl: undefined as unknown as string,
                hubId: 1,
                culture: undefined as unknown as string,
                dateTimeFormat: undefined,
                zipCodeFormat: undefined as unknown as string,
                hiltiDataConsentUrl: undefined as unknown as string,
                bpRigidityCheckUrl: undefined as unknown as string,
                dlubalEnabled: false,
                defaultUnitLength: undefined,
                defaultUnitLengthLarge: undefined,
                defaultUnitArea: undefined,
                defaultUnitStress: undefined,
                defaultUnitStressSmall: undefined,
                defaultUnitForce: undefined,
                defaultUnitMoment: undefined,
                defaultUnitTemperature: undefined,
                defaultUnitForcePerLength: undefined,
                defaultUnitMomentPerLength: undefined,
                defaultUnitDensity: undefined,
                defaultUnitAreaPerLength: undefined
            } as ICommonRegionConstructor)
        } as IRegionConstructor);
    }

    private getSelectedOrGlobalAllowedRegion(designTypeId?: DesignTypeEnum, regionId?: number) {
        if (designTypeId == null || regionId == null) {
            return undefined;
        }

        if (regionId == SpecialRegion.Default) {
            regionId = this.settings.application.general.regionId.value ?? undefined;
        }

        const designType = this.getDesignTypeById(designTypeId);
        if (designType?.allowedDesignStandardRegions?.some((row) => row.RegionId == regionId)) {
            return this.getRegionById(regionId ?? 0);
        }

        return this.getDefaultRegion();
    }


    private getDesignTypeById(designTypeId: DesignTypeEnum): DesignType | undefined {
        const designTypeCodeList = this.codeListService.projectCodeLists[ProjectCodeList.DesignType] as DesignType[];
        return designTypeCodeList.find(designType => designType.id == designTypeId);
    }


    private getDesignStandardById(designStandardId: DesignStandardEnum | undefined | null): DesignStandard | undefined {
        const designStandardCodeList = this.codeListService.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[];
        return designStandardCodeList.find(designStandard => designStandard.id == designStandardId);
    }

    private getDesignStandardsByDesignType(designType: DesignType | undefined, designTypeRegionId: number | undefined): DesignStandard[] {
        const allDesignStandards = this.codeListService.projectCodeLists[ProjectCodeList.DesignStandard] as DesignStandard[];
        return getAllowedDesignStandardsByDesignType(allDesignStandards, designType, designTypeRegionId ?? 0, null as unknown as number) ?? [];
    }


    private getDesignMethodGroupById(designMethodGroupId: DesignMethodGroupEnum | undefined | null): DesignMethodGroup | undefined {
        const designMethodGroupCodeList = this.codeListService.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[];
        return designMethodGroupCodeList.find(designMethodGroup => designMethodGroup.id == designMethodGroupId);
    }

    private getDefaultDesignMethodGroupId(designTypeId: DesignTypeEnum | undefined, designStandard: DesignStandard | undefined, region: Region | undefined): number | undefined {
        if (designTypeId == null || designStandard == null || region == null) {
            return undefined;
        }

        const designMethodGroup = this.getDefaultDesignMethodGroupIdForDesignTypeStandardRegion(designTypeId, designStandard, region);
        if (designMethodGroup != null) {
            return designMethodGroup;
        }

        const designType = this.getDesignTypeById(designTypeId);
        let designMethodGroups = (this.codeListService.projectCodeLists[ProjectCodeList.DesignMethodGroup] as DesignMethodGroup[])
            .filter((designMethodGroup) => designMethodGroup.designStandards?.includes(designStandard.id));

        // filter design method groups by design type and region
        designMethodGroups = designMethodGroups.filter((designMethodGroup) => designType?.allowedDesignMethodGroupRegions?.some((row) => row.RegionId == region.id && row.DesignMethodGroups.includes(designMethodGroup.id)));

        // In case of Masonry design type, ETAG design standard is removed for new designs but can be used for old designs
        designMethodGroups = designMethodGroups.filter((designMethodGroup) => designMethodGroup.id != DesignMethodGroupEnum.ETAG029);

        const firstDesignMethodGroup = designMethodGroups.length > 0
            ? designMethodGroups[0]
            : null;
        return firstDesignMethodGroup?.id;
    }

    private getDefaultDesignMethodGroupIdForDesignTypeStandardRegion(designTypeId: DesignTypeEnum | undefined, designStandard: DesignStandard | undefined, region: Region | undefined) {
        if (designTypeId == null || designStandard == null || region == null) {
            return undefined;
        }

        if ((designTypeId == DesignTypeEnum.Concrete || designTypeId == DesignTypeEnum.Handrail) && designStandard.id == DesignStandardEnum.ETAG) {
            return region.defaultDesignMethodGroupForConcreteHandrailEtagDesignStandard;
        }
        if ((designTypeId == DesignTypeEnum.Concrete || designTypeId == DesignTypeEnum.Handrail) && (designStandard.id == DesignStandardEnum.EC2 || designStandard.id == DesignStandardEnum.CN)) {
            return region.defaultDesignMethodGroupForEurocodeDesignStandard;
        }
        if ((designTypeId == DesignTypeEnum.Concrete || designTypeId == DesignTypeEnum.Handrail) && designStandard.id == DesignStandardEnum.NZ) {
            return region.defaultDesignMethodGroupForNZDesignStandard;
        }
        if ((designTypeId == DesignTypeEnum.Concrete || designTypeId == DesignTypeEnum.MetalDeck) && designStandard.id == DesignStandardEnum.ACI) {
            return region.ACIDesignMethodGroup;
        }
        if (designTypeId == DesignTypeEnum.Concrete && designStandard.id == DesignStandardEnum.IS) {
            return region.defaultDesignMethodGroupForISDesignStandard;
        }
        if (designTypeId == DesignTypeEnum.Concrete && designStandard.id == DesignStandardEnum.MS) {
            return region.defaultDesignMethodGroupForMSDesignStandard;
        }
        return undefined;
    }


    private getDefaultSteelGuideline(designStandardId: number | undefined, generalRegion: Region): SteelGuideline {
        const regionId = generalRegion != null && generalRegion.id > 0
            ? generalRegion.id
            : this.settings.application.general.regionId.value ?? 0;
        const selectedRegion = this.getRegionById(regionId);
        if (selectedRegion == null) {
            return SteelGuideline.None;
        }

        return findSteelCalculationMethod(this.codeListService.projectCodeLists, designStandardId, selectedRegion);
    }


    private getEdgeDistanceDisplayTypeById(edgeDistanceDisplayId: number | null): EdgeDistanceDisplayType | undefined {
        const edgeDistanceDisplayTypeCodeList = this.codeListService.projectCodeLists[ProjectCodeList.EdgeDistanceDisplayType] as EdgeDistanceDisplayType[];
        return edgeDistanceDisplayTypeCodeList.find(region => region.id == edgeDistanceDisplayId);
    }

    private defaultEdgeDistanceCodelistItem(type: DesignTypeEnum): EdgeDistanceDisplayType {
        const val = defaultEdgeDistance(type);
        return this.getPeCodelistItemById<EdgeDistanceDisplayType>(ProjectCodeList.EdgeDistanceDisplayType, val);
    }


    /**
     * Reads the code list value based on the string stored in the settings service.
     * Implementation details:
     *      If value returned form service is equal to null or 0, than ste unit is null whitch also indicates that default unit should be used.
     * @param codeListType The code list type
     * @param val The val
     */
    private getUnitFromCodeList(codeListType: CommonCodeList, val: string | number | null | undefined): Unit | undefined {
        let valNum: number;

        if (val == null) {
            return undefined;
        }

        if (typeof val === 'number') {
            if (val == null || val == 0) {
                return undefined;
            }
            valNum = val;
        }
        else if (typeof val === 'string') {
            if (val == null || val == '' || val == '0') {
                return undefined;
            }
            valNum = parseInt(val, 10);
        }

        const unitsCodeList = this.commonCodeListService.commonCodeLists[codeListType] as Unit[];
        return unitsCodeList.find((unit) => unit.id == valNum);
    }

    private getPeCodelistItemById<Type extends CodeList>(codelistType: ProjectCodeList, val: any) {
        const valNum = val == null ? null : parseInt(val, 10);
        const edgeDistanceCodeList = this.codeListService.projectCodeLists[codelistType] as Type[];
        const selected = edgeDistanceCodeList.find((item) => item.id == valNum);
        return selected as Type;
    }


    private getDefaultConcreteInCompressionMethod(regionId: number) {
        const selectedRegion = this.getRegionById(regionId);
        return selectedRegion?.defaultConcreteInCompressionMethod;
    }

    private validateDesignStandard() {
        // ETAG was completely removed, so convert all ETAG settings to EC2 + ETAG based for concrete and handrail
        if (this.settings.quickStart.concrete.calculationDesignStandardId.value == DesignStandardEnum.ETAG) {
            this.settings.quickStart.concrete.calculationEtagEnOnly.value = this.settings.quickStart.concrete.calculationDesignMethodGroupId.value == DesignMethodGroupEnum.ETAG;
            this.settings.quickStart.concrete.calculationDesignStandardId.value = DesignStandardEnum.EC2;
            this.settings.quickStart.concrete.calculationDesignMethodGroupId.value = DesignMethodGroupEnum.ETAG_Based;
        }
        if (this.settings.quickStart.handrail.calculationDesignStandardId.value == DesignStandardEnum.ETAG) {
            this.settings.quickStart.handrail.calculationEtagEnOnly.value = this.settings.quickStart.handrail.calculationDesignMethodGroupId.value == DesignMethodGroupEnum.ETAG;
            this.settings.quickStart.handrail.calculationDesignStandardId.value = DesignStandardEnum.EC2;
            this.settings.quickStart.handrail.calculationDesignMethodGroupId.value = DesignMethodGroupEnum.ETAG_Based;
        }
    }

    public isDefaultEnAndSofaBasedDesignMethod(designStandard: number | null, designMethod: number | null, regionId: number | null): boolean {
        const region = this.getRegionById(regionId ?? 0)
            ?? this.getDefaultRegion();
        if (!region) {
            return false;
        }

        if ((designStandard == DesignStandardEnum.EC2 || designStandard == DesignStandardEnum.NZ || designStandard == DesignStandardEnum.MS
            || designStandard == DesignStandardEnum.CN || designStandard == DesignStandardEnum.IS || designStandard == DesignStandardEnum.SATS
        )
            && (designMethod == DesignMethodGroupEnum.EN_Based || designMethod == DesignMethodGroupEnum.SOFA_Based)) {
            return true;
        }

        if (designStandard == DesignStandardEnum.HK
            && (designMethod == DesignMethodGroupEnum.EN_AnchorDesign || designMethod == DesignMethodGroupEnum.SOFA_Based)
        ) {
            return true;
        }

        return false;
    }
}
