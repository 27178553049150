import { Subscription } from 'rxjs';
import Sortable, { Options } from 'sortablejs';

import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, NgZone, OnDestroy, OnInit,
    ViewChild, ViewEncapsulation
} from '@angular/core';
import { Vector2 } from '@babylonjs/core/Maths/math.vector';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Change, Update } from '@profis-engineering/gl-model/base-update';
import { IModel, Mode2d, View2dModeType, ViewType } from '@profis-engineering/gl-model/gl-model';
import { Anchor2d } from '@profis-engineering/pe-gl-model/components/2d/anchor-2d';
import { Center2dPe } from '@profis-engineering/pe-gl-model/components/2d/center-2d';
import { plate2dConstants } from '@profis-engineering/pe-gl-model/components/2d/plate-2d-helper';
import {
    profile2dConstants
} from '@profis-engineering/pe-gl-model/components/2d/profile-2d-helper';
import {
    IModelPe, IScreenShotSettingsPe, TextureDisplay
} from '@profis-engineering/pe-gl-model/gl-model';
import { Mode2dPe } from '@profis-engineering/pe-gl-model/mode-2d';
import {
    CheckboxButtonGroupItem, CheckboxButtonGroupProps, CheckboxButtonItem, CheckboxButtonProps
} from '@profis-engineering/pe-ui-common/components/checkbox-button/checkbox-button.common';
import {
    IConfirmChangeProps
} from '@profis-engineering/pe-ui-common/components/confirm-change/confirm-change.common';
import {
    DropdownItem, DropdownProps
} from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import {
    ToggleButtonGroupItem, ToggleButtonGroupProps
} from '@profis-engineering/pe-ui-common/components/toggle-button-group/toggle-button-group.common';
import {
    getCodeListTextDeps
} from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Context3dKey } from '@profis-engineering/pe-ui-common/entities/context-3d';
import { DesignEvent, StateChange } from '@profis-engineering/pe-ui-common/entities/design';
import { IDesignSectionComponent } from '@profis-engineering/pe-ui-common/entities/design-section';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { IMainHeaderComponent } from '@profis-engineering/pe-ui-common/entities/main-header';
import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import {
    IMainMenuComponent, IMenu
} from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import {
    BaseControl, NavigationTabWidth, UIPropertyBaseControl
} from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { IDesignInfo } from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    INotification,
    INotificationsComponentInput, INotificationScopeCheck, NotificationLocation, NotificationType
} from '@profis-engineering/pe-ui-common/entities/notifications';
import { Project } from '@profis-engineering/pe-ui-common/entities/project';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    Feature, KnownRegion
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.Common.Shared.Models.Enums';
import { TrackingDesignType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.CommonTrackingService.Shared.Enums';
import {
    DataIntegrationType
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.IntegrationServices.Shared.Entities.Enums';
import { MenuType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.UserSettings.Shared.Enums';
import {
    SafeFunctionInvokerHelper
} from '@profis-engineering/pe-ui-common/helpers/safe-function-invoker-helper';
import { format } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup, UnitType as Unit } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import {
    IDesignTemplateDocument
} from '@profis-engineering/pe-ui-common/services/design-template.common';
import { Controls2dEditorBase } from '@profis-engineering/pe-ui-common/services/menu-2d.common';
import { IntroJs } from '@profis-engineering/pe-ui-common/services/tour.common';
import { ProjectAndDesignView } from '@profis-engineering/pe-ui-common/services/user.common';
import { environment } from '../../../environments/environmentPe';
import { GlModelPeProps, IGlModelPeComponent } from '../../../shared/components/gl-model';
import { DesignPe as Design, DesignPe, IDesignState } from '../../../shared/entities/design-pe';
import { IDetailedDisplayDesign } from '../../../shared/entities/display-design';
import { MainMenuCommandPE } from '../../../shared/entities/main-menu';
import { NumericalParameter } from '../../../shared/entities/translation-parameters';
import {
    TranslationFormat, TranslationParameter
} from '../../../shared/generated-modules/Hilti.PE.Core.Common.Entities.Translation';
import {
    TranslationParameterType
} from '../../../shared/generated-modules/Hilti.PE.Core.Common.Entities.Translation.Enums';
import {
    MeasureAnchorPlateMode, MeasureBaseMaterialEdgeFromMode
} from '../../../shared/generated-modules/Hilti.PE.Core.Common.Enums';
import {
    ScopeCheckResultItem
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.DesignReportData';
import {
    ScopeCheckFlags
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.Enums';
import {
    ScopeCheck, ScopeCheckButtonParameter
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Calculation.ScopeChecks';
import {
    UIProperty, UIPropertyValueSelect
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.Display';
import {
    AnchorLayoutPointEntity, Point2DEntity
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign';
import {
    AdvancedCalculationType, AnchorFilterType, DesignMethodGroup, DesignStandard, DesignType,
    LoadType, PlateShape, ProfileShapeType, StaticLoadType, SupplementaryReinforcementCategory
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Baseplate.ProjectDesign.Enums';
import {
    PointsTableType
} from '../../../shared/generated-modules/Hilti.PE.Core.Entities.Navigation';
import { PropertyMetaData } from '../../../shared/properties/properties';
import { Controls2dEditor, IMenu2dContext } from '../../../shared/services/menu-2d.service.base';
import { CollapsingControls } from '../../entities/collapsing-controls';
import { IExportReportSupportMethodsPe } from '../../entities/export-report';
import { ApprovalHelper } from '../../helpers/approval-helper';
import {
    areLoadCombinationsAvailable, getLoadCombinationNumberTextById
} from '../../helpers/load-combination-helper';
import {
    isSmartAnchorAllowed, smartAnchorDefaultScopeChecks
} from '../../helpers/smart-anchor-helper';
import { ApplicationProviderService } from '../../services/application-provider.service';
import { CalculationServicePE } from '../../services/calculation-pe.service';
import { ChangesService } from '../../services/changes.service';
import { DesignSettingsService } from '../../services/design-settings.service';
import { DesignTemplateService } from '../../services/design-template.service';
import { DocumentService } from '../../services/document.service';
import { FavoritesService } from '../../services/favorites.service';
import { FeaturesVisibilityInfoService } from '../../services/features-visibility-info.service';
import { FeaturesVisibilityService } from '../../services/features-visibility.service';
import { IntegrationsDataService } from '../../services/integrations-data.service';
import { IntegrationsDocumentService } from '../../services/integrations-document.service';
import { IntegrationsNotificationService } from '../../services/integrations-notification.service';
import { LicenseService } from '../../services/license.service';
import { LocalizationService } from '../../services/localization.service';
import { Menu2dService } from '../../services/menu-2d.service';
import { MenuService } from '../../services/menu.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { OfflineService } from '../../services/offline.service';
import { ProductInformationService } from '../../services/product-information.service';
import { RegionOrderService } from '../../services/region-order.service';
import { RoutingService } from '../../services/routing.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { SignalRService } from '../../services/signalr.service';
import { TourService } from '../../services/tour.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { getSpriteAsIconStyle, includeSprites } from '../../sprites';
import { AfterOpenInstruction } from '../add-edit-design/add-edit-design.component';

type MainMenuCommands = MainMenuCommandPE;

enum DisplayOption {
    BaseplateDimensions,
    ConcreteDimensions,
    HandrailDimensions,
    ProfileMeasurments,
    AnchorLayoutMeasurments,
    Loads,
    LoadsHeight,
    AnchorNumbering,
    PlateNumbering,
    TransparentBaseMaterial,
    ProfileCenterToConcreteEdge,
    ProfileEdgeToConcreteEdge,
    ProfileEdgeToBaseplateEdge,
    AnchorSpacing,
    Rotation,
    ProfileCenterToBaseplateCenter,
    Mesh,
    TransparentProfile,
    Stiffener2D,
    SupplementaryReinforcementDimensions,
    ToolTips,
    AnchorCenterToConcreteEdge,
    ShearLugMeasurements
}

export enum DisplayOptionEditor {
    Editor3D,
    Editor2D,
    Both
}

const enum FloatingInfo {
    InfoSection = 1,
    WeldCurves = 2
}

const setTimeoutPromise = (handler: () => void, timeout?: number) => new Promise<void>((resolve, reject) => setTimeout(() => {
    try {
        handler();
        resolve();
    }
    catch (error) {
        reject(error);
    }
}, timeout));

@Component({
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss', './main-imports.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input()
    public glDebug!: boolean;

    // TODO BUDQBP-31813: This function should be moved to corresponding module to be used directly in module, and not passed here. Then cleanup main-component in pe-ui.
    @Input()
    public openConfirmChangeForOffline!: () => void;

    // TODO BUDQBP-31813: This function should be moved to corresponding module to be used directly in module, and not passed here. Then cleanup main-component in pe-ui.
    @Input()
    public publish!: () => Promise<void>;

    // TODO BUDQBP-31813: This function should be moved to corresponding module to be used directly in module, and not passed here. Then cleanup main-component in pe-ui.
    @Input()
    public processDesignClose!: () => Promise<void>;

    // TODO BUDQBP-31813: This function should be moved to corresponding module to be used directly in module, and not passed here. Then cleanup main-component in pe-ui.
    @Input()
    public processDesignBrowserUnload!: () => Promise<void>;

    @ViewChild('mainContentCenterRightRef')
    public mainContentCenterRightRef!: ElementRef<HTMLElement>;

    @ViewChild('mainContentCenterRightTopRef')
    public mainContentCenterRightTopRef!: ElementRef<HTMLElement>;

    @ViewChild('mainContentRightUtilRef')
    public mainContentRightUtilRef!: ElementRef<HTMLElement>;

    public CollapsingControls = CollapsingControls;
    public DesignTypeId: Record<keyof typeof DesignType, DesignType> = {
        Unknown: DesignType.Unknown,
        Concrete: DesignType.Concrete,
        Handrail: DesignType.Handrail,
        Masonry: DesignType.Masonry,
        MetalDeck: DesignType.MetalDeck
    };
    public ViewType: Record<keyof typeof ViewType, ViewType> = {
        View2d: ViewType.View2d,
        View3d: ViewType.View3d
    };
    public FloatingInfo: Record<keyof typeof FloatingInfo, FloatingInfo> = {
        InfoSection: FloatingInfo.InfoSection,
        WeldCurves: FloatingInfo.WeldCurves
    };

    public design!: Design;

    public displayOptionsCheckbox!: Pick<CheckboxButtonProps<DisplayOption>, 'selectedValues' | 'items'>;
    public displayOptionsCheckboxConcrete!: Pick<CheckboxButtonGroupProps<DisplayOption>, 'selectedValues' | 'groupItems'>;
    public mode2dToggleButtonGroup!: Pick<ToggleButtonGroupProps<Mode2dPe>, 'items'>;

    public modelViewZoom = 0;
    public draggingSelectionOptions!: string;
    public anchorText!: string;
    public baseplateText!: string;
    public draggingDisabledPlate!: string;
    public showDraggingTooltipPlateDisabled = false;
    public rightSideLoaded = false;
    public hideLeftMenu = false;
    public hideRightMenu = false;
    public userLogout = false;
    public switchToManualProps?: IConfirmChangeProps;
    public asadResetPropertyList: UIProperty[] = [
        UIProperty.BaseMaterial_EdgeXPositiveFromAnchor,
        UIProperty.BaseMaterial_EdgeXNegativeFromAnchor,
        UIProperty.BaseMaterial_EdgeYPositiveFromAnchor,
        UIProperty.BaseMaterial_EdgeYNegativeFromAnchor,
        UIProperty.BaseMaterial_EdgeXPositiveFromProfileCenter,
        UIProperty.BaseMaterial_EdgeXNegativeFromProfileCenter,
        UIProperty.BaseMaterial_EdgeYPositiveFromProfileCenter,
        UIProperty.BaseMaterial_EdgeYNegativeFromProfileCenter,
        UIProperty.BaseMaterial_EdgeXPositiveFromConcreteEdge,
        UIProperty.BaseMaterial_EdgeXNegativeFromConcreteEdge,
        UIProperty.BaseMaterial_EdgeYPositiveFromConcreteEdge,
        UIProperty.BaseMaterial_EdgeYNegativeFromConcreteEdge,
        UIProperty.AnchorLayout_Spacing_SX,
        UIProperty.AnchorLayout_Spacing_SY,
        UIProperty.AnchorLayout_OffsetXFromAnchorPlate,
        UIProperty.AnchorLayout_OffsetYFromAnchorPlate
    ];

    @ViewChild('designSectionRef')
    public designSectionComponentRef!: ElementRef<IDesignSectionComponent>;

    @ViewChild('glModelRef')
    public glModelComponentElementRef!: ElementRef<IGlModelPeComponent>;

    @ViewChild('mainMenuRef')
    public mainMenuComponentElementRef!: ElementRef<IMainMenuComponent>;

    @ViewChild('displayOptionsDropdown', { static: true })
    public displayOptionsDropdownRef!: NgbDropdown;

    @ViewChild('displayOptionsDropdownElementRef')
    public displayOptionsDropdownElementRef!: ElementRef<HTMLElement>;

    @ViewChild('mainHeaderRef')
    public mainHeaderComponentRef!: ElementRef<IMainHeaderComponent>;

    public glModel!: Pick<GlModelPeProps,
        'continuousRender' |
        'model' |
        'onFontsLoaded' |
        'onZoom' |
        'onSelectTab' |
        'onPositionsChanged' |
        'onDraggingSelectionChanged'
    >;

    public PropertyMetaData = PropertyMetaData;
    public Context3dKeyMain = Context3dKey.Main;

    public calculationOutputDropdown!: Pick<DropdownProps<TextureDisplay>, 'selectedValue' | 'items'>;
    public sortableMenu3DRightOptions!: Options;

    public exportReportSupportMethodsPe!: IExportReportSupportMethodsPe;

    public floatingInfo?: FloatingInfo;

    public view: ViewType = ViewType.View3d;
    public showDraggingTooltip = false;

    public notificationComponentInputs!: INotificationsComponentInput;

    private menu3dSelectedTab!: string;

    private localizationChangeSubscription!: Subscription;

    private controllingDimensionsChangeSubscription!: Subscription;

    private _mode2d = Mode2d.Pointer;

    private openedVirtualTour?: IntroJs;

    private _asadUseLambda = new Set<boolean>();

    public isSmartAnchorVirtualTour = false;

    public asadDebugNotification!: INotification;


    constructor(
        public localizationService: LocalizationService,
        public userSettingsService: UserSettingsService,
        public designSettingsService: DesignSettingsService,
        public licenseService: LicenseService,
        public offlineService: OfflineService,
        public modalService: ModalService,
        private readonly routingService: RoutingService,
        private readonly sharedEnvironmentData: SharedEnvironmentService,
        private readonly userService: UserService,
        private readonly regionOrderService: RegionOrderService,
        private readonly unitService: UnitService,
        private readonly productInformationService: ProductInformationService,
        private readonly featuresVisibilityInfoService: FeaturesVisibilityInfoService,
        private readonly numberService: NumberService,
        private readonly designTemplateService: DesignTemplateService,
        private readonly documentService: DocumentService,
        private readonly integrationsNotificationService: IntegrationsNotificationService,
        private readonly integrationsDataService: IntegrationsDataService,
        private readonly integrationsDocumentService: IntegrationsDocumentService,
        private readonly tourService: TourService,
        private readonly menuService: MenuService,
        private readonly menu2dService: Menu2dService,
        private readonly favoritesService: FavoritesService,
        private readonly changesService: ChangesService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly calculationService: CalculationServicePE,
        private readonly signalrService: SignalRService,
        private readonly translationFormatService: TranslationFormatService,
        private readonly applicationProviderService: ApplicationProviderService,
        private readonly ngZone: NgZone,
        private readonly elementRef: ElementRef<HTMLElement>,
        private readonly featuresVisbilityService: FeaturesVisibilityService
    ) { }


    // We count any click
    @HostListener('document:click', ['$event'])
    handlerFunction() {
        this.design.usageCounter.GeneralClicks++;
    }

    public get isNewHomePage() {
        return this.featuresVisbilityService.isFeatureEnabled('PE_EnableNewHomePage');
    }

    public get zoomUnit() {
        return Unit.percent;
    }

    public get glModelComponent() {
        return this.glModelComponentElementRef?.nativeElement;
    }

    public get mainMenuComponent() {
        return this.mainMenuComponentElementRef?.nativeElement;
    }

    public get selectedMenu(): IMenu {
        return this.mainMenuComponent?.getSelectedMenu() ?? {} as IMenu;
    }

    public get isAsadVisible() {
        const isAsad = this.design.model[UIProperty.SmartAnchor_Enabled] as boolean;

        if (!isAsad) {
            this.design.setAnchorAISolutionSelected(false);
        }

        return isAsad;
    }

    public get isAsadPlateHidden() {
        return this.design.model[UIProperty.SmartAnchor_PlateHidden] as boolean;
    }

    public get isUtilizationInLoadsVisible() {
        return !this.isAsadVisible || this.isAnchorAISolutionSelected;
    }

    public get useNewDisplayOptionsDropdown() {
        return this.design.designType.id == DesignType.Concrete;
    }

    public get mode2d() {
        return this._mode2d;
    }
    public set mode2d(value) {
        if (this._mode2d !== value) {
            this._mode2d = value;

            this.mode2d == Mode2d.Pan ? this.setCursor('move') : this.setCursor('');

            this.glModelComponent.update({
                editor2d: { mode: this.mode2d }
            });
        }
    }

    public get weldCurves() {
        return this.design.baseplateDesignData?.WeldCurves;
    }

    public get regionLanguage() {
        return this.userSettingsService.getRegionLanguage();
    }

    public get language() {
        return this.userSettingsService.getLanguage();
    }

    public get exportReportDisabled() {
        return this.design.pendingCalculation || !this.design.designData.reportData.CanExportReport;
    }

    public get hasExtendedWidth() {
        if (this.selectedMenu?.tabs == null)
            return false;

        const selectedTab = this.selectedMenu?.tabs[this.selectedMenu.selectedTab];
        const canExtend = selectedTab?.width == NavigationTabWidth.Extended;
        return canExtend && !this.hideLeftMenu;
    }

    public get isLoading() {
        return this.design.loading;
    }

    public get title() {
        let title = this.design.designName;

        if (this.design.isTemplate) {
            title = this.design.templateName ?? '';
        }

        if (this.design.isTemplate) {
            title += ` (${this.translate('Agito.Hilti.Profis3.Main.TemplateProjectName')})`;
        }
        else if (!this.offlineService.isOffline) {
            title += ` (${this.userService.project.getDisplayName(this.localizationService)})`;
        }

        const codeListDeps = getCodeListTextDeps(this.localizationService, this.numberService);
        title += ` - ${this.design.region.getTranslatedNameText(codeListDeps)}, `;

        if (this.design.isCBFEMCalculation || this.design.designType.id == DesignType.Handrail) {
            title += `${this.translate('Agito.Hilti.Profis3.Main.Steel')} ${this.design.steelMethod}, ${this.translate('Agito.Hilti.Profis3.Main.Anchor')} `;
        }

        const designTypeSpecificKey = `${this.design.designStandard.nameResourceKey}.${this.design.designType.displayKey}`;

        title += this.localizationService.getKeyExists(designTypeSpecificKey)
            ? this.localizationService.getString(designTypeSpecificKey)
            : `${this.design.designStandard.getTranslatedNameText(codeListDeps)}`;

        if (this.design.designMethodName != null && this.design.designMethodName != '') {
            title += `, ${this.design.designMethodName}`;
        }

        if (this.design.ApprovalNumberSTO != null && this.design.ApprovalNumberSTO != '') {
            title += `, ${this.design.ApprovalNumberSTO}`;
        }
        else if (this.design.ApprovalNumberUKTA != null && this.design.ApprovalNumberUKTA != '') {
            title += `, ${this.design.ApprovalNumberUKTA}`;
        }
        else if (this.design.approvalNumber != null && this.design.approvalNumber != '') {
            title += `, ${this.design.approvalNumber}`;
        }

        return title;
    }

    public get displayOptionsTooltip() {
        return this.translate(this.view == ViewType.View3d ? 'Agito.Hilti.Profis3.Main.ShowHideElements' : 'Agito.Hilti.Profis3.Main.ShowHideElements2D');
    }

    public get undoRedoHidden() {
        return this.featuresVisibilityInfoService.isHidden(Feature.Design_UndoRedo, this.design.region.id);
    }

    public get undoRedoDisabled() {
        return this.featuresVisibilityInfoService.isDisabled(Feature.Design_UndoRedo, this.design.region.id);
    }

    public get undoTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_UndoRedo) ||
            this.localizationService.getString('Agito.Hilti.Profis3.Main.Undo');
    }

    public get redoTooltip() {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_UndoRedo) ||
            this.localizationService.getString('Agito.Hilti.Profis3.Main.Redo');
    }

    public get showCustomStiffenersControl() {
        if (this.view != ViewType.View2d ||
            !this.design.isCBFEMCalculation ||
            !this.design.isCustomStiffenerLayout
        ) {
            return false;
        }

        if (!this.design.designData.projectDesign.IsProfilePresent) {
            return false;
        }

        if (this.design.profileFamily?.shape == ProfileShapeType.Pipe) {
            return this.design.profileHeight == this.design.profileWidth;
        }

        return true;
    }

    public get isPlateEditable() {
        return !(this.design.properties.get(UIProperty.AnchorPlate_PlateShapeCustom).disabled || this.design.properties.get(UIProperty.AnchorPlate_PlateShapeCustom).hidden) && (this.design.model[UIProperty.AnchorPlate_CustomLayoutPoints] as number[]).length > 2;
    }

    public get areAnchorsEditable() {
        return this.design.designType.id != TrackingDesignType.Concrete2Concrete;
    }

    public get isAsad() {
        return this.userService.isAsadEnabled;
    }

    public get isAsadDebugEnabled() {
        return this.userService.isAsadDebugEnabled;
    }

    public get isAnchorAIEnable() {
        return this.design.model[UIProperty.SmartAnchor_Enabled];
    }

    public get hasLoadsInWorstPosition() {
        return this.design?.punctualLoadInWorstPosition
            || this.design?.verticalPunctualLoadInWorstPosition
            || !this.design?.isinfillPunctualLoadInwardsHidden
            || !this.design?.isinfillPunctualLoadOutwardsHidden;
    }

    public get isAnchorAISolutionSelected() {
        return (this.design.isAnchorAISolutionSelected && !this.isAsadPlateHidden) || this.hasLastSelectedSolution;
    }

    public get hasLastSelectedSolution() {
        return this.design.model[UIProperty.SmartAnchor_IsLastSelectedSolution] as boolean;
    }

    private get isAnchorEditable() {
        return !(this.design.properties.get(UIProperty.AnchorLayout_LayoutCustom).disabled || this.design.properties.get(UIProperty.AnchorLayout_LayoutCustom).hidden);
    }

    private get showMeshOption() {
        return this.showCalculationOutputDropdown();
    }

    private get showSupplementaryReinforcementDimensions() {
        return this.displayOptionsVisible(DisplayOptionEditor.Editor3D)
            && this.design.designType.id == DesignType.Concrete
            && this.design.designStandard.id == DesignStandard.EC2
            || this.design.designStandard.id == DesignStandard.CN
            || this.design.designStandard.id == DesignStandard.NZ
            || this.design.designStandard.id == DesignStandard.MS;
    }

    private get showShearLugMeasurements() {
        return this.displayOptionsVisible(DisplayOptionEditor.Editor3D)
            && this.design.designType.id == DesignType.Concrete
            && this.design.designStandard.id == DesignStandard.ACI
            && this.design.region.id == KnownRegion.UnitedStates;
    }

    private get getCalculationOutputDropdownSelectedValue(): TextureDisplay {
        switch (this.design.designType.id) {
            case DesignType.Concrete:
            case DesignType.MetalDeck:
            case DesignType.Masonry:
                return TextureDisplay.Solid3D;
            case DesignType.Handrail:
                return TextureDisplay.EquivalentStress;
            default:
                throw new Error('Invalid Design type');
        }
    }

    private set isSmartAnchorVirtualTourEnabled(value: boolean) {
        this.isSmartAnchorVirtualTour = value;
    }

    public get hasScopeChecks() {
        return this.scopeChecks.length > 0;
    }

    public get scopeChecks() {
        return this.design?.designData.reportData.ScopeCheckResultItems ?? [];
    }

    public get alertScopeChecks(): ScopeCheckResultItem[] {
        if (this.isAsadVisible) {
            return this.design?.designData.reportData.ScopeCheckResultItems?.filter(sc => this.getScopeCheckType(sc) == NotificationType.alert).filter(sc => sc.ScopeCheckId != ScopeCheck.SC_STANDOFF_SHEAR_EDGE_DISTANCE_INSUFFICIENT) ?? [];
        }
        return this.design?.designData.reportData.ScopeCheckResultItems?.filter(sc => this.getScopeCheckType(sc) == NotificationType.alert) ?? [];
    }

    public get notificationButtonsHidden() {
        return this.featuresVisibilityInfoService.isHidden(Feature.Design_ScopeCheckButtons, this.design?.region.id);
    }

    public isScopeCheckError(scopeCheck: ScopeCheckResultItem) {
        return (scopeCheck.IndicatesCalculationError
            && scopeCheck.ScopeCheckId == ScopeCheck.SC_CALCULATION_ERROR);
    }

    public isScopeCheckButtonVisible(list: UIPropertyValueSelect[]) {
        return list != null && list.every(prop => prop.IgnoreVisibilityCheck || !this.design?.properties.get(prop.Property).hidden) && !this.notificationButtonsHidden;
    }

    public scopeCheckButtonsDisabled(actionButton: ScopeCheckButtonParameter) {
        return this.design?.pendingCalculation ||
            this.featuresVisibilityInfoService.isDisabled(Feature.Design_ScopeCheckButtons) ||
            (actionButton.UIPropertyValueList.some(x => x.GenerateReport) && !this.design?.designData.reportData.CanExportReport) ||
            (this.design?.isReadOnlyDesignMode && !actionButton.EnabledInReadOnlyMode);
    }

    public scopeCheckSupportButtonClick() {
        // open support modal
        this.modalService.openSupport({
            correlationId: this.signalrService.common.requestId,
            requestPayload: this.signalrService.common.requestData,
            responsePayload: this.signalrService.common.responseData,
            endPointUrl: this.signalrService.common.connectionUrl
        });
    }

    public scopeCheckButtonClick(list: UIPropertyValueSelect[]) {
        for (const UIValue of list) {
            if (UIValue.Property != UIProperty.None) {
                this.design.addModelChangeNoCalculation(UIValue.Property, true, UIValue.Value);
                switch (UIValue.Property) {
                    case UIProperty.Optimize_AxialSpacing:
                        this.design.usageCounter.AdjustAnchorSpacing++;
                        break;
                    case UIProperty.Optimize_BaseMaterialThickness:
                        this.design.usageCounter.AdjustBaseMaterialThickness++;
                        break;
                    case UIProperty.Optimize_EdgeDistance:
                        this.design.usageCounter.AdjustEdgeDistance++;
                        break;
                    case UIProperty.Optimize_AnchorPlateSize:
                        this.design.usageCounter.AdjustPlateSize++;
                        break;
                    case UIProperty.Optimize_AnchorPlatePosition:
                        this.design.usageCounter.AdjustPlatePosition++;
                        break;
                    case UIProperty.SmartAnchor_Enabled:
                        this.design.usageCounter.SmartAnchor_ToggleChanged++;
                        break;
                }
            }
        }

        if (list.some(x => x.GenerateReport)) {
            this.modalService.openExportReport({
                createGlModelScreenshot: this.exportReportSupportMethodsPe.createGlModelScreenshot,
                onModelImagesCreated: () => { return; }
            });
        }
        else if (list.some(x => x.RunAdvancedCalculation)) {
            this.calculationService.calculateAdvancedBaseplateAll(this.design);
        }
        else {
            this.calculationService.calculateAsync(this.design);
        }
    }

    public getButtonTitle(actionButton: ScopeCheckButtonParameter) {
        let translationText = this.localizationService.getString(actionButton.ButtonTitle);

        if (actionButton.TitleTranslationParameters == null) {
            return translationText;
        }

        for (const key in actionButton.TitleTranslationParameters) {
            translationText = translationText.replace(`{${key}}`, actionButton.TitleTranslationParameters[key]);
        }

        return translationText;
    }

    public notificationButtonsTooltip(translationKey: string) {
        return this.featuresVisibilityInfoService.tooltip(Feature.Design_ScopeCheckButtons) ||
            this.localizationService.getString(translationKey);
    }

    public getScopeCheckHtml(scopeCheckMessage: TranslationFormat) {
        // Handle all numerical parameters
        const transformedParams = this.translationFormatService.transformTranslationParameters(
            scopeCheckMessage.TranslationParameters,
            true,
            undefined,
            this.getNumericScopeCheckHtml.bind(this)
        );

        const html = this.translationFormatService.getLocalizedStringWithTranslationFormat(
            scopeCheckMessage,
            true,
            transformedParams
        );

        return html;
    }

    private getNumericScopeCheckHtml(parameter: TranslationParameter, roundValue: boolean) {
        if (
            parameter.ParameterType !== TranslationParameterType.Numerical ||
            !roundValue
        ) {
            // Handle only Numerical parameters with rounding
            return undefined;
        }

        const numericalParameter = parameter as NumericalParameter;
        if (numericalParameter.Value == null) {
            return undefined;
        }

        return this.getNumericScopeCheckHtmlBase(numericalParameter.Value, numericalParameter.UnitGroup, numericalParameter.AdditionalPrecision);
    }

    private getNumericScopeCheckHtmlBase(numberValue: number, unitGroup: UnitGroup, additionalPrecision: number) {
        let unit = Unit.None;
        if (unitGroup) {
            unit = this.unitService.getDefaultUnit(unitGroup);
        }

        const maxPrecision = this.unitService.getDefaultPrecision() + 1;  // Same as used in server code (UnitHelper.ConvertUnitTo)!
        const precision = this.unitService.getPrecision(unit) + additionalPrecision ?? 0;

        let displayedUnitValue = '';
        let exactUnitValue = '';

        if (unitGroup) {
            const internalUnit = this.unitService.getInternalUnit(unitGroup);

            numberValue = this.unitService.convertUnitValueArgsToUnit(numberValue, internalUnit, unit);
            displayedUnitValue = this.unitService.formatUnitValueArgs(numberValue, unit, precision);
            exactUnitValue = this.unitService.formatUnitValueArgs(numberValue, unit, maxPrecision);
        }
        else {
            displayedUnitValue = this.unitService.formatNumber(numberValue, precision);
            exactUnitValue = this.unitService.formatNumber(numberValue, maxPrecision);
        }

        if (displayedUnitValue.length >= exactUnitValue.length) {
            return undefined;
        }

        // Displayed with less decimals than internally used!
        displayedUnitValue = this.unitService.appendPrecisionLossSymbolToValueString(numberValue, displayedUnitValue);

        if (unitGroup) {
            displayedUnitValue = this.unitService.appendUnitToValueString(numberValue, displayedUnitValue, unit);
            exactUnitValue = this.unitService.appendUnitToValueString(numberValue, exactUnitValue, unit);
        }

        return `<span class="additional-info" title="${exactUnitValue}">${displayedUnitValue}</span>`;
    }

    public get notificationScopeChecks() {
        if (!this.design.designData.reportData.ScopeCheckResultItems) {
            return [];
        }

        return this.design?.designData.reportData.ScopeCheckResultItems?.map(sc => ({
            type: this.getScopeCheckType(sc),
            message: this.getScopeCheckHtml(sc.Message),
            actionButtons: sc.ActionButtons.map(ab => ({
                condition: () => { return this.isScopeCheckButtonVisible(ab.UIPropertyValueList); },
                disabled: () => { return this.scopeCheckButtonsDisabled(ab); },
                click: () => {
                    return this.scopeCheckButtonClick(ab.UIPropertyValueList);
                },
                buttonTitle: this.getButtonTitle(ab),
                tooltip: this.notificationButtonsTooltip(ab.ButtonTooltip),
                disableTooltip: () => { return !this.localizationService.hasTranslation(ab.ButtonTooltip); }
            })),
            hasSupportButton: () => { return this.isScopeCheckError(sc); },
            supportButtonClick: () => { return this.scopeCheckSupportButtonClick(); }
        } as INotificationScopeCheck));
    }

    public get notificationScopeChecksForAnchorAI() {
        if (!this.design.designData.reportData.ScopeCheckResultItems) {
            return [];
        }

        const scopeChecksToKeep = this.design?.designData.reportData.ScopeCheckResultItems?.filter(sc => smartAnchorDefaultScopeChecks().includes(sc.ScopeCheckId));
        return scopeChecksToKeep?.map(sc => ({
            type: this.getScopeCheckType(sc),
            message: this.getScopeCheckHtml(sc.Message),
        } as INotificationScopeCheck));
    }

    public get isOptimizeAnchorLayoutVisible() {
        return !this.design?.properties.get(PropertyMetaData.Optimize_AnchorLayout.id).hidden;
    }

    public get isOptimizeBaseplateSizeVisible() {
        return !this.design?.properties.get(PropertyMetaData.Optimize_BaseplateSize.id).hidden;
    }

    public get isFindSolutionVisible() {
        return !this.design?.properties.get(PropertyMetaData.Optimize_FindSolution.id).hidden;
    }

    public get isRigidCalculationVisible() {
        return this.design?.IsAnchorPlatePresent && this.design?.designData.reportData.ShowRigidBasePlateDisclaimer;
    }

    public get isOptimizePostDistanceVisible() {
        return !this.design?.properties.get(PropertyMetaData.Optimize_PostDistance.id).hidden;
    }

    public get isAdvancedCalculationVisible() {
        if (this.design?.isCBFEMCalculation || this.design?.isHandrailCBFEMCalculation) {
            const advancedCalculation = this.design.properties.get(PropertyMetaData.AnchorPlate_AdvancedCalculation.id);
            return !advancedCalculation.disabled && !advancedCalculation.hidden;
        }
        else {
            return this.design?.designData.reportData != null && this.design?.designData.reportData.IsLongRunningCalculation &&
                Object.keys(this.design?.designData.reportData.Tension || {}).length == 0 &&
                Object.keys(this.design?.designData.reportData.Shear || {}).length == 0 &&
                Object.keys(this.design?.designData.reportData.Combination || {}).length == 0;
        }
    }

    public get isOptimizeAnchorOffsetVisible() {
        return !this.design?.properties.get(PropertyMetaData.Optimize_AnchorOffset.id).hidden;
    }

    public get isQuantityCalculatorLinkVisible() {
        return !this.offlineService.isOffline && !this.quantityCalculatorLinkDisabled && !this.quantityCalculatorLinkHidden;
    }

    public get quantityCalculatorLinkDisabled() {
        return !this.design?.designData.reportData.CanExportReport;
    }

    public get quantityCalculatorLinkHidden() {
        return this.design?.designType.id != DesignType.Handrail &&
            this.design?.calculationType != AdvancedCalculationType.Rigid && this.design?.calculationType != AdvancedCalculationType.Realistic && this.design?.calculationType != AdvancedCalculationType.BPRigidityCheck;
    }

    public get asadDisabled(): string[] {
        // in debug mode Anchor.AI is never disabled
        if (this.isAsadDebugEnabled) {
            return [];
        }

        const asadNotoficationValues: string[] = [];

        if (this.design.loadType != LoadType.Static) {
            asadNotoficationValues.push('* Load type = static');
        }

        if (this.design.staticLoadTypeId != null && this.design.staticLoadTypeId != StaticLoadType.Design) {
            asadNotoficationValues.push('* Static load type = design');
        }

        if (!this.design.anchorPlateExists) {
            asadNotoficationValues.push('* Anchor plate is present');
        }

        if (this.design.anchorPlateShape != PlateShape.Rectangular) {
            asadNotoficationValues.push('* Anchor plate is rectangular');
        }

        if (this.design.supplementaryReinforcementCategory != null && this.design.supplementaryReinforcementCategory != SupplementaryReinforcementCategory.None) {
            asadNotoficationValues.push('* Supplementary reinforcement is not used');
        }

        if (this.design.calculationType != AdvancedCalculationType.Rigid) {
            asadNotoficationValues.push('* Only for Rigid calculation');
        }

        if ((this.design.model[UIProperty.AnchorLayout_CustomLayoutPoints] as AnchorLayoutPointEntity[])?.some(x => x.IsSlotted)) {
            asadNotoficationValues.push('* Slotted holes are not used');
        }

        if (this.design.filledHolesSOFA) {
            asadNotoficationValues.push('* SOFA is not used');
        }

        if (this.design.designMethodGroup?.id == DesignMethodGroup.ETAG_Based) {
            asadNotoficationValues.push('* ETAG design method is not used');
        }

        if (this.design.designMethodGroup?.id == DesignMethodGroup.ETA_Based &&
            (this.design.anchorFamily == null ||
                !this.design.anchorFamily?.detailed?.allowedInAnchorFilters.includes(AnchorFilterType.EN1992))
        ) {
            asadNotoficationValues.push('* Only for EN1992 Design Basis when using ETA design method');
        }

        if (this.design.designStandard.id != DesignStandard.EC2 && this.design.designStandard.id != DesignStandard.ACI) {
            asadNotoficationValues.push('* Only for European design (EN, ETAG) or ACI design standard');
        }

        if (this.design.showOptimizedAnchorPlateThickness) {
            asadNotoficationValues.push('* Show optimized anchor plate thickness is not used');
        }

        const hasUtilizations = this.design.designData.reportData != null &&
            (this.design.designData.reportData.Tension?.Decisive != null || this.design.designData.reportData.Shear?.Decisive != null || this.design.designData.reportData.Combination?.Decisive != null);
        if (!hasUtilizations) {
            asadNotoficationValues.push('* Only for designs where calculation-breaking scope checks are not failing');
        }

        return asadNotoficationValues;
    }

    public get advancedCalculationDisabled() {
        if (this.design.isCBFEMCalculation || this.design.isHandrailCBFEMCalculation) {
            const advancedCalculation = this.design.properties.get(PropertyMetaData.AnchorPlate_AdvancedCalculation.id);
            return this.design.pendingCalculation || advancedCalculation.disabled;
        }
        else {
            return this.design.pendingCalculation;
        }
    }

    public get handrailCBFEMLearnMoreLink() {
        return this.productInformationService.regionLinksForUser(this.design.region.id)?.HandrailCBFEMLearnMoreLink;
    }

    public get isHandrailCBFEMLearnMoreLinkAvailable() {
        return (this.handrailCBFEMLearnMoreLink != null && this.handrailCBFEMLearnMoreLink != '');
    }

    public get fatigueNotificationVisible() {
        const selectedRegion = this.userSettingsService.settings.application.general.regionId.value;
        return this.design?.isDynamicFatigue &&
            Object.keys(this.design?.designData.reportData.Combination || {}).length > 0 &&
            Object.keys(this.design?.designData.reportData.Shear || {}).length > 0 &&
            Object.keys(this.design?.designData.reportData.Tension || {}).length > 0 &&
            (this.design?.designData.reportData.Combination.Decisive.ValueError || this.design?.designData.reportData.Shear.Decisive.ValueError || this.design?.designData.reportData.Tension.Decisive.ValueError) &&
            (selectedRegion == KnownRegion.Germany || selectedRegion == KnownRegion.Austria || selectedRegion == KnownRegion.Netherlands || selectedRegion == KnownRegion.Switzerland || selectedRegion == KnownRegion.Poland);
    }

    public get enBasedfatigueNotificationTranslation() {
        const selectedRegion = this.userSettingsService.getRegionById(this.userSettingsService.settings.application.general.regionId.value);
        const mail = selectedRegion?.contactUrl?.replace('mailto:', '') ?? '';
        const phone = selectedRegion?.supportPhone ?? '';

        return this.localizationService.getString('Agito.Hilti.Profis3.Notification.EnBasedDynamic.Message', { tags: LocalizationService.PBrB })
            .replace('{phoneNumber}', phone).replace('{email}', mail);
    }

    public get isWHGCoatingWithFilledHoles() {
        return (this.design?.filledHolesETAG || this.design?.filledHolesSOFA)
            && this.design?.isWHGConcreteWithCoating
            && this.design?.anchorFamily?.detailed?.showWHGCoatingMessage;
    }

    public get isChangeDG1toCBFEMVisible() {
        return this.design?.calculationType == AdvancedCalculationType.AISC &&
            DesignPe.getRegionPe(this.design).isDG1ExtensionAllowed;
    }

    public get DG1TranslationMessage() {
        return this.localizationService.getString('Agito.Hilti.Profis3.Notification.DG1ToCBFEMChange.Message', { tags: LocalizationService.A })
            .replace('{dg1ExplanationLink}', environment.dg1ExplanationLink);
    }

    public get notificationButtonsDisabled() {
        return this.design?.pendingCalculation ||
            this.featuresVisibilityInfoService.isDisabled(Feature.Design_ScopeCheckButtons, this.design?.region.id) ||
            this.design?.isReadOnlyDesignMode ||
            this.design?.isHandrailCBFEMCalculation;
    }

    public get isEnCip() {
        if (this.design.anchorFamily == null || this.design.designMethodGroup == null) {
            return false;
        }
        const enCipMethodGroups = [DesignMethodGroup.EN_Based, DesignMethodGroup.ETA_Based, DesignMethodGroup.EN_AnchorDesign, DesignMethodGroup.EN_AnchorDesign_FOS3];

        if (!enCipMethodGroups.includes(this.design.designMethodGroup.id)) {
            return false;
        }

        return this.design?.anchorFamily?.detailed?.allowedInAnchorFilters.includes(AnchorFilterType.CastInPlace);
    }

    public get isHeadJointCompletelyFilledVisible() {
        return !this.design?.properties.get(PropertyMetaData.BaseMaterial_HeadJointCompletelyFilled.id).hidden
            && !(this.design?.model[PropertyMetaData.BaseMaterial_HeadJointCompletelyFilled.id] as boolean)
            && this.scopeChecks.find(sc => sc.ScopeCheckId == ScopeCheck.SC_GEOM_MIN_HEADJOINT_DIST_FOGC) != null;
    }

    public get suggestedAnchorsUnavailable() {
        if (!this.design || !isSmartAnchorAllowed(this.design)) {
            return false;
        }

        if (this.design.smartAnchorCategory == null || this.design.smartAnchorApplication == null) {
            return false;
        }

        return this.design.properties.get(PropertyMetaData.SmartAnchor_SuggestedAnchorFamily.id).disabledValues?.length ?? 0 > 0;
    }

    private get asadText() {
        const asadDisabled = this.asadDisabled;

        if (asadDisabled.length > 0) {
            return 'Anchor.AI\n' + asadDisabled.join('\n');
        }
        else {
            return 'Anchor.AI';
        }
    }

    private get asadUseLambda() {
        return this._asadUseLambda;
    }

    private set asadUseLambda(value) {
        this._asadUseLambda = value;
        this.asadUseLambdaChange();
    }

    private get isTransparentBasematerialSelected() {
        return this.userSettingsService.settings.applicationModelDisplayOptions.transparentBaseMaterial.value;
    }

    public get isConcreteDesign() {
        return this.design?.designType?.id == DesignType.Concrete;
    }

    public openHandrailCBFEMLearnMoreLink() {
        window.open(this.handrailCBFEMLearnMoreLink, '_blank');
    }

    public runAdvancedCalculation() {
        if (this.design.isCBFEMCalculation) {
            this.calculationService.calculateAdvancedBaseplate(this.design);
        }
        else if (this.design.isHandrailCBFEMCalculation) {
            this.calculationService.calculateAdvancedBaseplate(this.design, this.design.selectedLoadCombinationId);
        }
        else {
            this.calculationService.calculateAsync(this.design, undefined, { calculateLongRunning: true, forceCalculation: true });
        }
    }

    public async ngOnInit(): Promise<void> {
        this.tourService.customObservable.subscribe((res) => {
            this.isSmartAnchorVirtualTourEnabled = res;
        }
        );

        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-arrow-left-medium',
            'sprite-arrow-right-medium',
            'sprite-undo',
            'sprite-redo',
            'sprite-search',
            'sprite-search-white',
            'sprite-center',
            'sprite-view',
            'sprite-pointer',
            'sprite-move',
            'sprite-plus-green',
            'sprite-plus-red',
            'sprite-trash',
            'sprite-arrow-left-white',
            'sprite-virtual-tour-arrow-right'
        );

        this.design = this.userService.design;

        this.menuService.initialize();
        this.menu2dService.initialize();

        this.glModelComponentUpdate = this.glModelComponentUpdate.bind(this);
        this.resize3d = this.resize3d.bind(this);
        this.createDesignScreenshot = this.createDesignScreenshot.bind(this);
        this.tabSelected = this.tabSelected.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.onCloseEventTrack = this.onCloseEventTrack.bind(this);
        this.beforeLogout = this.beforeLogout.bind(this);
        this.openDesignSettings = this.openDesignSettings.bind(this);
        this.openSaveAsTemplate = this.openSaveAsTemplate.bind(this);
        this.startTour = this.startTour.bind(this);
        this.openGeneralNotes = this.openGeneralNotes.bind(this);

        this.displayOptionsCheckbox = {
            items: this.createDisplayOptionsCheckboxItems(),
            selectedValues: this.getDisplayOptionsCheckboxSelectedValuesFromUserSettings()
        };

        this.displayOptionsCheckboxConcrete = {
            groupItems: this.createDisplayOptionsCheckboxItemsConcrete(),
            selectedValues: this.getDisplayOptionsCheckboxSelectedValuesFromUserSettings()
        };

        this.mode2dToggleButtonGroup = {
            items: this.createMode2dToggleButtonGroupItems()
        };

        this.sortableMenu3DRightOptions = {
            handle: '.drag-handle-static',
            store: {
                get: () => {
                    return [];
                },
                set: (sortable) => {
                    this.regionOrderService.update(sortable.toArray(), MenuType.Menu3DRight);
                }
            },
            onSort: () => undefined
        };

        this.TrackDataOnTabClose();
        this.checkIntegrationsConnections();

        // show the page and then load controls after GLModel asynchronously
        setTimeoutPromise(() => this.initGLModel())
            .then(() => setTimeoutPromise(() => this.initMenu3d()))
            .then(() => setTimeoutPromise(() => this.initRightSide()))
            .then(() => setTimeoutPromise(() => this.startDesignTour()))
            .catch(err => console.error(err));

        // set functions for virtual tour
        this.tourService.openDisplayOptionsDropdownFn = this.openDisplayOptionsDropdown.bind(this);
        this.tourService.closeDisplayOptionsDropdownFn = this.closeDisplayOptionsDropdown.bind(this);
        this.tourService.openExportReportFn = this.openExportReport.bind(this);
        this.tourService.closeExportReportFn = this.closeExportReport.bind(this);

        this.calculationOutputDropdown = {
            selectedValue: this.getCalculationOutputDropdownSelectedValue,
            items: this.getCalculationOutputDropdownItems()
        };

        this.anchorText = this.localizationService.getString('Agito.Hilti.Profis3.SelectionOptions.Text');
        this.anchorText = format(this.anchorText, '<span>' + this.localizationService.getString('Agito.Hilti.Profis3.SelectionOptions.AnchorNodes') + '</span>');

        this.baseplateText = this.localizationService.getString('Agito.Hilti.Profis3.SelectionOptions.Text');
        this.baseplateText = format(this.baseplateText, '<span>' + this.localizationService.getString('Agito.Hilti.Profis3.SelectionOptions.BaseplateNodes') + '</span>');

        this.draggingSelectionOptions = this.anchorText + ' ' + this.baseplateText;
        this.draggingDisabledPlate = this.localizationService.getString('Agito.Hilti.Profis3.Navigation.TabBasePlate.RegionPlateShape.PlateShape.Disabled.Tooltip');

        // attach events to document
        document.addEventListener('keydown', this.onKeyDown, false);

        this.localizationChangeSubscription = this.localizationService.localizationChange.subscribe(() => {
            const selected = this.selectedMenu.selectedTab;

            this.initMenu3d();

            // init 3d menu always to change translations, etc.
            if (this.view == ViewType.View2d) {
                // save reinitialized 3d menu

                this.initMenu2d();
            }

            this.selectTabById(selected);
        });

        this.controllingDimensionsChangeSubscription = this.designSettingsService.controllingDimensionChanged.subscribe(() => {
            this.glModelComponent.update({
                concreteBaseMaterial: {
                    isProfileCenterControllingDimension: (this.sharedEnvironmentData.data?.useDevFeatures ?? false) ? this.design.measureBaseMaterialEdgeFrom == MeasureBaseMaterialEdgeFromMode.ProfileCenter : undefined
                },
                plate: {
                    isControllingDimension: this.design.measureAnchorPlate == MeasureAnchorPlateMode.UsingOverallWidthAndHeight
                },
                anchor: {
                    isControllingDimension: this.design.measureAnchorPlate == MeasureAnchorPlateMode.FromAnchorCenterToPlateEdge
                }
            });
        });

        this.setNotificationComponentInputs();

        if (this.isAsadDebugEnabled && this.isConcreteDesign) {
            this.setASADDebugNotification();
        }

        //toggle off the smartdesign for a design shared with standard user
        if (this.isStandardUser && this.isAnchorAIEnable) {
            this.design.states = [];
            this.design.model[UIProperty.SmartAnchor_Enabled] = false;
            await this.calculationService.calculateAsync(this.design);
        }
    }

    public ngAfterViewInit(): void {
        if (this.userService.renameFile) {
            this.openDesignSettings();
            this.userService.renameFile = false;
        }

        // CR: what exactly is this?
        if (this.userService.openDesign) {
            setTimeout(() => {
                this.documentService.openDesignExclusivePe(this.userService.design);
                this.userService.openDesign = false;
            }, 1000);
        }

        this.design.setSavedStateIdx();
    }

    public ngOnDestroy(): void {
        this.localizationChangeSubscription?.unsubscribe();
        this.controllingDimensionsChangeSubscription?.unsubscribe();

        if (this.design != undefined) {
            this.design.off(DesignEvent.stateChanged, this.onStateChanged);
            this.design.off(DesignEvent.beforeCalculate, this.onBeforeCalculate);
            this.design.off(DesignEvent.calculate, this.onCalculate);
            this.publish();

            // angular bug: template render is still called multiple times after ngOnDestroy for some reason
            // this causes errors since this.design is already null
            setTimeout(() => {
                this.design = undefined as unknown as DesignPe;
            }, 100);
        }

        // remove document events
        document.removeEventListener('keydown', this.onKeyDown, false);
        window.removeEventListener('beforeunload', this.onCloseEventTrack, false);

        // Fix for the issue that happens when you close the design before the introduction loads
        if (this.openedVirtualTour != null) {
            setTimeout(() => {
                document.querySelectorAll('.introjs-helperLayer, .introjs-tooltipReferenceLayer, .introjs-tooltip, .introjs-overlay, .introjs-disableInteraction')
                    .forEach(element => element.remove());
            });
        }
    }

    public glModelComponentUpdate(model: IModelPe, replace?: boolean) {
        return this.glModelComponent.update(model, replace);
    }

    public getScopeCheckType(scopeCheck: ScopeCheckResultItem) {
        return scopeCheck.IndicatesCalculationError || (scopeCheck.SpecialFlags & ScopeCheckFlags.DisplayAsCalculationError)
            ? NotificationType.alert
            : NotificationType.info;
    }

    public TrackDataOnTabClose() {
        window.addEventListener('beforeunload', this.onCloseEventTrack, false);
    }

    public displayOptionsVisible(displayOptionEditor: DisplayOptionEditor) {
        return displayOptionEditor == DisplayOptionEditor.Both || (this.view == ViewType.View2d && displayOptionEditor == DisplayOptionEditor.Editor2D) || (this.view == ViewType.View3d && displayOptionEditor == DisplayOptionEditor.Editor3D);
    }

    public menuOpened() {
        this.userService.design.usageCounter.MenuOpened++;
    }

    public resize3d(resetHighlightedComponents?: boolean) {
        if (this.glModelComponent != null) {
            if (resetHighlightedComponents) {
                this.glModelComponent.resetHighlightedComponents();
            }

            this.glModelComponent.resizeNextFrame();
        }
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    public async beforeLogout() {
        if (!this.design.isTemplate) {
            this.userLogout = true; // we set this to true so we ignore beforeunload event
            await this.processDesignClose();
        }
    }

    public onPlusClick() {
        this.glModelComponent.cameraZoomIn();
    }

    public onMinusClick() {
        this.glModelComponent.cameraZoomOut();
    }

    public onCalculationOutputValueChange() {
        this.glModelComponent.update({ textureDisplay: this.calculationOutputDropdown.selectedValue });
    }

    public setCursor(cursor: 'move' | '') {
        this.mainContentCenterRightRef.nativeElement.style.cursor = cursor;
    }

    public openClose2d() {
        if (this.view == ViewType.View2d) {
            this.dismiss2dInternal();
        }
        else if (this.view == ViewType.View3d) {
            // set props for the popup if smart anchor design is toggled on
            if (this.isAsadVisible) {
                this.updateMode2dToggleButtonGroupItems();
                this.switchToManualProps = {
                    id: 'SwitchToManual',
                    title: this.localizationService.getString('Agito.Hilti.Profis3.Information'),
                    message: this.localizationService.getString('Agito.Hilti.Profis3.Main.SwitchToManual'),
                    confirmButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Ok'),
                    cancelButtonText: this.localizationService.getString('Agito.Hilti.Profis3.Cancel')
                };
            }
            if (!this.isAsadVisible) {
                this.updateMode2dToggleButtonGroupItems();
                this.switchToManualProps = {} as any;
            }

            this.open2d();
        }
    }

    public openGeneralNotes() {
        // TODO: BUDQBP-23561

        const copyText = this.localizationService.getString('Agito.Hilti.Profis3.GeneralNotes.CopyText');
        const text = this.localizationService.getString('Agito.Hilti.Profis3.GeneralNotes.DisplayText');

        this.modalService.openGeneralNotes(text, copyText);
    }

    public getDesignInfoForDesignType() {
        return this.applicationProviderService.getDesignInfo().find(x => x.designTypeId == this.design.designType.id) as IDesignInfo;
    }

    public openDesignSettings(afterOpenInstructions?: AfterOpenInstruction[]) {
        const designInfo = this.getDesignInfoForDesignType();
        this.modalService.openAddEditDesignFromModule({
            design: {
                id: this.design.id,
                name: this.design.designName,
                projectId: this.design.projectId,
                projectName: this.design.projectName,
                region: this.design.region,
                designType: this.design.designType.id,
                displayDesignType: this.design.isTemplate ? DisplayDesignType.template : DisplayDesignType.design,
                anchorName: this.getAnchorName(),
                approvalNumber: this.design.approvalNumber,
                designTemplateDocumentId: this.design.templateId,
                design: this.design
            },
            addEditType: AddEditType.edit,
            afterOpenInstructions: afterOpenInstructions,
            selectedModuleDesignInfo: designInfo,
            onDesignEdited: (design, project) => {
                this.userService.changeDesign(project as Project, this.design);
                this.userService.mapDisplayDesignToDesign(design as IDetailedDisplayDesign);
                this.userService.projectAndDesignView = project == this.documentService.draftsProject
                    ? ProjectAndDesignView.drafts
                    : ProjectAndDesignView.projects;

                return this.calculationService.calculateAsync(this.design, undefined, { suppressLoadingFlag: true })
                    .then(() => undefined);
            }
        });
    }

    public async openSaveAsTemplate() {
        this.modalService.openSaveAsTemplate({
            designTemplateDocument: this.getDesignTemplateDocument(),
            thumbnailId: this.design.id,
            onTemplateSaved: this.onTemplateSaved.bind(this)
        });
    }

    private getDesignTemplateDocument(): IDesignTemplateDocument {
        return {
            designTypeId: this.design.designData.projectDesign.ProjectDesignType,
            designStandardId: this.design.designData.projectDesign.Options.DesignStandard,
            regionId: this.design.designData.projectDesign.Options.RegionId,
            anchorName: this.getAnchorName(),
            approvalNumber: this.design.approvalNumber,
            projectDesign: JSON.stringify(this.design.designData.projectDesign)
        };
    }

    private getAnchorName() {
        if (!this.design?.anchorType?.name || !this.design?.anchorSize?.name) {
            return undefined;
        }
        return this.design.anchorType.name + ' ' + this.design.anchorSize.name;
    }

    public isSpecificationTextEmpty() {
        return this.userService.design.designData.reportData.SpecificationText == null;
    }

    public get isStandardUser() {
        return this.userService.hasFreeLicense || this.userService.hasfloatingLimitReached;
    }

    public startTour() {
        this.tourService.isAsadEnabled = this.userService.isAsadEnabled && !this.isStandardUser;
        this.modalService.openVirtualTourPopup(this.selectTab.bind(this));
    }

    public startDesignTour() {
        if (this.tourService.isNavigationTourInProgress) {
            this.tourService.newConcreteTour(this.selectTab.bind(this), this.showVirtualTour.bind(this));
            return;
        }

        if (this.userService == null || this.userService.design == null) {
            return;
        }

        // Get all tours that are available for design and not yet seen.
        const availableTours = this.tourService.getVirtualTours()
            .filter(x => x.order != null)
            .filter(x => SafeFunctionInvokerHelper.safeInvoke(x.isAvailable, false))
            .filter(x => !SafeFunctionInvokerHelper.safeInvoke(x.alreadySeen, false))
            .sort((a, b) => ((a.order ?? 0) - (b.order ?? 0)));

        // First tour shall start immediately, others should start after previous one is completed.
        const startNextTour = () => {
            const tour = availableTours.shift();

            if (tour == null) {
                return;
            }

            this.openedVirtualTour = tour.openTour(this.selectTab.bind(this));

            // oncomplete is triggered if Got It button is clicked on last step, onexit is triggered if dismiss button is clicked on any other step
            const exitFn = () => {
                this.openedVirtualTour = undefined;
                tour?.markAsSeen();
                startNextTour();
            };

            this.openedVirtualTour.oncomplete(exitFn.bind(this));
            this.openedVirtualTour.onexit(exitFn.bind(this));
        };

        startNextTour();
    }

    public regionLinkOpened() {
        this.design.usageCounter.OnlineTechnicalInformation++;
    }

    public hiltiDataPrivacyUrlOpened() {
        this.design.usageCounter.OnlineTechnicalInformation++;
    }

    public openApplicationSettings() {
        this.modalService.openApplicationSettings();
    }

    public openUpgradePE() {
        if (this.userSettingsService.getProfis3Url) {
            // TODO: BUDQBP-23561

            const profis3Url = this.regionLanguage.getProfis3Url as string;
            this.offlineService.nativeExternalLinkOpen(profis3Url);
        }
    }

    public zoomPercentageChange(value: number) {
        // The value is changed inside the same cycle so change detection
        // needs to be run again before the new change
        this.modelViewZoom = value;
        this.changeDetector.detectChanges();

        this.glModelComponent.cameraZoom(value);
    }

    public resetCamera() {
        this.glModelComponent.resetCamera();
    }

    public downloadLicenceOffline() {
        // TODO: BUDQBP-23561

        const openUrl = `${environment.baseUrl}Content/Pdfs/PROFIS_licenses.pdf`;
        this.offlineService.nativeLocalPathOpen(openUrl, 'PROFIS_licenses.pdf', true, true);
    }

    public toggleLeftMenu() {
        this.hideLeftMenu = !this.hideLeftMenu;

        this.resize3dAfterUI();
    }

    public toggleRightMenu() {
        this.hideRightMenu = !this.hideRightMenu;

        this.resize3dAfterUI();
    }

    public undo() {
        if (!this.canUndo()) {
            return;
        }

        if (this.view == ViewType.View2d && this.glModelComponent) {
            this.glModelComponent.unselectAllPlatePoints();
        }

        this.design.usageCounter.Undo++;
        this.calculationService.undo(this.design);

        this.setNotificationComponentInputs();
    }

    public redo() {
        if (!this.canRedo()) {
            return;
        }

        if (this.view == ViewType.View2d && this.glModelComponent) {
            this.glModelComponent.unselectAllPlatePoints();
        }

        this.design.usageCounter.Redo++;
        this.calculationService.redo(this.design);

        this.setNotificationComponentInputs();
    }

    public canUndo() {
        return this.design.canUndo && !this.undoRedoDisabled && !this.design.isReadOnlyDesignMode;
    }

    public canRedo() {
        return this.design.canRedo && !this.undoRedoDisabled && !this.design.isReadOnlyDesignMode;
    }

    public showWeldCurvesDropdown() {
        return this.showCalculationOutputDropdown() &&
            (this.userService.design?.baseplateDesignData?.WeldCurves?.length ?? 0) > 0;
    }

    public zoomToFit() {
        const extraInfo = {
            imgHeight: 0,
            imgWidth: 0,
            zoomed: false,
            preview: true,
            loadsVisibilityInfo: {
                designType: this.design.designType.id,
                preview: true,
                modifyLoads: false,
                showInwardsWindLoad: false,
                showLinearInwardsLoad: false,
                showLinearOutwardsLoad: false,
                showOutwardsWindLoad: false,
                showVerticalLoad: false
            }
        };

        return this.glModelComponent.zoomToFit(extraInfo);
    }

    public showCalculationOutputDropdown() {
        return (this.design.calculationType == AdvancedCalculationType.FEM || this.design.calculationType == AdvancedCalculationType.Realistic || this.design.isHandrailCBFEMCalculation) &&
            this.displayOptionsVisible(DisplayOptionEditor.Editor3D);
    }

    public loadsVisible() {
        return this.view == ViewType.View3d && !this.design.properties.get(PropertyMetaData.Loads_LoadCombinations.id).hidden;
    }

    public displayOptionsCheckboxItemToggle(displayOption: DisplayOption) {
        const checked = (this.useNewDisplayOptionsDropdown ?
            this.displayOptionsCheckboxConcrete.selectedValues?.has(displayOption) :
            this.displayOptionsCheckbox.selectedValues?.has(displayOption)) as boolean;

        switch (displayOption) {
            case DisplayOption.BaseplateDimensions: {
                this.updateBaseplateDimensionsDisplayOption(checked);
                break;
            }
            case DisplayOption.ConcreteDimensions: {
                this.updateConcreteDimensionsDisplayOption(checked);
                break;
            }
            case DisplayOption.AnchorCenterToConcreteEdge: {
                this.updateAnchorCenterToConcreteEdgeDisplayOption(checked);
                break;
            }
            case DisplayOption.HandrailDimensions: {
                this.updateHandrailDimensionsDisplayOption(checked);
                break;
            }
            case DisplayOption.ProfileMeasurments: {
                this.glModelComponent.update({
                    profile: {
                        profileMeasurmentsVisible: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.profileMeasurments.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.AnchorLayoutMeasurments: {
                this.glModelComponent.update({
                    anchor: {
                        anchorLayoutMeasurmentsVisible: checked,
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.anchorSpacing.value = checked;
                this.userSettingsService.settings.applicationModelDisplayOptions.anchorLayoutMeasurments.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.Loads: {
                this.updateLoadsDisplayOption(checked);
                break;
            }
            case DisplayOption.LoadsHeight: {
                const model = this.glModelComponent?.getModel();

                if (model?.handrailBaseMaterial != null) {
                    this.glModelComponent.update({ handrailBaseMaterial: { loadHeightDimensionsVisible: checked } });
                }

                this.userSettingsService.settings.applicationModelDisplayOptions.loadsHeight.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.AnchorNumbering: {
                this.glModelComponent.update({
                    anchor: {
                        anchorNumberingVisible: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.anchorNumbering.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.PlateNumbering: {
                this.glModelComponent.update({
                    plate: {
                        plateNumberingVisible: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.plateNumbering.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.Rotation: {
                this.glModelComponent.update({
                    anchor: {
                        rotationVisible: checked
                    },
                    plate: {
                        rotationVisible: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.rotation.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.AnchorSpacing: {
                this.glModelComponent.update({
                    anchor: {
                        anchorLayoutMeasurmentsVisible2D: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.anchorLayoutMeasurments2d.value = checked;
                this.userSettingsService.settings.applicationModelDisplayOptions.anchorSpacing2d.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ProfileCenterToConcreteEdge: {
                this.glModelComponent.update({
                    profile: {
                        profileCenterToConcreteEdgeVisible: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.profileCenterToConcreteEdge.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ProfileEdgeToConcreteEdge: {
                this.glModelComponent.update({
                    profile: {
                        profileEdgeToConcreteEdgeVisible: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.profileEdgeToConcreteEdge.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ProfileEdgeToBaseplateEdge: {
                this.glModelComponent.update({
                    profile: {
                        profileEdgeToBaseplateEdgeVisible: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.profileEdgeToBaseplateEdge.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ProfileCenterToBaseplateCenter: {
                this.glModelComponent.update({
                    profile: {
                        profileCenterToBaseplateCenterVisible: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.profileCenterToBaseplateCenter.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.TransparentBaseMaterial: {
                this.updateTransparentBaseMaterialDisplayOption(checked);
                break;
            }
            case DisplayOption.Mesh: {
                this.glModelComponent.update({
                    meshVisible: checked
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.mesh.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.TransparentProfile: {
                this.glModelComponent.update({
                    profile: {
                        transparentProfile: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.transparentProfile.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.Stiffener2D: {
                this.glModelComponent.update({
                    stiffener: {
                        stiffener2dVisible: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.stiffener2d.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.SupplementaryReinforcementDimensions: {
                this.glModelComponent.update({
                    supplementaryReinforcement: {
                        dimensionsVisible: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.supplementaryReinforcementDimensions.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ToolTips: {
                this.glModelComponent.update({
                    tooltips: checked
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.toolTips.value = checked;
                this.userSettingsService.save();

                break;
            }
            case DisplayOption.ShearLugMeasurements: {
                this.glModelComponent.update({
                    shearLug: {
                        shearLugMeasurementsVisible: checked
                    }
                });

                this.userSettingsService.settings.applicationModelDisplayOptions.shearLugMeasurements.value = checked;
                this.userSettingsService.save();

                break;
            }
            default:
                throw new Error('unknown DisplayOption');
        }

        if (this.useNewDisplayOptionsDropdown) {
            this.changeDetector.detectChanges();
        }
    }

    public updateBaseplateDimensionsDisplayOption(checked: boolean) {
        this.glModelComponent.update({
            plate: {
                dimensionsVisible: checked
            }
        });

        this.userSettingsService.settings.applicationModelDisplayOptions.baseplateDimensions.value = checked;
        this.userSettingsService.save();
    }

    public updateConcreteDimensionsDisplayOption(checked: boolean) {
        const model = this.glModelComponent.getModel();

        const update: IModelPe = {};

        if (model.concreteBaseMaterial != null) {
            if (this.useNewDisplayOptionsDropdown) {
                update.concreteBaseMaterial = { dimensionThicknessVisible: checked };
            }
            else {
                update.concreteBaseMaterial = {
                    dimensionsVisible: checked,
                    dimensionThicknessVisible: checked
                };
            }
        }
        else if (model.masonryBaseMaterial != null) {
            update.masonryBaseMaterial = { dimensionsVisible: checked };
        }
        else if (model.handrailBaseMaterial != null) {
            update.handrailBaseMaterial = { dimensionsVisible: checked };
        }
        else if (model.metalDeckBaseMaterial != null) {
            update.metalDeckBaseMaterial = { dimensionsVisible: checked };
        }

        this.glModelComponent.update(update);

        if (!this.useNewDisplayOptionsDropdown) {
            this.userSettingsService.settings.applicationModelDisplayOptions.baseMaterialSpacing.value = checked;
        }
        this.userSettingsService.settings.applicationModelDisplayOptions.concreteDimensions.value = checked;
        this.userSettingsService.save();
    }

    public updateAnchorCenterToConcreteEdgeDisplayOption(checked: boolean) {
        const model = this.glModelComponent.getModel();

        const update: IModelPe = {};

        if (model.concreteBaseMaterial != null) {
            update.concreteBaseMaterial = { dimensionsVisible: checked };
        }
        else if (model.masonryBaseMaterial != null) {
            update.masonryBaseMaterial = { dimensionsVisible: checked };
        }
        else if (model.handrailBaseMaterial != null) {
            update.handrailBaseMaterial = { dimensionsVisible: checked };
        }
        else if (model.metalDeckBaseMaterial != null) {
            update.metalDeckBaseMaterial = { dimensionsVisible: checked };
        }

        this.glModelComponent.update(update);

        this.userSettingsService.settings.applicationModelDisplayOptions.anchorCenterToConcreteEdge.value = checked;
        this.userSettingsService.save();
    }

    public updateHandrailDimensionsDisplayOption(checked: boolean) {
        const model = this.glModelComponent.getModel();

        if (model.handrailBaseMaterial != null) {
            this.glModelComponent.update({ handrailBaseMaterial: { handrailDimensionsVisible: checked } });
        }

        this.userSettingsService.settings.applicationModelDisplayOptions.handrailDimensions.value = checked;
        this.userSettingsService.save();
    }

    public updateLoadsDisplayOption(checked: boolean) {
        const model = this.glModelComponent?.getModel();

        const update: IModelPe = {
            force: {
                visible: checked
            },
            moment: {
                visible: checked
            }
        };

        if (model?.masonryBaseMaterial != null) {
            update.masonryBaseMaterial = { stressArrowsVisible: checked };
        }

        if (model?.handrail != null) {
            update.handrail = { loadsVisible: checked };
        }

        this.glModelComponent.update(update);

        this.userSettingsService.settings.applicationModelDisplayOptions.loads.value = checked;
        this.userSettingsService.save();
    }

    public updateTransparentBaseMaterialDisplayOption(checked: boolean) {
        const model = this.glModelComponent.getModel();

        const update: IModelPe = {};

        if (model.concreteBaseMaterial != null) {
            update.concreteBaseMaterial = { transparent: checked };
        }
        else if (model.masonryBaseMaterial != null) {
            update.masonryBaseMaterial = { transparent: checked };
        }
        else if (model.handrailBaseMaterial != null) {
            update.handrailBaseMaterial = { transparent: checked };
        }
        else if (model.metalDeckBaseMaterial != null) {
            update.metalDeckBaseMaterial = { transparent: checked };
        }

        this.glModelComponent.update(update);

        this.userSettingsService.settings.applicationModelDisplayOptions.transparentBaseMaterial.value = checked;
        this.userSettingsService.save();
    }

    public sortMenu3DRight(sortable: Sortable) {
        sortable.sort(this.favoritesService.menu3DRightOrder.map(String));
    }

    public floatingInfoChange(floatingInfo: FloatingInfo, collapsed: boolean) {
        if (collapsed && this.floatingInfo == floatingInfo) {
            // everything is collapsed
            this.floatingInfo = undefined;
        }
        else if (!collapsed) {
            // open this specific floating info
            this.floatingInfo = floatingInfo;
        }
    }

    public get is2DModeDisabled() {
        return !this.isAnchorAISolutionSelected && this.isAsadVisible;
    }

    public updateMode(mode: Mode2d) {

        if (this.isAsadVisible && (mode != Mode2dPe.Pan)) {
            this.design.model[UIProperty.SmartAnchor_Enabled] = false;
            this.calculationService.calculateAsync(this.design).then(() => {
                this.switchToManualProps = null as any;
                this.mode2d = mode;
                this.initMenu2d();
            });
        }
        else {
            this.mode2d = mode;
        }
    }

    private getCalculationOutputDropdownItems(): DropdownItem<TextureDisplay>[] {
        switch (this.design.designType.id) {
            case DesignType.Concrete:
            case DesignType.MetalDeck:
                return [
                    { value: TextureDisplay.Solid3D, text: this.concateLoadCaseId('Agito.Hilti.Profis3.Main.Solid3D', null) },
                    { value: TextureDisplay.EquivalentStress, text: this.concateLoadCaseId('Agito.Hilti.Profis3.Main.EquivalentStress', this.design.baseplateDesignData?.DecisiveStressMeshLoadCombinationId) },
                    { value: TextureDisplay.PlasticStrain, text: this.concateLoadCaseId('Agito.Hilti.Profis3.Main.PlasticStrain', this.design.baseplateDesignData?.DecisiveStrainMeshLoadCombinationId) },
                    { value: TextureDisplay.StressInConcrete, text: this.concateLoadCaseId('Agito.Hilti.Profis3.Main.StressInConcrete', this.design.baseplateDesignData?.DecisiveConcreteStressMeshLoadCombinationId) },
                    { value: TextureDisplay.Deformation, text: this.concateLoadCaseId('Agito.Hilti.Profis3.Main.Deformation', this.design.baseplateDesignData?.DecisiveDisplacementMeshLoadCombinationId) }
                ];
            case DesignType.Handrail:
                return [
                    { value: TextureDisplay.Solid3D, text: this.concateLoadCaseId('Agito.Hilti.Profis3.Main.Solid3D', null) },
                    { value: TextureDisplay.EquivalentStress, text: this.concateLoadCaseId('Agito.Hilti.Profis3.Main.EquivalentStress', this.design.baseplateDesignData?.DecisiveStressMeshLoadCombinationId) },
                    { value: TextureDisplay.PlasticStrain, text: this.concateLoadCaseId('Agito.Hilti.Profis3.Main.PlasticStrain', this.design.baseplateDesignData?.DecisiveStrainMeshLoadCombinationId) },
                ];
            case DesignType.Masonry:
                return [];
            default:
                throw new Error('Invalid Design type');
        }
    }

    private checkIntegrationsConnections() {
        if (this.offlineService.isOffline ||
            this.design.designType.id != DesignType.Concrete ||
            !this.featuresVisibilityInfoService.areIntegrationsEnabled) {
            return;
        }

        if (!this.userSettingsService.settings.application.general.dlubalEnabled &&
            !this.userSettingsService.settings.application.general.sap2000Enabled.value &&
            !this.userSettingsService.settings.application.general.robotEnabled.value &&
            !this.userSettingsService.settings.application.general.etabsEnabled.value &&
            !this.userSettingsService.settings.application.general.staadProEnabled.value) {
            return;
        }

        if (this.integrationsNotificationService.connected) {
            // if we aren't connected to integrations notifications, try to connect (or reconnect).
            this.integrationsNotificationService
                .connect()
                .then((notificationState) => {
                    if (!notificationState) {
                        return;
                    }

                    this.integrationsDataService.registerNewDataAvailableHandler();
                    this.integrationsDocumentService.registerNewDocumentNotificationHandler();

                    this.refreshIntegrationsAvailability();
                });
        }
        else {
            if (this.sharedEnvironmentData.data?.integrationServicesServerEnabled) {
                this.refreshIntegrationsAvailability();
            }
        }
    }

    private refreshIntegrationsAvailability(): void {
        // dlubal connection
        if (this.userSettingsService.settings.application.general.dlubalEnabled.value) {
            this.integrationsDataService.refreshIntegrationAvailability(DataIntegrationType.Dlubal);
        }

        // sap2000 connection
        if (this.userSettingsService.settings.application.general.sap2000Enabled.value) {
            this.integrationsDataService.refreshIntegrationAvailability(DataIntegrationType.SAP2000);
        }

        // robot connection
        if (this.userSettingsService.settings.application.general.robotEnabled.value) {
            this.integrationsDataService.refreshIntegrationAvailability(DataIntegrationType.Robot);
        }

        // ETABS connection
        if (this.userSettingsService.settings.application.general.etabsEnabled.value) {
            this.integrationsDataService.refreshIntegrationAvailability(DataIntegrationType.ETABS);
        }

        // StaadPro connection
        if (this.userSettingsService.settings.application.general.staadProEnabled.value) {
            this.integrationsDataService.refreshIntegrationAvailability(DataIntegrationType.StaadPro);
        }
    }

    private onCloseEventTrack(e: BeforeUnloadEvent) {

        // Ask for unsaved changes before close
        if (this.offlineService.isOffline && this.design.isStateChanged) {
            e.preventDefault();
            e.returnValue = '';
            this.openConfirmChangeForOffline();
        }
        else if (!this.userLogout) {
            this.processDesignBrowserUnload();
        }
    }

    private createDisplayOptionsCheckboxItemsConcrete(): CheckboxButtonGroupItem<DisplayOption>[] {
        const groupItems: CheckboxButtonGroupItem<DisplayOption>[] = [];
        const dimensionItems = this.getDimensionsItemsConcrete();
        const transparencyItems = this.getTransparencyItemsConcrete();
        const labelItems = this.getLabelItemsConcrete();
        const tooltipItems = this.getTooltipItemsConcrete();

        this.fillGroupItemsList(dimensionItems, 'DimensionsTitle', groupItems);
        this.fillGroupItemsList(transparencyItems, 'TransparencyTitle', groupItems);
        this.fillGroupItemsList(labelItems, 'LabelsTitle', groupItems);
        this.fillGroupItemsList(tooltipItems, 'TooltipsTitle', groupItems);

        return groupItems;
    }

    private getTooltipItemsConcrete(): CheckboxButtonItem<DisplayOption>[] {
        const tooltipItems: CheckboxButtonItem<DisplayOption>[] = [];

        // Tooltips
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.isAnchorAIEnable) {
            tooltipItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ToolTips],
                value: DisplayOption.ToolTips,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ToolTips'),
                disabled: false
            });
        }

        return tooltipItems;
    }

    private getLabelItemsConcrete(): CheckboxButtonItem<DisplayOption>[] {
        const labelItems: CheckboxButtonItem<DisplayOption>[] = [];

        // Anchor numbering
        if (this.displayOptionsVisible(DisplayOptionEditor.Both) && !this.isAnchorAIEnable) {
            labelItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.AnchorNumbering],
                value: DisplayOption.AnchorNumbering,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.AnchorNumbering'),
                disabled: false
            });
        }

        // Loads
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.isAnchorAIEnable) {
            labelItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.Loads],
                value: DisplayOption.Loads,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.Loads'),
                disabled: false
            });
        }

        // Anchor plate numbering
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor2D)) {
            labelItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.PlateNumbering],
                value: DisplayOption.PlateNumbering,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.PlateNumbering'),
                disabled: !this.design.anchorPlateExists
            });
        }

        return labelItems;
    }

    private getTransparencyItemsConcrete(): CheckboxButtonItem<DisplayOption>[] {
        const transparencyItems: CheckboxButtonItem<DisplayOption>[] = [];

        // Transparent base material
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D)) {
            transparencyItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.TransparentBaseMaterial],
                value: DisplayOption.TransparentBaseMaterial,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.TransparentBaseMaterial'),
                disabled: false
            });
        }

        // Transparent profile
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D)) {
            transparencyItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.TransparentProfile],
                value: DisplayOption.TransparentProfile,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.TransparentProfile'),
                disabled: !this.design.profileExists
            });
        }

        return transparencyItems;
    }

    private getDimensionsItemsConcrete(): CheckboxButtonItem<DisplayOption>[] {
        const dimensionItems: CheckboxButtonItem<DisplayOption>[] = [];

        // Anchor center to concrete edge
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D)) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.AnchorCenterToConcreteEdge],
                value: DisplayOption.AnchorCenterToConcreteEdge,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.AnchorCenterToConcreteEdge'),
                disabled: false
            });
        }

        // Anchor layout
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.isAnchorAIEnable) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.AnchorLayoutMeasurments],
                value: DisplayOption.AnchorLayoutMeasurments,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.AnchorLayoutMeasurments'),
                disabled: false
            });
        }

        // Baseplate size
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.isAnchorAIEnable) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.BaseplateDimensions],
                value: DisplayOption.BaseplateDimensions,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.BaseplateDimensions'),
                disabled: !this.design.anchorPlateExists
            });
        }

        // Base material size
        if (this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ConcreteDimensions],
                value: DisplayOption.ConcreteDimensions,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.BaseMaterialSpacing'),
                disabled: false
            });
        }

        // Profile center to baseplate edge
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.isAnchorAIEnable) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ProfileMeasurments],
                value: DisplayOption.ProfileMeasurments,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ProfileMeasurments'),
                disabled: !this.design.anchorPlateExists || this.design.hasIntegratedProfile || !this.design.profileExists
            });
        }

        // Profile edge to concrete edge
        if (this.design.designType.id == DesignType.Concrete && this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ProfileEdgeToConcreteEdge],
                value: DisplayOption.ProfileEdgeToConcreteEdge,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ProfileEdgeToConcreteEdge'),
                disabled: !this.design.anchorPlateExists || !this.design.profileExists
            });
        }

        // Profile center to concrete edge
        if (this.design.designType.id == DesignType.Concrete && this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ProfileCenterToConcreteEdge],
                value: DisplayOption.ProfileCenterToConcreteEdge,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ProfileCenterToConcreteEdge'),
                disabled: !this.design.anchorPlateExists || !this.design.profileExists
            });
        }

        // Profile edge to baseplate edge
        if (this.design.designType.id == DesignType.Concrete && this.displayOptionsVisible(DisplayOptionEditor.Both) && !this.isAnchorAIEnable) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ProfileEdgeToBaseplateEdge],
                value: DisplayOption.ProfileEdgeToBaseplateEdge,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ProfileEdgeToBaseplateEdge'),
                disabled: !this.design.anchorPlateExists || !this.design.profileExists
            });
        }

        // Profile eccentricity
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.isAnchorAIEnable) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ProfileCenterToBaseplateCenter],
                value: DisplayOption.ProfileCenterToBaseplateCenter,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ProfileCenterToBaseplateCenter'),
                disabled: !this.design.anchorPlateExists || this.design.hasIntegratedProfile || !this.design.profileExists
            });
        }

        // CBFEM mesh
        if (this.showMeshOption) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.Mesh],
                value: DisplayOption.Mesh,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.Mesh'),
                disabled: false
            });
        }

        // Rotation
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor2D)) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.Rotation],
                value: DisplayOption.Rotation,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.Rotation'),
                disabled: false
            });
        }

        // Anchor Spacing
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor2D)) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.AnchorSpacing],
                value: DisplayOption.AnchorSpacing,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.AnchorSpacing'),
                disabled: false
            });
        }

        // Stiffeners
        if (this.displayOptionsVisible(DisplayOptionEditor.Editor2D)) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.Stiffener2D],
                value: DisplayOption.Stiffener2D,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.Stiffener2D'),
                disabled: !this.design.profileExists || !this.design.isStiffenerPresent
            });
        }

        if (this.showSupplementaryReinforcementDimensions && !this.isAnchorAIEnable) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.SupplementaryReinforcementDimensions],
                value: DisplayOption.SupplementaryReinforcementDimensions,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.SupplementaryReinforcementDimensions'),
                disabled: this.design.supplementaryReinforcementCategory == null || this.design.supplementaryReinforcementCategory == SupplementaryReinforcementCategory.None
            });
        }

        // Shear Lug Measurements
        if (this.showShearLugMeasurements) {
            dimensionItems.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ShearLugMeasurements],
                value: DisplayOption.ShearLugMeasurements,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ShearLugMeasurements'),
                disabled: !this.design.isShearLugPresent || this.design.calculationType != AdvancedCalculationType.Rigid || this.design.designMethodGroup?.id != DesignMethodGroup.ACI31819
            });
        }

        return dimensionItems;
    }

    private fillGroupItemsList(items: CheckboxButtonItem<DisplayOption>[], groupName: string, groupItems: CheckboxButtonGroupItem<DisplayOption>[]) {
        if (items.length > 0) {
            const translationKeyPreffix = 'Agito.Hilti.Profis3.Main.DisplayOptions.';

            groupItems.push({
                title: this.localizationService.getString(translationKeyPreffix.concat(groupName)),
                items
            });
        }
    }

    private createDisplayOptionsCheckboxItems(): CheckboxButtonItem<DisplayOption>[] {
        const items: CheckboxButtonItem<DisplayOption>[] = [];

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.isAnchorAIEnable) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.BaseplateDimensions],
                value: DisplayOption.BaseplateDimensions,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.BaseplateDimensions'),
                disabled: !this.design.anchorPlateExists
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Both) && !this.isAnchorAIEnable) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ConcreteDimensions],
                value: DisplayOption.ConcreteDimensions,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.BaseMaterialSpacing'),
                disabled: false
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && this.design.designType.id == DesignType.Handrail) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.HandrailDimensions],
                value: DisplayOption.HandrailDimensions,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.HandrailDimensions'),
                disabled: false
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.isAnchorAIEnable) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ProfileMeasurments],
                value: DisplayOption.ProfileMeasurments,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ProfileMeasurments'),
                disabled: !this.design.anchorPlateExists || this.design.hasIntegratedProfile
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.isAnchorAIEnable) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.AnchorLayoutMeasurments],
                value: DisplayOption.AnchorLayoutMeasurments,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.AnchorLayoutMeasurments'),
                disabled: false
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.isAnchorAIEnable) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.Loads],
                value: DisplayOption.Loads,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.Loads'),
                disabled: false
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && this.design.designType.id == DesignType.Handrail) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.LoadsHeight],
                value: DisplayOption.LoadsHeight,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.LoadsHeight'),
                disabled: false
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Both) && !this.isAnchorAIEnable) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.AnchorNumbering],
                value: DisplayOption.AnchorNumbering,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.AnchorNumbering'),
                disabled: false
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor2D)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.PlateNumbering],
                value: DisplayOption.PlateNumbering,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.PlateNumbering'),
                disabled: !this.design.anchorPlateExists
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor2D)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.Rotation],
                value: DisplayOption.Rotation,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.Rotation'),
                disabled: false
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor2D)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.AnchorSpacing],
                value: DisplayOption.AnchorSpacing,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.AnchorSpacing'),
                disabled: false
            });
        }

        if (this.design.designType.id == DesignType.Concrete && this.displayOptionsVisible(DisplayOptionEditor.Both)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ProfileCenterToConcreteEdge],
                value: DisplayOption.ProfileCenterToConcreteEdge,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ProfileCenterToConcreteEdge'),
                disabled: !this.design.anchorPlateExists || !this.design.profileExists
            });
        }

        if (this.design.designType.id == DesignType.Concrete && this.displayOptionsVisible(DisplayOptionEditor.Both) && !this.isAnchorAIEnable) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ProfileEdgeToConcreteEdge],
                value: DisplayOption.ProfileEdgeToConcreteEdge,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ProfileEdgeToConcreteEdge'),
                disabled: !this.design.anchorPlateExists || !this.design.profileExists
            });
        }

        if (this.design.designType.id == DesignType.Concrete && this.displayOptionsVisible(DisplayOptionEditor.Both) && !this.isAnchorAIEnable) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ProfileEdgeToBaseplateEdge],
                value: DisplayOption.ProfileEdgeToBaseplateEdge,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ProfileEdgeToBaseplateEdge'),
                disabled: !this.design.anchorPlateExists || !this.design.profileExists
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D) && !this.isAnchorAIEnable) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.ProfileCenterToBaseplateCenter],
                value: DisplayOption.ProfileCenterToBaseplateCenter,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.ProfileCenterToBaseplateCenter'),
                disabled: !this.design.anchorPlateExists || this.design.hasIntegratedProfile
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.TransparentBaseMaterial],
                value: DisplayOption.TransparentBaseMaterial,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.TransparentBaseMaterial'),
                disabled: false
            });
        }

        if (this.showMeshOption) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.Mesh],
                value: DisplayOption.Mesh,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.Mesh'),
                disabled: false
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor3D)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.TransparentProfile],
                value: DisplayOption.TransparentProfile,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.TransparentProfile'),
                disabled: !this.design.profileExists
            });
        }

        if (this.displayOptionsVisible(DisplayOptionEditor.Editor2D)) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.Stiffener2D],
                value: DisplayOption.Stiffener2D,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.Stiffener2D'),
                disabled: !this.design.profileExists || !this.design.isStiffenerPresent
            });
        }

        if (this.showSupplementaryReinforcementDimensions && !this.isAnchorAIEnable) {
            items.push({
                id: 'DisplayOption-' + DisplayOption[DisplayOption.SupplementaryReinforcementDimensions],
                value: DisplayOption.SupplementaryReinforcementDimensions,
                text: this.localizationService.getString('Agito.Hilti.Profis3.Main.DisplayOptions.SupplementaryReinforcementDimensions'),
                disabled: this.design.supplementaryReinforcementCategory == null || this.design.supplementaryReinforcementCategory == SupplementaryReinforcementCategory.None
            });
        }

        return items;
    }

    private getDisplayOptionsCheckboxSelectedValuesFromUserSettings(): Set<DisplayOption> {
        const displayOptions = this.userSettingsService.settings.applicationModelDisplayOptions;

        const values = new Set([
            displayOptions.baseplateDimensions.value ? DisplayOption.BaseplateDimensions : undefined,
            displayOptions.concreteDimensions.value ? DisplayOption.ConcreteDimensions : undefined,
            displayOptions.handrailDimensions.value ? DisplayOption.HandrailDimensions : undefined,
            displayOptions.profileMeasurments.value ? DisplayOption.ProfileMeasurments : undefined,
            displayOptions.anchorLayoutMeasurments.value ? DisplayOption.AnchorLayoutMeasurments : undefined,
            displayOptions.anchorCenterToConcreteEdge.value ? DisplayOption.AnchorCenterToConcreteEdge : undefined,
            displayOptions.loads.value ? DisplayOption.Loads : undefined,
            displayOptions.loadsHeight.value ? DisplayOption.LoadsHeight : undefined,
            displayOptions.anchorNumbering.value ? DisplayOption.AnchorNumbering : undefined,
            displayOptions.plateNumbering.value ? DisplayOption.PlateNumbering : undefined,
            displayOptions.rotation.value ? DisplayOption.Rotation : undefined,
            displayOptions.anchorSpacing2d.value ? DisplayOption.AnchorSpacing : undefined,
            displayOptions.profileCenterToConcreteEdge.value ? DisplayOption.ProfileCenterToConcreteEdge : undefined,
            displayOptions.profileEdgeToConcreteEdge.value ? DisplayOption.ProfileEdgeToConcreteEdge : undefined,
            displayOptions.profileEdgeToBaseplateEdge.value ? DisplayOption.ProfileEdgeToBaseplateEdge : undefined,
            displayOptions.profileCenterToBaseplateCenter.value ? DisplayOption.ProfileCenterToBaseplateCenter : undefined,
            displayOptions.transparentBaseMaterial.value ? DisplayOption.TransparentBaseMaterial : undefined,
            displayOptions.mesh.value ? DisplayOption.Mesh : undefined,
            displayOptions.transparentProfile.value ? DisplayOption.TransparentProfile : undefined,
            displayOptions.stiffener2d.value ? DisplayOption.Stiffener2D : undefined,
            displayOptions.supplementaryReinforcementDimensions.value ? DisplayOption.SupplementaryReinforcementDimensions : undefined,
            displayOptions.toolTips.value ? DisplayOption.ToolTips : undefined,
            displayOptions.shearLugMeasurements.value ? DisplayOption.ShearLugMeasurements : undefined
        ]);
        values.delete(undefined);

        return values as Set<DisplayOption>;
    }

    private createMode2dToggleButtonGroupItems(): ToggleButtonGroupItem<Mode2dPe>[] {
        const items: ToggleButtonGroupItem<Mode2dPe>[] = [];

        items.push({
            id: 'main-2D-editor-pointer-button',
            value: Mode2d.Pointer,
            tooltip: this.translate('Agito.Hilti.Profis3.Main.Editor2d.Select'),
            image: getSpriteAsIconStyle('sprite-pointer')
        });

        items.push({
            id: 'main-2D-editor-pan-button',
            value: Mode2d.Pan,
            tooltip: this.translate('Agito.Hilti.Profis3.Main.Editor2d.Pan2d'),
            image: getSpriteAsIconStyle('sprite-move')
        });

        if (this.isPlateEditable) {
            items.push({
                id: 'main-2D-editor-plate-point-button',
                value: Mode2dPe.AddPlatePoint,
                tooltip: this.translate('Agito.Hilti.Profis3.Main.Editor2d.AddNode'),
                image: getSpriteAsIconStyle('sprite-plus-green'),
                confirmClick: this.isAsadVisible
            });
        }

        if (this.areAnchorsEditable) {
            items.push({
                id: 'main-2D-editor-anchor-point-button',
                value: Mode2dPe.AddAnchorPoint,
                tooltip: this.translate('Agito.Hilti.Profis3.Main.Editor2d.AddAnchor'),
                image: getSpriteAsIconStyle('sprite-plus-red'),
                confirmClick: this.isAsadVisible
            });
        }

        if (this.isPlateEditable || this.areAnchorsEditable) {
            items.push({
                id: 'main-2D-editor-remove-button',
                value: Mode2d.Delete,
                tooltip: this.translate('Agito.Hilti.Profis3.Main.Editor2d.Remove'),
                image: getSpriteAsIconStyle('sprite-trash'),
                confirmClick: this.isAsadVisible
            });
        }

        return items;
    }

    private updateMode2dToggleButtonGroupItems() {
        this.mode2dToggleButtonGroup.items?.forEach(item => {
            if (item.id != 'main-2D-editor-pan-button') {
                item.confirmClick = this.isAsadVisible;
            }
        });
    }

    private openApproval(navigationControl: UIPropertyBaseControl) {
        if (navigationControl.UIPropertyId == null) {
            return;
        }

        const urls = this.design.model[navigationControl.UIPropertyId] as string[];
        let availableLanguages = this.design.model[PropertyMetaData.AnchorLayout_ApprovalLanguages.id] as string[][];

        // Whether the shown approvals are meant for STO design standard.
        const isSTOApproval = navigationControl.UIPropertyId == PropertyMetaData.AnchorLayout_ViewApproval_STO.id;
        if (isSTOApproval) {
            availableLanguages = (this.design.model[PropertyMetaData.AnchorLayout_ApprovalLanguages_STO.id] as string[][]);
        }

        // Whether the shown approvals are meant for UKTA.
        const isUKTAApproval = navigationControl.UIPropertyId == PropertyMetaData.AnchorLayout_ViewApproval_UKTA.id;
        if (isUKTAApproval) {
            availableLanguages = (this.design.model[PropertyMetaData.AnchorLayout_ApprovalLanguages_UKTA.id] as string[][]);
        }

        // Open popup with multiple approvals showing allowing user to open each separately
        // and preventing browser to block second tab-opening as popup
        if (urls.length > 1) {
            this.modalService.openMultipleApprovals(urls, availableLanguages);
        }
        // Open tab otherwise
        else if (urls[0] != null && urls[0] != '') {
            const approvalLang = ApprovalHelper.getApprovalLanguage(availableLanguages[0], this.language.culture);
            const approvalInfo = ApprovalHelper.getApprovalInfo(approvalLang, urls[0]);
            this.offlineService.nativeLocalPathOpen(approvalInfo.url, approvalInfo.name, true, true);
        }
    }

    private openFullscreenLoader(navigationControl: UIPropertyBaseControl) {
        if (navigationControl.UIPropertyId == null) {
            return;
        }

        this.design.addModelChangeNoCalculation(navigationControl.UIPropertyId, true);

        this.modalService.loadingCustomOpen();
        this.calculationService.calculateAsync(this.design, undefined, { suppressLoadingFlag: true })
            .finally(() => {
                this.modalService.loadingCustomClose();
            });
    }

    private onTemplateSaved() {
        this.userService.projectAndDesignView = ProjectAndDesignView.templates;

        this.routingService.navigateToUrl(UrlPath.projectAndDesign);
    }

    private selectTab(tab: string) {
        this.selectTabById(`tab-${tab}`);

        this.hideLeftMenu = false;
        this.resize3dAfterUI();
    }

    private selectTabById(tab: string) {
        this.mainMenuComponent?.selectTab(tab);
    }

    private setDraggingTooltipVisible(visible: boolean) {
        this.showDraggingTooltip = visible && this.isPlateEditable;
    }

    private setDraggingTooltipPlateDisabled(visible: boolean) {
        if (this.design.hasETAG()) {
            this.showDraggingTooltipPlateDisabled = visible && !this.isPlateEditable;
        }
    }

    private save2dState(saveProperties: { [uiPropertyId: number]: boolean }, skipSaveModelToProp = false) {
        if (!skipSaveModelToProp) {
            this.save2dModelToProperties(saveProperties);
        }

        this.calculationService.calculateAsync(this.design, undefined, undefined);
    }

    private open2d() {
        if (this.view != ViewType.View2d) {
            // Tracking
            this.design.usageCounter.TwoDEditor++;

            this.menu3dSelectedTab = this.selectedMenu.selectedTab;

            /*  If no calculation then immediately update 2d window otherwise after calculation is done. No calculation when:
                    - button "change to 2d" is clicked or
                    - user selects already selected value (toggle button with command change to 2d). "custom anchor layout", "custom stiffeners", ...
            */
            if (!this.design.pendingCalculation) {
                this.update2dView();
            }
            else {
                this.design.one(DesignEvent.afterCalculation, () => {
                    this.update2dView();
                });
            }
        }
    }


    private update2dView() {
        this.mode2d = this.isAsadVisible ? Mode2d.Pan : Mode2d.Pointer;

        this.initMenu2d();

        // update 2d menu
        const model = this.glModelComponent.getModel();
        this.mainMenuComponent?.setMainMenuState(menu => this.menu2dService.updateMainMenuControls(menu, {
            [this.menu2dService.controlName(Controls2dEditor.Baseplate)]: {
                activeValue: this.design.model[UIProperty.AnchorPlate_PlateShape] as number
            } as any,
            [this.menu2dService.controlName(Controls2dEditor.LedgerAngle)]: {
                activeValue: this.design.model[UIProperty.AnchorPlate_LedgerAngleShape] as number
            } as any,
            [this.menu2dService.controlName(Controls2dEditor.StandardLayout)]: {
                activeValue: this.design.model[UIProperty.AnchorLayout_Layout] as number,
                hidden: !this.isAnchorEditable
            } as any,
            [this.menu2dService.controlName(Controls2dEditor.CustomLayout)]: {
                activeValue: null,
                hidden: !this.isAnchorEditable
            } as any,
            [this.menu2dService.controlName(Controls2dEditor.AnchorNodes)]: {
                items: model.anchor?.points?.map(p => {
                    const positionRelativeToCenter = Center2dPe.calculatePointRelativeToCenter(model, p.position);
                    return { x: positionRelativeToCenter.x, y: positionRelativeToCenter.y };
                }),
                isEditable: this.isAnchorEditable,
                hidden: model.anchor?.points == null || model.anchor.points.length < 1
            } as any,
            [this.menu2dService.controlName(Controls2dEditor.BaseplateNodes)]: {
                items: model.plate?.points?.map(p => {
                    const positionRelativeToCenter = Center2dPe.calculatePointRelativeToCenter(model, p);
                    return { x: positionRelativeToCenter.x, y: positionRelativeToCenter.y };
                }),
                isEditable: this.isPlateEditable,
                hidden: model.plate?.points == null || model.plate.points.length < 1
            } as any
        }));

        this.view = ViewType.View2d;

        const spacing = (this.menu2dService.getControlByName(this.selectedMenu, this.menu2dService.controlName(Controls2dEditorBase.GridSpacing)) as ITextBoxProps).value as string;
        const spacingValue = this.unitService.convertUnitValueToInternalUnitValue(this.unitService.parseUnitValue(spacing, UnitGroup.Length))?.value as number;
        this.mainMenuComponent?.setMainMenuState(menu => this.menu2dService.updateMainMenuControl<ITextBoxProps>(menu, this.menu2dService.controlName(Controls2dEditorBase.GridSpacing), { value: this.unitService.formatInternalValueAsDefault(spacingValue, UnitGroup.Length) } as any));
        const showGrid = (this.menu2dService.getControlByName(this.selectedMenu, this.menu2dService.controlName(Controls2dEditorBase.ShowGrid)) as ICheckboxProps).checked;
        this.mainMenuComponent?.setMainMenuState(menu => this.menu2dService.updateMainMenuControl<ICheckboxProps>(menu, this.menu2dService.controlName(Controls2dEditorBase.ShowGrid), { checked: showGrid } as any));

        this.glModelComponent.update({
            plate: {
                editable: this.isPlateEditable,
                disabledPoints: (visible: boolean) => this.ngZone.run(() => this.setDraggingTooltipPlateDisabled(visible))
            },
            grid2d: {
                visible: (this.menu2dService.getControlByName(this.selectedMenu, this.menu2dService.controlName(Controls2dEditorBase.ShowGrid)) as ICheckboxProps).checked,
                spacing: spacingValue,
                snapToGrid: (this.menu2dService.getControlByName(this.selectedMenu, this.menu2dService.controlName(Controls2dEditor.SnapToGrid)) as ICheckboxProps).checked
            }
        });

        this.glModelComponent.resizeNextFrame(1, 2);

        // set gl model view to 2d
        this.glModelComponent.update({
            view: ViewType.View2d,
            editor2d: {
                mode: this.isAsadVisible ? Mode2d.Pan : Mode2d.Pointer
            },
            view2dMode: View2dModeType.Top
        });

        this.glModelComponent.clearSelected();
        this.glModelComponent?.resetRotation();
        this.glModelComponent.resetCamera();

        setTimeout(() => this.glModelComponent.resizeNextFrame(1, 10));

        // repopulate the display options checkbox items since they change with 2d/3d view
        this.displayOptionsCheckbox.items = this.createDisplayOptionsCheckboxItems();
        this.displayOptionsCheckboxConcrete.groupItems = this.createDisplayOptionsCheckboxItemsConcrete();
    }

    private dismiss2dInternal() {
        this.setCursor('');
        this.view = ViewType.View3d;

        this.initMenu3d();

        // reselect tab on 3d
        this.mainMenuComponent?.setMainMenuState(menu => ({
            ...menu,
            selectedTab: this.menu3dSelectedTab
        }));

        this.resize3d();

        this.glModelComponent.update({
            view: ViewType.View3d
        });

        this.glModelComponent.clearSelected();
        this.glModelComponent?.resetRotation();
        this.glModelComponent.resetCamera();

        // repopulate the display options checkbox items since they change with 2d/3d view
        this.displayOptionsCheckbox.items = this.createDisplayOptionsCheckboxItems();
        this.displayOptionsCheckboxConcrete.groupItems = this.createDisplayOptionsCheckboxItemsConcrete();
    }

    private save2dModelToProperties(saveProperties: { [uiPropertyId: number]: boolean }) {
        const modelPe = this.glModelComponent.getModel();

        let center = Vector2.Zero();

        // plate points
        if (saveProperties[UIProperty.AnchorPlate_CustomLayoutPoints] != null) {
            if (this.isPlateEditable) {
                this.design.model[UIProperty.AnchorPlate_CustomLayoutPoints] = modelPe.plate?.points?.map(item => ({
                    X: item.x,
                    Y: item.y
                }) as Point2DEntity);

                const points = modelPe.plate?.points as Vector2[];

                let area = 0;
                for (let i = 0; i < points.length; ++i) {
                    const p0 = points[i];
                    const p1 = points[(i + 1) % points.length];
                    const a = (p0.x * p1.y - p1.x * p0.y);

                    area = area + a;
                    center.x = center.x + ((p0.x + p1.x) * a);
                    center.y = center.y + ((p0.y + p1.y) * a);
                }

                if (area == 0) {
                    center = Vector2.Zero();
                }
                else {
                    center.x /= (3 * area);
                    center.y /= (3 * area);
                }
            }
        }

        // anchor points
        if (saveProperties[UIProperty.AnchorLayout_CustomLayoutPoints] != null || !center.equals(Vector2.Zero())) {
            this.design.model[UIProperty.AnchorLayout_CustomLayoutPoints] = modelPe.anchor?.points?.map(item => ({
                X: item.position.x - center.x,
                Y: item.position.y - center.y
            }) as AnchorLayoutPointEntity);
        }

        // profile offset
        if (saveProperties[UIProperty.Profile_OffsetX] != null || saveProperties[UIProperty.Profile_OffsetY] != null || !center.equals(Vector2.Zero())) {
            if (modelPe.profile != null && modelPe.profile.offset != null) {
                this.design.model[UIProperty.Profile_OffsetX] = modelPe.profile.offset.x - center.x;
                this.design.model[UIProperty.Profile_OffsetY] = modelPe.profile.offset.y - center.y;
            }
        }
    }

    private get2dMenuContext(): IMenu2dContext {
        return {
            glModelComponent: this.glModelComponent,
            currentView: () => this.view,
            currentMenu: () => this.selectedMenu,
            tabSelected: this.tabSelected.bind(this),
            setState: this.mainMenuComponent?.setMainMenuState.bind(this),
            save2dState: this.save2dState.bind(this),
            resize3dAfterUI: this.resize3dAfterUI.bind(this)
        };
    }

    private initMenu2d() {
        this.mainMenuComponent?.initMenu2d(() =>
            this.menu2dService.createMenu(this.get2dMenuContext(),
                {
                    ['OpenDesignSettingsPopup']: () => this.openDesignSettings(),
                } as unknown as Record<MainMenuCommands, (navigationControl: BaseControl) => void>));
    }

    private initMenu3d() {
        this.mainMenuComponent?.initMenu3d(
            this.design,
            this.tabSelected,
            {
                ['OpenImportLoadsPopup']: () => this.modalService.openImportLoads(),
                ['OpenCustomizeDetailsPopup']: () => this.modalService.openBuildingTypeCustomizeDetails(),
                ['OpenInputHandrailLoadsPopup']: () => this.modalService.openInputHandrailLoads(),
                ['OpenWindloadsPopup']: () => this.modalService.openWindloads(),
                ['Open2dEditor']: () => { setTimeout(this.open2d.bind(this)); },
                ['OpenApproval']: this.openApproval.bind(this),
                ['OpenDlubalImportPopup']: () => this.modalService.openDlubalImport(),
                ['OpenDlubalExportPopup']: () => this.modalService.openDlubalExport(),
                ['OpenFullscreenLoader']: this.openFullscreenLoader.bind(this),
                ['OpenAdvancedSettings']: this.openAdvancedBaseplateCalculationSettings.bind(this),
                ['OpenAdvancedCalculation']: this.openAdvancedBaseplateCalculation.bind(this),
                ['OpenNotOwnedLearnMore']: this.openUpgradePE.bind(this),
                ['OpenExportRisa']: this.openExportRisa.bind(this),
                ['OpenSAP2000ImportPopup']: () => this.modalService.openSapImport(),
                ['OpenHorizontalPostProfileConnectionPopup']: () => this.modalService.openHorizontalPostProfileConnection(),
                ['OpenAdvancedCalculationTypeInfoPopup']: () => this.modalService.openAdvancedCalculationTypeInfo(),
                ['OpenRobotImportPopup']: () => this.modalService.openRobotImport(),
                ['OpenETABSImportPopup']: () => this.modalService.openEtabsImport(),
                ['OpenStaadProImportPopup']: () => this.modalService.openStaadProImport(),
                ['OpenSupplementaryReinforcementInfoPopup']: () => this.modalService.openSupplementaryReinforcementInfo(),
                ['OpenFillHolesPopup']: () => this.modalService.openFillHolesModal(),
                ['OpenSeismicFillingSetPopup']: () => this.modalService.openSeismicFillingSetModal(),
                ['OpenAnchorDetailedFilterPopup']: () => this.design?.model[UIProperty.SmartAnchor_Enabled] as boolean ? this.openMultiselectAnchor() : this.modalService.openSelectAnchor(),
                ['OpenAnchorNeedSolutionPopup']: () => this.modalService.openAnchorNeedSolutionPopup(),
                ['OpenAutomaticTorquePopUp']: () => this.modalService.openAutomaticTorquePopup(),
                ['OpenWHGInfoPopup']: () => this.modalService.openWHGInfo(),
                ['OpenMoreInfoOnSOFAPopup']: () => this.modalService.openMoreInfoOnSOFAModal(),
                ['OpenDesignSettingsPopup']: () => this.openDesignSettings(),
                ['HeadJointHollowPopup']: () => this.modalService.openHeadJointPopup('HeadJointSelectionHollow'),
                ['HeadJointSolidPopup']: () => this.modalService.openHeadJointPopup('HeadJointSelectionSolid'),
                ['OpenMoreInfoOnFatiguePopup']: () => this.modalService.openMoreInfoOnFatigueModal(),
                ['OpenMoreInfoOnFireRedirect']: () => this.fireMoreInfoRedirect(),
                ['OpenShearLoadDistributionPopup']: () => this.modalService.openShearLoadDistributionPopup(this.design.shearLoadDistributionType, this.design.designStandard.id, this.design.designMethodGroup?.id),
            } as unknown as Record<MainMenuCommands, (navigationControl?: BaseControl) => void>);
    }

    private openExportRisa() {
        this.designSectionComponentRef.nativeElement.openExport();
    }

    private openExportReport() {
        this.designSectionComponentRef.nativeElement.openExportReport();
    }

    private closeExportReport() {
        this.designSectionComponentRef.nativeElement.closeExportReport();
    }

    private openAdvancedBaseplateCalculation() {
        this.calculationService.calculateAdvancedBaseplate(this.userService.design);
    }

    private openAdvancedBaseplateCalculationSettings() {
        this.openDesignSettings([AfterOpenInstruction.OpenAdvancedSettings]);
    }

    private tabSelected() {
        this.hideLeftMenu = false;

        this.resize3dAfterUI();
    }

    private initRightSide() {
        this.rightSideLoaded = true;

        this.exportReportSupportMethodsPe = {
            createGlModelScreenshot: (extraInfo: IScreenShotSettingsPe) => this.glModelComponent.createDesignScreenshot(extraInfo)
        };
    }

    private resize3dAfterUI() {
        // the UI might update later so we resize it twice
        this.resize3d();

        setTimeout(() => {
            this.resize3d();
        });
    }

    /* GL MODEL */
    private initGLModel() {
        return new Promise<void>(resolve => {
            const model: IModel = {
                tooltips: this.useNewDisplayOptionsDropdown ? this.userSettingsService.settings.applicationModelDisplayOptions.toolTips.value ?? undefined : true,
            };

            this.onBeforeCalculate = this.onBeforeCalculate.bind(this);
            this.onStateChanged = this.onStateChanged.bind(this);
            this.onCalculate = this.onCalculate.bind(this);

            this.design.onBeforeCalculate(this.onBeforeCalculate);
            this.design.onCalculate(this.onCalculate);
            this.design.onStateChanged(this.onStateChanged);

            this.initGLModelPe(model, resolve);
        });
    }

    private initGLModelPe(model: IModel, resolve: (value: void | PromiseLike<void>) => void): void {
        const modelPe: IModelPe = {
            ...model,
            meshVisible: this.userSettingsService.settings.applicationModelDisplayOptions.mesh.value ?? undefined,
            textureDisplay: this.getCalculationOutputDropdownSelectedValue,
            force: {
                visible: this.userSettingsService.settings.applicationModelDisplayOptions.loads.value ?? undefined,
            },
            moment: {
                visible: this.userSettingsService.settings.applicationModelDisplayOptions.loads.value ?? undefined,
            },
            plate: {
                dimensionsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.baseplateDimensions.value ?? undefined,
                plateNumberingVisible: this.userSettingsService.settings.applicationModelDisplayOptions.plateNumbering.value ?? undefined,
                rotationVisible: this.userSettingsService.settings.applicationModelDisplayOptions.rotation.value ?? undefined,
                centerCrossVisible: true,
                isControllingDimension: this.userSettingsService.settings.quickStart.concrete.measureAnchorPlate?.value == MeasureAnchorPlateMode.UsingOverallWidthAndHeight
            },
            anchor: {
                anchorLayoutMeasurmentsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.anchorLayoutMeasurments.value ?? undefined,
                anchorLayoutMeasurmentsVisible2D: this.userSettingsService.settings.applicationModelDisplayOptions.anchorLayoutMeasurments2d.value ?? undefined,
                anchorNumberingVisible: this.userSettingsService.settings.applicationModelDisplayOptions.anchorNumbering.value ?? undefined,
                rotationVisible: this.userSettingsService.settings.applicationModelDisplayOptions.rotation.value ?? undefined,
                anchorOffsetVisible: true,
                isControllingDimension: this.userSettingsService.settings.quickStart.concrete.measureAnchorPlate?.value == MeasureAnchorPlateMode.FromAnchorCenterToPlateEdge
            },
            profile: {
                profileMeasurmentsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.profileMeasurments.value ?? undefined,
                profileCenterToConcreteEdgeVisible: this.design.designType.id == DesignType.Concrete ? this.userSettingsService.settings.applicationModelDisplayOptions.profileCenterToConcreteEdge.value ?? undefined : undefined,
                profileEdgeToConcreteEdgeVisible: this.design.designType.id == DesignType.Concrete ? this.userSettingsService.settings.applicationModelDisplayOptions.profileEdgeToConcreteEdge.value ?? undefined : undefined,
                profileEdgeToBaseplateEdgeVisible: this.design.designType.id == DesignType.Concrete ? this.userSettingsService.settings.applicationModelDisplayOptions.profileEdgeToBaseplateEdge.value ?? undefined : undefined,
                profileCenterToBaseplateCenterVisible: this.userSettingsService.settings.applicationModelDisplayOptions.profileCenterToBaseplateCenter.value ?? undefined,
                transparentProfile: this.userSettingsService.settings.applicationModelDisplayOptions.transparentProfile.value ?? undefined,
                useTallProfile: this.design.useTallProfile
            },
            shearLug: {
                shearLugMeasurementsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.shearLugMeasurements.value ?? undefined
            },
            welds: {
            },
            stiffener: {
                stiffener2dVisible: this.userSettingsService.settings.applicationModelDisplayOptions.stiffener2d.value ?? undefined
            },
        };

        this.initBaseMaterials(modelPe);

        this.glModel = {
            continuousRender: this.glDebug,
            model: modelPe,
            onZoom: (zoom) => {
                this.modelViewZoom = Math.round(100 - zoom);
                this.changeDetector.detectChanges();
            },
            onFontsLoaded: () => {
                this.updateGLModel(this.design, null as any, Update.ServerAndClient, undefined, false, true);

                setTimeout(() => {
                    this.glModelComponent.update({ hidden: false }, undefined, true);
                    resolve();
                }, 100);
            },
            onPositionsChanged: (components) => {
                const updateObj: { [uiProperty: number]: boolean } = {};

                const menu2dContext = this.get2dMenuContext();

                if (components[plate2dConstants.componentId] != null) {
                    this.mainMenuComponent?.setMainMenuState(menu => this.menu2dService.updateMainMenuControl(menu, this.menu2dService.controlName(Controls2dEditor.Baseplate), { activeValue: null } as any));
                    this.menu2dService.updatePointsTable(menu2dContext, PointsTableType.Plate);
                    updateObj[UIProperty.AnchorPlate_CustomLayoutPoints] = true;
                }
                if (components[Anchor2d.componentId] != null) {
                    this.mainMenuComponent?.setMainMenuState(menu => this.menu2dService.updateMainMenuControl(menu, this.menu2dService.controlName(Controls2dEditor.StandardLayout), { activeValue: null } as any));
                    this.mainMenuComponent?.setMainMenuState(menu => this.menu2dService.updateMainMenuControl(menu, this.menu2dService.controlName(Controls2dEditor.CustomLayout), { activeValue: null } as any));
                    this.menu2dService.updatePointsTable(menu2dContext, PointsTableType.Anchor);
                    updateObj[UIProperty.AnchorLayout_CustomLayoutPoints] = true;
                }
                if (components[profile2dConstants.componentName] != null) {
                    updateObj[UIProperty.Profile_OffsetX] = true;
                    updateObj[UIProperty.Profile_OffsetY] = true;
                }

                this.save2dState(updateObj);
            },
            onDraggingSelectionChanged: (visible) => {
                this.setDraggingTooltipVisible(visible);
            },
            onSelectTab: (tab) => {
                this.selectTab(tab);
            }
        };
    }

    private initBaseMaterials(modelPe: IModelPe) {
        if (this.design.designType.id == DesignType.Concrete) {
            modelPe.concreteBaseMaterial = {
                dimensionsVisible: this.useNewDisplayOptionsDropdown ? this.userSettingsService.settings.applicationModelDisplayOptions.anchorCenterToConcreteEdge.value ?? undefined : this.userSettingsService.settings.applicationModelDisplayOptions.concreteDimensions.value ?? undefined,
                dimensionThicknessVisible: this.userSettingsService.settings.applicationModelDisplayOptions.concreteDimensions.value ?? undefined,
                transparent: this.userSettingsService.settings.applicationModelDisplayOptions.transparentBaseMaterial.value ?? undefined,
                isProfileCenterControllingDimension: (this.sharedEnvironmentData.data?.useDevFeatures ?? false) ? this.userSettingsService.settings.quickStart.concrete.measureBaseMaterialEdgeFrom?.value == MeasureBaseMaterialEdgeFromMode.ProfileCenter : undefined
            };
            if (this.showSupplementaryReinforcementDimensions) {
                modelPe.supplementaryReinforcement = {
                    dimensionsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.supplementaryReinforcementDimensions.value ?? undefined
                };
            }
        }
        else if (this.design.designType.id == DesignType.Handrail) {
            modelPe.handrailBaseMaterial = {
                handrailDimensionsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.handrailDimensions.value ?? undefined,
                dimensionsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.concreteDimensions.value ?? undefined,
                transparent: this.userSettingsService.settings.applicationModelDisplayOptions.transparentBaseMaterial.value ?? undefined,
                loadHeightDimensionsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.loadsHeight.value ?? undefined
            };

            modelPe.handrail = {
                loadsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.loads.value ?? undefined,
            };
        }
        else if (this.design.designType.id == DesignType.Masonry) {
            modelPe.masonryBaseMaterial = {
                dimensionsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.concreteDimensions.value ?? undefined,
                stressArrowsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.loads.value ?? undefined,
                transparent: this.userSettingsService.settings.applicationModelDisplayOptions.transparentBaseMaterial.value ?? undefined
            };
        }
        else if (this.design.designType.id == DesignType.MetalDeck) {
            modelPe.metalDeckBaseMaterial = {
                dimensionsVisible: this.userSettingsService.settings.applicationModelDisplayOptions.concreteDimensions.value ?? undefined,
                transparent: this.userSettingsService.settings.applicationModelDisplayOptions.transparentBaseMaterial.value ?? undefined
            };
        }
    }

    private createDesignScreenshot() {
        if (this.design == null) {
            return;
        }

        const isTemplate = this.design.isTemplate;
        const isCreateTemplate = this.userService.isCreateTemplate;
        const id = (isTemplate ? this.design.templateId : this.design.id) as string;
        this.glModelComponent.createDesignScreenshot({ isThumbnail: true, imgHeight: this.isNewHomePage ? 120 : 145, imgWidth: this.isNewHomePage ? 190 : 145, zoomed: false, preview: false, isHomepageThumbnail: this.isNewHomePage } as IScreenShotSettingsPe)
            .then(img => {
                if (isTemplate) {
                    this.designTemplateService.updateDesignThumbnailImage(id, img, false);
                }
                else {
                    this.documentService.updateDesignThumbnailImage(id, img, false).then(async () => {
                        if (isCreateTemplate) {
                            await this.createNewTemplate();
                        }
                    });
                }
            });
    }

    private async createNewTemplate() {
        //save new  template
        const template = {
            designTemplateDocument: this.getDesignTemplateDocument(),
            thumbnailId: this.design.id,
        };
        template.designTemplateDocument.templateName = this.design.templateName;
        template.designTemplateDocument.templateFolderId = this.design.templateFolderId;
        await this.designTemplateService.create(template.designTemplateDocument, template.thumbnailId).then((response) => {
            //enable editing of template file
            this.userService.design.templateId = response;
            this.userService.design.isTemplate = true;
            this.userService.setIsCreateTemplate(false);
        });
    }

    private onBeforeCalculate(design: Design, changes: Change[]) {
        if (changes != null && changes.length > 0) {
            this.updateGLModel(design, changes, Update.Client, undefined, true);
        }
    }

    private onStateChanged(design: Design, state: IDesignState, oldState: IDesignState, stateChange: StateChange) {
        if (stateChange == StateChange.server) {
            this.callAllUpdateMethods();
        }
        else {
            this.callOtherStateChanges(state, oldState);
        }

        // select available mode/pointer
        if (!this.isPlateEditable && this.mode2d == Mode2dPe.AddPlatePoint) {
            this.mode2d = Mode2d.Pointer;
        }

        if (this.glModelComponent != null) {
            // unselect points
            if (!this.isPlateEditable) {
                this.glModelComponent.unselectAllPlatePoints();
            }

            // update plate -> make unselected points undraggable
            if (this.glModelComponent.getModel()?.plate?.editable !== this.isPlateEditable) {
                this.glModelComponent.update({ plate: { editable: this.isPlateEditable } });
            }
        }

        const calculationOutputItems = this.getCalculationOutputDropdownItems();
        const selectedItem = calculationOutputItems.find(item => item.value == this.calculationOutputDropdown.selectedValue);

        // hide items
        const filteredCalculationOutputItems = [];

        for (const calculationOutputItem of calculationOutputItems) {
            if (!((calculationOutputItem.value == TextureDisplay.Deformation || calculationOutputItem.value == TextureDisplay.StressInConcrete || calculationOutputItem.value == TextureDisplay.PlasticStrain) && this.design.calculationType == AdvancedCalculationType.FEM)) {
                filteredCalculationOutputItems.push(calculationOutputItem);
            }
        }

        this.ensureSeletedItemIsNotHidden(selectedItem, filteredCalculationOutputItems);

        this.displayOptionsCheckbox.items = this.createDisplayOptionsCheckboxItems();
        this.displayOptionsCheckboxConcrete.groupItems = this.createDisplayOptionsCheckboxItemsConcrete();
        this.calculationOutputDropdown.items = filteredCalculationOutputItems;

        this.resize3dAfterUI();
        this.setNotificationComponentInputs();
    }

    private ensureSeletedItemIsNotHidden(selectedItem: DropdownItem<TextureDisplay> | undefined, filteredCalculationOutputItems: DropdownItem<TextureDisplay>[]) {
        if (selectedItem != null && !filteredCalculationOutputItems.includes(selectedItem)) {
            const value = filteredCalculationOutputItems.length > 0 ? filteredCalculationOutputItems[0].value : undefined;

            if (value !== this.calculationOutputDropdown.selectedValue) {
                this.calculationOutputDropdown.selectedValue = value;
                this.onCalculationOutputValueChange();
            }
        }
    }

    private callOtherStateChanges(state: IDesignState, oldState: IDesignState) {
        if (state === oldState) {
            this.updateGLModel(this.design, null as any, Update.ServerAndClient);
        }
        else {
            const changes = Object.values(this.changesService.getShallowChanges(oldState.model, state.model, true));

            if (changes != null && changes.length > 0) {
                this.updateGLModel(this.design, changes, Update.ServerAndClient, undefined, false, true);
            }
        }
    }

    private callAllUpdateMethods() {
        this.updateGLModel(this.design, null as any, Update.Server);
    }

    private onCalculate(design: Design, changes: Change[]) {
        this.updateGLModel(design, changes, Update.Client, undefined, false, true);

        this.setNotificationComponentInputs();
    }

    private updateGLModel(design: Design, changes: Change[], update: Update, model?: IModelPe, beforeCalculate = false, updateDesignScreenshot = false) {
        this.glModelComponent?.propertyValueChanged(changes, design, update, model);

        // updateGLModel is called multiple times so we only update the screenshot when update != Controls.GLUpdate.Update.server
        if (!beforeCalculate && update != Update.Server && updateDesignScreenshot) {
            setTimeout(() => {
                this.createDesignScreenshot();
            });
        }
    }

    private onKeyDown(event: KeyboardEvent) {
        // undo
        if (event.ctrlKey === true && (event.key === 'Z' || event.key === 'z')) {
            event.stopPropagation();
            event.preventDefault();

            this.undo();
        }

        // redo
        if (event.ctrlKey === true && (event.key === 'Y' || event.key === 'y')) {
            event.stopPropagation();
            event.preventDefault();

            this.redo();
        }

        // open import design popup
        if (event.ctrlKey === true && (event.key === 'I' || event.key === 'i')) {
            event.stopPropagation();
            event.preventDefault();

            this.designSectionComponentRef.nativeElement.openFile();
        }
    }

    private concateLoadCaseId(translationKey: string, loadCase: string | null | undefined) {
        const str = this.localizationService.getString(translationKey);

        if (loadCase == null || !areLoadCombinationsAvailable(this.design.designData.reportData)) {
            return str;
        }

        return str + ' ' + getLoadCombinationNumberTextById(this.design, this.localizationService, loadCase);
    }

    private updateTransparentBaseMaterial() {
        this.updateTransparentBaseMaterialDisplayOption(true);
        this.displayOptionsCheckboxConcrete.selectedValues?.add(
            DisplayOption.TransparentBaseMaterial
        );
    }

    private asadUseLambdaChange() {
        this.userService.design.designData.asadData.input.useLambda = this._asadUseLambda.has(true);
    }

    private openInQuantityCalculator() {
        window.open(`${this.sharedEnvironmentData.data?.externalQuantityCalculatorApplicationUrl}projectAndDesign/${this.design.id}`, '_blank');
    }

    private changeDG1ToCBFEM() {
        // Update model and run calculation
        this.calculationService.calculateAsync(this.design,
            (design) => {
                design.calculationType = AdvancedCalculationType.Realistic;
            }
        );
    }

    private optimizeDesignClick(propertyId: number) {
        this.triggerChangeNoCalculation(propertyId);

        if (propertyId == PropertyMetaData.Optimize_PostDistance.id) {
            this.design.usageCounter.PostDistance_Optimization++;
        }

        if (propertyId == PropertyMetaData.Optimize_AnchorOffset.id) {
            this.design.usageCounter.AnchorOffset_Optimization++;
        }

        this.modalService.loadingCustomOpen();
        this.calculationService.calculateAsync(this.design, undefined, { suppressLoadingFlag: true, calculateLongRunning: true })
            .finally(() => {
                this.modalService.loadingCustomClose();
            });
    }

    private triggerChangeNoCalculation(propertyId: number) {
        this.design.addModelChangeNoCalculation(propertyId, true);
    }

    private optimizeAnchorLayoutClick() {
        this.design.usageCounter.OptimizeAnchorLayout++;
        this.triggerChangeNoCalculation(PropertyMetaData.Optimize_AnchorLayout.id);

        this.modalService.loadingCustomOpen();
        this.calculationService.calculateAsync(this.design, undefined, { suppressLoadingFlag: true })
            .finally(() => {
                this.modalService.loadingCustomClose();
            });
    }

    private optimizedBaseplateSizeClick() {
        this.design.usageCounter.OptimizeBaseplateSize++;
        this.triggerChangeNoCalculation(PropertyMetaData.Optimize_BaseplateSize.id);

        this.modalService.loadingCustomOpen();
        this.calculationService.calculateAsync(this.design, undefined, { suppressLoadingFlag: true })
            .finally(() => {
                this.modalService.loadingCustomClose();
            });
    }

    private findSolutionClick() {
        this.triggerChangeNoCalculation(PropertyMetaData.Optimize_FindSolution.id);

        this.modalService.loadingCustomOpen();
        this.calculationService.calculateAsync(this.design, undefined, { suppressLoadingFlag: true, calculateLongRunning: true })
            .finally(() => {
                this.modalService.loadingCustomClose();
            });
    }

    private asadOptimize() {
        this.modalService.openAsadOptimizeModal();
    }

    private setASADDebugNotification() {
        this.asadDebugNotification =
        {
            condition: () => { return this.isAsadDebugEnabled && this.isConcreteDesign; },
            text: this.asadText,
            doesCount: false,
            class: this.asadDisabled.length > 0 ? 'notification-alert' : '',
            button: {
                buttonTitle: 'Optimize',
                disabled: () => { return this.asadDisabled.length > 0; },
                click: () => { this.asadOptimize(); },
                condition: () => { return true; }
            },
            checkbox: {
                items: [{ text: 'Use AWS Lambda', value: true }],
                value: this.asadUseLambda.has(true),
                valueChange: () => {
                    this.asadUseLambda = (new Set<boolean>()).add(!this.asadUseLambda.has(true));
                    this.asadUseLambdaChange();
                },
                condition: () => { return true; }
            },
            notificationLocation: NotificationLocation.Start
        };
    }

    private setNotificationComponentInputs() {
        this.notificationComponentInputs = {
            isVisible: () => {
                return this.design?.designData != null &&
                    (this.hasScopeChecks ||
                        this.isOptimizeAnchorLayoutVisible ||
                        this.isOptimizeBaseplateSizeVisible ||
                        this.isFindSolutionVisible ||
                        this.isRigidCalculationVisible ||
                        this.isOptimizePostDistanceVisible ||
                        this.isAdvancedCalculationVisible ||
                        this.isOptimizeAnchorOffsetVisible ||
                        this.isQuantityCalculatorLinkVisible);
            },
            isInfoMessageVisible: () => {
                return !(this.isAsad && this.isAsadVisible) || (this.isAnchorAISolutionSelected && this.alertScopeChecks.length == 0);
            },
            notifications: [
                {
                    condition: () => { return this.isAdvancedCalculationVisible; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.AdvancedCalculation.Message'),
                    doesCount: true,
                    class: '',
                    button: {
                        buttonTitle: this.translate('Agito.Hilti.Profis3.Notification.AdvancedCalculation'),
                        disabled: () => { return this.advancedCalculationDisabled; },
                        click: () => {
                            NgZone.assertNotInAngularZone();
                            this.ngZone.run(() => this.runAdvancedCalculation());
                        },
                        condition: () => { return true; },
                    },
                    additionalText: this.translate('Agito.Hilti.Profis3.Notification.HandrailCBFEM.Message'),
                    additionalButton: {
                        buttonTitle: this.translate('Agito.Hilti.Profis3.Notification.HandrailCBFEM.LearnMore'),
                        condition: () => { return this.design.isHandrailCBFEMCalculation && this.isHandrailCBFEMLearnMoreLinkAvailable; },
                        click: () => { this.openHandrailCBFEMLearnMoreLink(); },
                        disabled: () => { return false; },
                        disableTooltip: () => { return true; }
                    },
                    notificationLocation: NotificationLocation.Start
                },
                {
                    // en based fatigue info should be between yellow scopechecks and all others info scope checks*
                    condition: () => { return this.fatigueNotificationVisible; },
                    text: this.enBasedfatigueNotificationTranslation,
                    doesCount: false,
                    notificationLocation: NotificationLocation.Middle
                },
                {
                    condition: () => { return this.isQuantityCalculatorLinkVisible; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.QuantityCalculatorLink.Message'),
                    doesCount: true,
                    button: {
                        buttonTitle: this.translate('Agito.Hilti.Profis3.Notification.QuantityCalculatorLink.Button'),
                        disabled: () => { return this.design.isReadOnlyDesignMode; },
                        click: () => { this.openInQuantityCalculator(); },
                        condition: () => { return true; },
                    },
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return this.isWHGCoatingWithFilledHoles; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.WHGCoatingWithFilledHoles.Message'),
                    doesCount: false,
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return this.isChangeDG1toCBFEMVisible; },
                    text: this.DG1TranslationMessage,
                    doesCount: false,
                    button: {
                        buttonTitle: this.translate('Agito.Hilti.Profis3.Notification.DG1ToCBFEMChange.Button'),
                        disabled: () => { return this.design.isReadOnlyDesignMode; },
                        click: () => {
                            NgZone.assertNotInAngularZone();
                            this.ngZone.run(() => this.changeDG1ToCBFEM());
                        },
                        condition: () => { return true; },
                    },
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return this.isOptimizePostDistanceVisible; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.OptimizePostDistance.Message'),
                    doesCount: true,
                    button: {
                        buttonTitle: this.translate('Agito.Hilti.Profis3.Navigation.TabApplication.RegionHandrail.OptimizePostDistance'),
                        disabled: () => { return this.notificationButtonsDisabled; },
                        click: () => {
                            NgZone.assertNotInAngularZone();
                            this.ngZone.run(() => this.optimizeDesignClick(PropertyMetaData.Optimize_PostDistance.id));
                        },
                        condition: () => { return !this.notificationButtonsHidden; },
                        tooltip: this.notificationButtonsTooltip('Agito.Hilti.Profis3.Navigation.TabApplication.RegionHandrail.ControlOptimizePostDistance.Tooltip')
                    },
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return this.isOptimizeAnchorOffsetVisible; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.OptimizeAnchorOffset.Message'),
                    doesCount: true,
                    button: {
                        buttonTitle: this.translate('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionLayout.ControlOptimizeAnchorOffset'),
                        disabled: () => { return this.notificationButtonsDisabled; },
                        click: () => {
                            NgZone.assertNotInAngularZone();
                            this.ngZone.run(() => this.optimizeDesignClick(PropertyMetaData.Optimize_AnchorOffset.id));
                        },
                        condition: () => { return !this.notificationButtonsHidden; },
                        tooltip: this.notificationButtonsTooltip('Agito.Hilti.Profis3.Navigation.TabAnchorLayout.RegionLayout.ControlOptimizeAnchorOffset.Tooltip.Title')
                    },
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return this.isOptimizeAnchorLayoutVisible; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.OptimizeAnchorLayout.Message'),
                    doesCount: true,
                    button: {
                        buttonTitle: this.translate('Agito.Hilti.Profis3.Notifications.OptimizeAnchorLayout'),
                        disabled: () => { return this.notificationButtonsDisabled; },
                        click: () => {
                            NgZone.assertNotInAngularZone();
                            this.ngZone.run(() => this.optimizeAnchorLayoutClick());
                        },
                        condition: () => { return !this.notificationButtonsHidden; },
                        tooltip: this.notificationButtonsTooltip('Agito.Hilti.Profis3.Notifications.OptimizeAnchorLayout.Tooltip')
                    },
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return this.isOptimizeBaseplateSizeVisible; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.OptimizeBaseplateSize.Message'),
                    doesCount: true,
                    button: {
                        buttonTitle: this.translate('Agito.Hilti.Profis3.Notifications.OptimizeBaseplateSize'),
                        disabled: () => { return this.notificationButtonsDisabled; },
                        click: () => {
                            NgZone.assertNotInAngularZone();
                            this.ngZone.run(() => this.optimizedBaseplateSizeClick());
                        },
                        condition: () => { return !this.notificationButtonsHidden; },
                        tooltip: this.notificationButtonsTooltip('Agito.Hilti.Profis3.Notifications.OptimizeBaseplateSize.Tooltip')
                    },
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return this.isFindSolutionVisible; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.FindSolution.Message'),
                    doesCount: true,
                    button: {
                        buttonTitle: this.translate('Agito.Hilti.Profis3.Notifications.FindSolution'),
                        disabled: () => { return this.notificationButtonsDisabled; },
                        click: () => {
                            NgZone.assertNotInAngularZone();
                            this.ngZone.run(() => this.findSolutionClick());
                        },
                        condition: () => { return !this.notificationButtonsHidden; },
                        tooltip: this.notificationButtonsTooltip('Agito.Hilti.Profis3.Notifications.FindSolution.Tooltip')
                    },
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return this.isRigidCalculationVisible; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.RigidCalculation.Message'),
                    doesCount: true,
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return this.design.designMethodGroup?.id == DesignMethodGroup.CSAA23319 && this.design?.isSustainedLoadsPresent; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.Csa-A23.3-19-SustainedLoads.Message'),
                    doesCount: true,
                    notificationLocation: NotificationLocation.Middle
                },
                {
                    condition: () => { return this.design.designMethodGroup?.id == DesignMethodGroup.CSAA23319; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.Csa-A23.3-19-MandatoryAnnexD.Message'),
                    doesCount: true,
                    notificationLocation: NotificationLocation.Middle
                },
                {
                    condition: () => { return this.isEnCip; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.EnCipWarning.Message'),
                    doesCount: false,
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return this.isHeadJointCompletelyFilledVisible; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.HeadJointCompletelyFilled.Message'),
                    doesCount: false,
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return this.suggestedAnchorsUnavailable; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.SuggestedAnchorsUnavailable.Message'),
                    doesCount: false,
                    notificationLocation: NotificationLocation.End
                },
                {
                    condition: () => { return !this.isTransparentBasematerialSelected && this.design?.isShearLugPresent; },
                    text: this.translate('Agito.Hilti.Profis3.Notification.SelectTransparentBaseMaterial.Message'),
                    doesCount: true,
                    button: {
                        buttonTitle: this.translate('Agito.Hilti.Profis3.Notification.SelectTransparentBaseMaterial.Button'),
                        condition: () => { return true; },
                        click: () => { this.updateTransparentBaseMaterial(); },
                        disableTooltip: () => { return true; },
                        disabled: () => { return this.design.isReadOnlyDesignMode; }
                    },
                    notificationLocation: NotificationLocation.End
                }
            ],
            scopeChecks: ((this.isAsadVisible && !this.design.isAnchorAISolutionSelectedOnce) || this.isAsadPlateHidden) ? this.notificationScopeChecksForAnchorAI : this.notificationScopeChecks
        } as INotificationsComponentInput;
    }

    private openMultiselectAnchor() {
        this.design.usageCounter.SmartAnchor_PreferredAnchorClicked++;
        this.modalService.openMultiselectAnchor();
    }

    private openDisplayOptionsDropdown() {
        setTimeout(() => {
            this.displayOptionsDropdownElementRef.nativeElement.setAttribute('data-preventClose', 'true');
            this.displayOptionsDropdownRef.open();
        });
    }

    private closeDisplayOptionsDropdown() {
        setTimeout(() => {
            this.displayOptionsDropdownElementRef.nativeElement.removeAttribute('data-preventClose');
            this.displayOptionsDropdownRef.close();
        });
    }

    private showVirtualTour() {
        this.mainHeaderComponentRef.nativeElement.showVirtualTour();
    }

    private fireMoreInfoRedirect() {
        const url = DesignPe.getRegionPe(this.design).fireLearnMoreUrl;
        if (url) {
            const design = this.userService.design;
            if (design != null) {
                design.usageCounter.LearnMore_FireClicked++;
            }
            window.open(url, '_blank');
        }
    }
}
